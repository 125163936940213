import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  DriversClient,
  IDriverDropdownQueryResponse,
} from '@shared/model/atlas.api';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { SelectItem } from 'primeng/api';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';

@Component({
  selector: 'atlas-driver-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './driver-dropdown.component.html',
  styleUrl: './driver-dropdown.component.scss',
})
export class DriverDropdownComponent implements OnInit, OnDestroy, OnChanges {
  searchOptions: SelectItem[] = [];

  private _subs: Subscription;
  drivers: IDriverDropdownQueryResponse[] = [];

  @Input() partnerId: string;

  constructor(
    public formService: AssistanceFormService,
    public service: AssistanceService,
    private client: DriversClient,
  ) {
    this._subs = new Subscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['partnerId'] && changes['partnerId'].currentValue !== changes['partnerId'].previousValue) {
      this._load().then((x) => {
        this.searchOptions = x.result.value.map((option) => ({
          label: option.name,
          value: option.id,
        }));
        this.drivers = x.result.value;
  
        this.formService.getForm.controls.driverId.reset();
      });
    }
  }

  ngOnInit() {
    this._load().then((x) => {
      this.searchOptions = x.result.value.map((option) => ({
        label: option.name,
        value: option.id,
      }));
      this.drivers = x.result.value;
    });

    this.formService.getForm.controls.driverId.valueChanges.subscribe(
      (driverId) => {
        const res = this.drivers.find((x) => x.id == driverId);
        if (res) {
          this.formService.getForm.controls.driverPhone.patchValue(
            res.phones.join(', '),
          );
          this.service.setDriver = res.name;
        }
      },
    );
  }

  private _resetCtrl() {
    // this.selectedDriver.patchValue(null);
  }

  private async _load() {
    return await firstValueFrom(this.client.getDriverDropdown(this.partnerId));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
