import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DriversClient } from '@shared/model/atlas.api';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-add-driver-autocomplete',
  standalone: true,
  imports: [
    InputSearchComponent,
    ErrorRequiredComponent
  ],
  templateUrl: './add-driver-autocomplete.component.html',
  styleUrl: './add-driver-autocomplete.component.scss'
})
export class AddDriverAutocompleteComponent implements OnInit {

  drivers: any;
  partnerId: string;

  @Input() driverId: string;
  @Input() driverName: string;
  @Input() sourceIdCtrl: FormControl;
  @Output() public onDriverChange = new EventEmitter<any>();

  constructor(
      private _driverClient: DriversClient,
      private router: Router
    ) {}


  ngOnInit(): void {
    this.sourceIdCtrl = new FormControl(null, Validators.required);
    const url = this.router.url;
    this.partnerId = url.substring(url.lastIndexOf('/') + 1);
    if (this.driverId) {
      this._loadDriverDetails(this.driverId, this.driverName);
    }
  }

  private async _loadDriverDetails(driverId: string, driverName: string): Promise<void> {
    this.onDriverChange.emit({ id: driverId, name: driverName });
  }

    searchDrivers(searchValue: string) {
      this._load(searchValue)
    }

    private _load(name: string) {
      this.getClients(name).then((res) => {
        this.drivers = res.result.map((driver: any) => ({
          id: driver.id,
          name: driver.fullName,
        }));
      })
    }

    private async getClients(name: string) {
        return await firstValueFrom(
          this._driverClient.getDriverAutocomplete(this.partnerId ,name)
        )
      }

      onSelectChange(item: any) {
          if (!item || !item.value) {
            return;
          }
          this.sourceIdCtrl.markAsUntouched()
          this.onDriverChange.emit(item.value);
          console.log(item.value)
        }

}
