<!-- regular input -->
<div class="flex flex-column gap-2 w-full">
  <label [for]="formCtrlId" *ngIf="showLabel">{{ ctrlLabel }} </label>
  @if (formCtrlIcon) {
    <ng-container *ngTemplateOutlet="inputWithLeftIcon"></ng-container>
  } @else if (formCtrlIconRight) {
    <ng-container *ngTemplateOutlet="inputWithRightIcon"></ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="regularInput"></ng-container>
  }
  <ng-container *ngIf="!hideErrors">
    <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
    <atlas-error-email [formCtrl]="formCtrl"></atlas-error-email>
    <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
  </ng-container>
</div>

<!-- regular input -->
<ng-template #regularInput>
  <input
    *ngIf="!isPriceFormat"
    pInputText
    pAutoFocus
    (blur)="handleBlur($event)"
    (keyup)="handleKeyUp($event)"
    [formControl]="formCtrl"
    [id]="formCtrlId"
    [type]="formCtrlType"
    [placeholder]="ctrlPlaceholder"
    autocomplete="off"
    [ngClass]="formCtrlIcon ? 'pl-5 w-full' + formCtrlClass : formCtrlClass"
    [autofocus]="shouldFocus"
    [disabled]="isDisabled"
    [pKeyFilter]="ctrlKeyFilter"
  />
  <p-inputNumber
    *ngIf="isPriceFormat"
    [formControl]="formCtrl"
    [id]="formCtrlId"
    mode="decimal"
    [locale]="'de-DE'"
    [minFractionDigits]="2"
    [maxFractionDigits]="2"
    [placeholder]="ctrlPlaceholder"
    [autofocus]="shouldFocus"
    [disabled]="isDisabled"
    [ngClass]="formCtrlClass"
    [pKeyFilter]="ctrlKeyFilter"
  ></p-inputNumber>
</ng-template>

<!-- input with left icon-->
<ng-template #inputWithLeftIcon>
  <div class="p-input-icon-left">
    <i [class]="formCtrlIcon" hover-class="hovered"></i>
    <input
      pAutoFocus
      (blur)="handleBlur($event)"
      (keyup)="handleKeyUp($event)"
      [formControl]="formCtrl"
      [id]="formCtrlId"
      [type]="formCtrlType"
      [placeholder]="ctrlPlaceholder"
      autocomplete="off"
      pInputText
      [ngClass]="formCtrlIcon ? 'pl-5 w-full ' + formCtrlClass : formCtrlClass"
      [autofocus]="shouldFocus"
      [disabled]="isDisabled"
      [pKeyFilter]="ctrlKeyFilter"
    />
  </div>
</ng-template>

<!-- input with right icon -->
<ng-template #inputWithRightIcon>
  <div class="p-input-icon-right">
    <i
      [class]="formCtrlIconRight"
      [pTooltip]="tooltip"
      class="cursor-pointer"
      tooltipPosition="top"
      hover-class="hovered"
    ></i>
    <input
      pAutoFocus
      (blur)="handleBlur($event)"
      (keyup)="handleKeyUp($event)"
      [formControl]="formCtrl"
      [id]="formCtrlId"
      [type]="formCtrlType"
      [placeholder]="ctrlPlaceholder"
      autocomplete="off"
      pInputText
      [ngClass]="formCtrlIconRight ? 'w-full ' + formCtrlClass : formCtrlClass"
      [autofocus]="shouldFocus"
      [disabled]="isDisabled"
      [pKeyFilter]="ctrlKeyFilter"
    />
  </div>
</ng-template>
