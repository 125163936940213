<div class="h-screen p-4 grid m-0">
  <div class="login-content col-12 lg:col-4 pr-4 flex flex-column">
    <atlas-white-logo></atlas-white-logo>
    <div class="flex align-items-center justify-content-center h-full">
      <div class="content-wrapper">
        <div class="text-3xl font-semibold">Dobrodošli</div>
        <div class="text-base mt-2 text-gray-600">
          Unesite podatke kako biste nastavili
        </div>
        <div
          class="flex flex-column justify-content-center align-items-center gap-5 mt-6"
        >
          <div class="flex flex-column gap-3 w-full">
            <atlas-input-mail
              [formCtrl]="form.controls.email | formControl"
              ctrlLabel="E-mail"
              ctrlPlaceholder="Unesite e-mail..."
              formCtrlId="username"
              (onEnterClicked)="handleLogin()"
            ></atlas-input-mail>
            <atlas-input-password
              [formCtrl]="form.controls.password | formControl"
              ctrlLabel="Lozinka"
              ctrlPlaceholder="Unesite lozinku..."
              formCtrlId="password"
              (onEnterClicked)="handleLogin()"
            ></atlas-input-password>
            <atlas-button
              (btnClicked)="handleForgotPassword()"
              btnClass="p-0"
              class="align-self-end"
              btnLabel="Zaboravljena lozinka?"
            ></atlas-button>
          </div>
          <div class="flex flex-column gap-3 w-full">
            <atlas-button
              (btnClicked)="handleLogin()"
              [btnStyle]="ButtonStyle.BASIC"
              btnClass="w-full"
              btnLabel="Uloguj se"
            ></atlas-button>
<!--            <div class="text-base w-full text-center">ili</div>-->
<!--            <div-->
<!--              class="flex align-items-center text-center justify-content-center gap-3 w-full"-->
<!--            >-->
<!--              <div class="">Nemaš nalog?</div>-->
<!--              <atlas-button-->
<!--                (btnClicked)="handleRegister()"-->
<!--                btnClass="p-0"-->
<!--                [btnStyle]="ButtonStyle.TEXT"-->
<!--                btnLabel="Registruj se"-->
<!--              ></atlas-button>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="text-base mt-2 text-gray-600 text-center">&copy; Atlas24h</div>
  </div>
  <div id="picture" class="hidden lg:block lg:col-8"></div>
</div>
