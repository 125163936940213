<div class="flex wraper" (click)="handleWrapperClick()">
  <img
    [ngSrc]="typeImageUrl"
    width="48"
    height="48"
    alt="card icon"
    class="icon-svg"
  />
  <div class="container-checkbox">
    <div class="label ml-3">{{ type.name }}</div>
  </div>
  <div class="checkbox-div">
    <div class="checkbox ml-3">
      <p-checkbox
        [(ngModel)]="selectedTypes"
        [value]="type.typeId"
        (onChange)="handleCheckboxAdd($event)"
        (click)="$event.stopPropagation()"
      ></p-checkbox>
    </div>
  </div>
</div>
