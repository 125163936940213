<atlas-dropdown
  [options]="modelNames"
  [formCtrl]="selectedModelCtrl"
  [ctrlPlaceholder]="modelNameCtrl.value || 'Odaberite model'"
  [isEditable]="true"
  [canClear]="true"
  ctrlStyleClass="w-full"
  [pressEnterOnBlur]="true"
  ctrlLabel="Model"
>
</atlas-dropdown>
