<div class="h-full flex flex-column gap-5 justify-content-between">
  <div *ngIf="titleNeeded" class="page-header">
    <div class="text-3xl font-semibold">{{ pageTitle }}</div>
    <atlas-button
      [btnLabel]="cancelBtnIcon == 'cancel' ? 'Odustani' : 'Idi nazad'"
      (btnClicked)="handleCancelForm()"
      [btnIcon]="cancelBtnIcon == 'cancel' ? 'pi pi-times': 'pi pi-arrow-left'"
      [btnStyle]="ButtonStyle.TEXT"
    ></atlas-button>
  </div>
  <div [ngClass]="isGray ? 'bg-gray' : ''">
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100dvh - 270px)' }">
      <ng-container *ngTemplateOutlet="content"> </ng-container>
    </p-scrollPanel>
  </div>
  <div
    class="flex gap-3 justify-content-end align-items-center h-full page-footer"
  >
    <atlas-button
      *ngIf="submitBtnLabel"
      [btnIcon]="
        submitBtnIcon == 'add'
          ? 'pi pi-plus'
          : submitBtnIcon == 'edit'
            ? 'pi pi-pencil'
            : submitBtnIcon
      "
      [btnLabel]="submitBtnLabel"
      [btnStyle]="ButtonStyle.BASIC"
      [btnSeverity]="ButtonSeverity.SECONDARY"
      (btnClicked)="handleSubmitForm()"
    ></atlas-button>
  </div>
</div>
