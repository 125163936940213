import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackagesClient } from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-package-detail-autocomplete',
  standalone: true,
  imports: [InputSearchComponent, ButtonComponent],
  templateUrl: './package-detail-autocomplete.component.html',
  styleUrl: './package-detail-autocomplete.component.scss',
})
export class PackageDetailAutocompleteComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  packages: string[] = [];

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  additionalServiceItem: string;

  additionalServices: string[] = [];
  additionalServicesFromPackage: string[] = [];
  resetInput: boolean = false;
  isBtnDisabled: boolean = true;
  currentPackageId: string;
  inputValue: string;

  @Output() listOfServices = new EventEmitter<string[]>();

  @ViewChild('inputSearch', { static: false })
  inputSearch: InputSearchComponent;

  constructor(private _packageClient: PackagesClient,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.currentPackageId = params['id'];
      });
    if (this.currentPackageId)
    this._LoadPackage(this.currentPackageId);
  }

  private loadServices(searchValue?: string) {
    this._subs.add(
      this._packageClient.getPackageDetails(searchValue).subscribe((res) => {
        const allPackages = res.result.packageDetails;
 
        // Filter out services already included in additionalServices and additionalServicesFromPackage
        this.packages = allPackages.filter(service => 
          !this.additionalServices.includes(service.trim()) &&
          !this.additionalServicesFromPackage.includes(service.trim())
        );
  
      }),
    );
  }

private _LoadPackage(id: string) {
  this._subs.add(
      this._packageClient.getPackage(id).subscribe((res) => {
          this.additionalServicesFromPackage = res.result.details.map(service => service.trim());
      })
  );
}

  handleSearch(item: any) {
    this.inputValue = item;
    this.loadServices(item);
  }

  onSelectChange(item: any) {
  if (!item || !item.value) {
    return;
  }

  this.additionalServiceItem = item.value;
  const isServiceAlreadyAdded = this.additionalServices.includes(this.additionalServiceItem);
  this.isBtnDisabled = isServiceAlreadyAdded;
}

handleAddService() {
  let inputVal: string;

  inputVal = this.additionalServiceItem || this.inputValue;

  if (this.additionalServices.includes(this.additionalServiceItem)) {
    return;
  }

  this.additionalServices = [...this.additionalServices, inputVal];

  this.additionalServiceItem = null;
  this.inputValue = null;
  inputVal = null;

  this.listOfServices.emit(this.additionalServices);

  this.resetInput = true;
  setTimeout(() => {
    this.resetInput = false;
  }, 0);
}

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
