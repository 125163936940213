import { NgTemplateOutlet, NgOptimizedImage, JsonPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  EmployeesClient,
  IAgentAutocompleteResponse,
} from '@shared/model/atlas.api';
import { ButtonComponent } from '@ui/button/button.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-agents-autocomplete',
  standalone: true,
  imports: [
    InputSearchComponent,
    ButtonComponent,
    NgTemplateOutlet,
    NgOptimizedImage,
    JsonPipe,
  ],
  templateUrl: './agents-autocomplete.component.html',
  styleUrl: './agents-autocomplete.component.scss',
})
export class AgentsAutocompleteComponent {
  private _subs: Subscription = new Subscription();
  // packages: string[] = [];
  // additionalServiceItem: string;
  // additionalServices: string[] = [];
  resetInput: boolean = false;
  agentSuggestions: IAgentAutocompleteResponse[] = [];
  @Input() exceptAgents: IAgentAutocompleteResponse[] = [];
  @ViewChild('inputSearch', { static: false })
  @Output()
  test: EventEmitter<IAgentAutocompleteResponse> =
    new EventEmitter<IAgentAutocompleteResponse>();
  @Output() agentSelected: EventEmitter<IAgentAutocompleteResponse> =
    new EventEmitter<IAgentAutocompleteResponse>();

  constructor(private _client: EmployeesClient) {}

  private searchAgents(searchValue: string = '') {
    this._subs.add(
      this._client
        .getAgentAutocomplete(
          searchValue,
          this.exceptAgents.map((x) => x.id),
        )
        .subscribe((res) => {
          if (res?.result) {
            this.agentSuggestions = res.result;
          } else {
            this.agentSuggestions = [];
          }
        }),
    );
  }

  handleSearch(item: any) {
    this.searchAgents(item);
  }

  onSelectChange(event$: any) {
    if (!event$ || !event$.value) {
      return;
    }
    this.agentSelected.emit(event$.value);
    this.resetInput = !this.resetInput;
  }

  // ToDo: objasniti potrebu
  // handleAddService() {
  //   if (!this.additionalServiceItem) {
  //     return;
  //   }
  //
  //   if (this.additionalServices.includes(this.additionalServiceItem)) {
  //     return;
  //   }
  //
  //   this.additionalServices = [
  //     ...this.additionalServices,
  //     this.additionalServiceItem,
  //   ];
  //
  //   this.listOfServices.emit(this.additionalServices);
  //
  //   this.additionalServiceItem = null;
  //   setTimeout(() => {
  //     this.resetInput = false;
  //   }, 0);
  // }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
