import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ChangeSellerDialogComponent } from '@app/cc-leader/clients/change-seller-dialog/change-seller-dialog.component';
import { DialogService } from '@ui/dialog/dialog.service';
import { AddTempSellerDialogComponent } from '@app/cc-leader/clients/add-temp-seller-dialog/add-temp-seller-dialog.component';
import { ToastMessageComponent } from '@app/cc-leader/toast-message/toast-message/toast-message.component';
import { UserService } from '@shared/services/user.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { LocationService } from '@shared/services/location.service';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { Subscription } from 'rxjs';
import { IClientWithPackagesItem } from '@shared/model/atlas.api';
import { OrderFormService } from '@app/cc-leader/services/order-form.service';

@Component({
  selector: 'atlas-checkbox-action-dialog',
  standalone: true,
  imports: [NgIf, ButtonModule, TooltipModule, ToastMessageComponent],
  templateUrl: './checkbox-action-dialog.component.html',
  styleUrl: './checkbox-action-dialog.component.scss',
})
export class CheckboxActionDialogComponent implements OnInit, OnDestroy {
  _subs: Subscription;
  agentName: string = '';
  packageIdsLength: number = 0;
  renewPackageId: string;
  selectedPackages: IClientWithPackagesItem[] = [];
  @Input() originalPackageSellers: Map<string, string[]> = new Map();
  @Input() originalPackageTempSellers: Map<string, string[]> = new Map();

  @Input() distinctVehicleCount: number = 0;
  @Input() canRenewPackage!: boolean;
  @Output() sellerChanged = new EventEmitter<any>();
  agentIds: string[] = [];
  packageIds: string[] = [];
  isTempSellerAssigned: boolean;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  constructor(
    private _dialogService: DialogService,
    private _packageCardService: PackageCardListService,
    private _locationService: LocationService,
    public userService: UserService,
    private _cpService: ClientPackageTableService,
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._subs = this._cpService.getSelectedPackages().subscribe((res) => {
      this.selectedPackages = res;
    });
  }

  handleAgentAssignment() {
    this.dialogConfig.header = 'Dodeli privremenom prodavcu';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      AddTempSellerDialogComponent,
      this.dialogConfig,
    );
    this._subs.add(
      dialogRef.onClose.subscribe((result) => {
        if (result?.isTempSellerAssigned) {
          // Reset showToast to true before displaying the toast again
          this.agentName = result.agentName;
          this.packageIdsLength = result.packageIdsLength;
          this.isTempSellerAssigned = true;
          this.sellerChanged.emit(result);
          this.agentIds = result.agentIds;
          this.packageIds = result.packageIds;
        }

        const temp = new Map<string, string[]>(this.originalPackageTempSellers);
        this.originalPackageTempSellers.clear();
        this.agentIds.forEach((agentId) => {
          this.originalPackageTempSellers.set(agentId, temp.get(agentId));
        });
      }),
    );
  }

  handleSellerChange() {
    this.dialogConfig.header = 'Promeni prodavca';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      ChangeSellerDialogComponent,
      this.dialogConfig,
    );
    this._subs.add(
      dialogRef.onClose.subscribe((result) => {
        if (result?.isSellerChanged) {
          this.agentName = result.agentName;
          this.agentIds = result.agentIds;
          this.isTempSellerAssigned = false;
          this.packageIds = result.packageIds;
          this.packageIdsLength = result.packageIdsLength;
          this.sellerChanged.emit(result);

          const temp = new Map<string, string[]>(this.originalPackageSellers);
          this.originalPackageSellers.clear();
          this.agentIds.forEach((agentId) => {
            this.originalPackageSellers.set(agentId, temp.get(agentId));
          });
        }
      }),
    );
  }

  handleRenewPackages() {
    this._packageCardService.setSelectedPackagesForRenew(
      this.selectedPackages.map((x) => {
        this.renewPackageId = x.clientPackageId;
        return {
          clientPackageId: x.clientPackageId,
          packageId: x.packageId,
        };
      }),
    );
    this._locationService.routeToRenewPackageForm(this.renewPackageId);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
