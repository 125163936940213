import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  CampaignFormService,
  EditCampaignForm,
} from '@app/cc-leader/services/campaign-form.service';
import {
  CampaignsClient,
  UpdateCampaignCommandBody,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-edit-campaign',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    InputTextComponent,
    NgIf,
    InputDescriptionComponent,
  ],
  templateUrl: './edit-campaign.component.html',
  styleUrl: './edit-campaign.component.scss',
})
export class EditCampaignComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  form: EditCampaignForm;
  campaignId: string;

  constructor(
    private _client: CampaignsClient,
    private _service: CampaignFormService,
    private _dialogRef: DialogRef,
    private _alertService: AlertService,
  ) {
    this.form = this._service.getEditForm();
  }

  ngOnInit(): void {
    this.campaignId = this._service.getCampaignId();
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.form.markAsTouched();
      return;
    }
    const updateCommand = new UpdateCampaignCommandBody({
      name: this.form.value.name,
      description: this.form.value.description,
    });
    this._subs.add(
      this._client
        .updateCampaign(this.campaignId, updateCommand)
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close(true);
        }),
    );
  }

  ngOnDestroy(): void {
    this._service.reset();
    this._subs.unsubscribe();
  }
}
