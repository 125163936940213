<div *ngIf="vehicleForm; else skeleton">
  <div class="dialog-subheader">Detalji vozila</div>
  <!-- HTML Structure -->
  <div class="flex flex-column gap-3 mt-3">
    <div class="flex justify-content-between gap-3">
      <!-- Tip vozila -->
      <atlas-vehicle-type-dropdown
        [typeCtrl]="vehicleForm.controls.type"
        (onTypeChanged)="handleTypeChange($event)"
        [isPartner]="true"
        class="w-full"
      ></atlas-vehicle-type-dropdown>
      <!-- Brend vozila -->
      <atlas-brand-dropdown
        [type]="vehicleForm.value.type"
        (onBrandChanged)="handleBrandChange($event)"
        [brandNameCtrl]="vehicleForm.controls.brand"
        [validateControl]="validateControls"
        class="w-full"
      ></atlas-brand-dropdown>
      <!-- Model vozila -->
      <atlas-model-dropdown
        [brandId]="selectedBrandId"
        [brandName]="vehicleForm.value.brand"
        [modelNameCtrl]="vehicleForm.controls.model"
        [isDisabled]="!vehicleForm.value.brand"
        [validateControl]="validateControls"
        class="w-full"
      ></atlas-model-dropdown>
    </div>

    <div class="flex justify-content-between gap-3">
      <!-- Registarska oznaka -->
      <atlas-input-text
        ctrlPlaceholder="XX000XX"
        [formCtrl]="vehicleForm.controls.licencePlate"
        class="w-full"
        formCtrlClass="uppercase"
        [showLabel]="false"
        [ctrlKeyFilter]="LicencePlateRegExp"
      ></atlas-input-text>
      <!-- Nosivost -->
      <atlas-input-addon
        ctrlPlaceholder="Nosivost"
        [formCtrl]="vehicleForm.controls.maxWeight"
        addonText="kg"
        [showLabel]="false"
        formCtrlFilter="num"
        class="w-full"
      ></atlas-input-addon>

      <!-- Broj putnika -->
      <atlas-num-of-passengers-dropdown
        [numOfPassCtrl]="vehicleForm.controls.seatsNumber"
        class="w-full"
      ></atlas-num-of-passengers-dropdown>
    </div>
  </div>

  <p-divider></p-divider>

  <!-- CENA -->
  <div class="price-part mt-3">
    <div class="price-input">
      <atlas-input-addon
        ctrlLabel="Cena po km"
        formCtrlId="price-per-km"
        ctrlPlaceholder="Cena"
        [formCtrl]="vehicleForm.controls.pricePerKm!"
        formCtrlFilter="money"
        addonText="rsd/km"
      ></atlas-input-addon>
    </div>
  </div>

  <p-divider></p-divider>

  <!-- LOKACIJA -->
  <div class="mt-3 w-full">
    <div class="flex flex-column gap-2">
      <label class="label">Lokacija</label>
      <span class="p-input-icon-left google-autocomplete">
        <i class="pi pi-map-marker"></i>
        <input
          id="search"
          #search
          placeholder="Lokacija vozila"
          [formControl]="vehicleForm.controls.place"
          [ngClass]="
            vehicleForm.controls.place.invalid &&
            (vehicleForm.controls.place.dirty ||
              vehicleForm.controls.place.touched)
              ? 'p-inputtext p-component w-full invalid'
              : 'p-inputtext p-component w-full'
          "
        />
      </span>
      <atlas-error-required
        [formCtrl]="vehicleForm.controls.place"
      ></atlas-error-required>
      <atlas-max-length
        [formCtrl]="vehicleForm.controls.place"
      ></atlas-max-length>
    </div>
    <div class="mt-3 w-full max-w-70rem">
      <google-map
        [center]="mapOptions.center"
        [zoom]="mapOptions.zoom"
        (mapClick)="addMarker($event)"
      >
        <map-marker
          [position]="markerPosition"
          [options]="markerOptions"
        ></map-marker>
      </google-map>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <div class="dialog-subheader">Detalji vozila</div>
  <!-- HTML Structure -->
  <div class="flex flex-column gap-3 mt-3">
    <div class="flex justify-content-between gap-3">
      <!-- Tip vozila -->
      <div class="w-10rem">
        <p-skeleton width="100%" height="33px"></p-skeleton>
      </div>
      <!-- Brend vozila -->
      <div class="w-10rem">
        <p-skeleton width="100%" height="33px"></p-skeleton>
      </div>
      <!-- Model vozila -->
      <div class="w-10rem">
        <p-skeleton width="100%" height="33px"></p-skeleton>
      </div>
    </div>

    <div class="flex justify-content-between gap-3">
      <!-- Registarska oznaka -->
      <div class="w-10rem">
        <p-skeleton width="100%" height="33px"></p-skeleton>
      </div>
      <!-- Nosivost -->
      <div class="w-10rem">
        <p-skeleton width="100%" height="33px"></p-skeleton>
      </div>

      <!-- Broj putnika -->
      <div class="w-10rem">
        <p-skeleton width="100%" height="33px"></p-skeleton>
      </div>
    </div>
  </div>

  <p-divider></p-divider>

  <!-- CENA -->
  <div class="w-full">
    <p-skeleton width="100%" height="33px"></p-skeleton>
  </div>

  <p-divider></p-divider>

  <!-- LOKACIJA -->
  <div class="w-full">
    <p-skeleton width="100%" height="533px"></p-skeleton>
  </div>
</ng-template>

<!--<pre>{{vehicleForm?.value | json}}</pre>-->
