import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { finalize, Subscription } from 'rxjs';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import { FormArray, FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {
  AddPartnerDriverCommand,
  DriversClient,
  PhoneItem,
  UpdateDriverCommand,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { ButtonComponent } from '@ui/button/button.component';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import { LoaderService } from '@shared/components/loader/loader.service';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { ContactPhoneListComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/contact-phone-list/contact-phone-list.component';
import { ContactFormService } from '../services/contact-form.service';
import {
  CreateDriverForm,
  PartnerFormService,
} from '@app/features/partners/services/partner-form.service';

@Component({
  selector: 'atlas-partner-driver-form',
  standalone: true,
  imports: [
    InputTextComponent,
    InputSwitchComponent,
    FormsModule,
    InputSwitchModule,
    FormControlPipe,
    JsonPipe,
    NgIf,
    InputPhoneComponent,
    ButtonComponent,
    NgFor,
    InputMailComponent,
    ContactPhoneListComponent,
  ],
  templateUrl: './partner-driver-form.component.html',
  styleUrl: './partner-driver-form.component.scss',
})
export class PartnerDriverFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form: CreateDriverForm;
  partnerId: string;
  driverId: string | undefined;
  sendInvite: boolean = false;
  isRegistered: boolean = false;
  showSwitch: boolean = true;

  constructor(
    private _formService: PartnerFormService,
    private _phonesService: ContactFormService,
    private _dialogRef: DialogRef,
    private _dialogConfig: DialogConfig,
    private _driverClient: DriversClient,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.form = this._formService.getDriverFrom();
    this.sendInvite = this.form.controls.sendInvite.value;

    if (this._dialogConfig.data?.driverId) {
      this.driverId = this._dialogConfig.data.driverId;
      this.showSwitch = false;
      this.loadDriverInfo();
    }

    this.partnerId = this._dialogConfig.data?.partnerId;

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        if (this._dialogConfig.data?.isEditMode) {
          this.handleEditDriver();
        } else {
          this.handleCreateDriver();
        }
      }),
    );
  }

  private _addPhones() {
    const phoneValues = this._phonesService.getPhones().value as PhoneItem[];
    phoneValues.forEach((x) => {
      this.form.controls.phones.push(this._phonesService.getPhoneForm(x));
    });

    this._removeEmptyPhones();
  }

  handleCreateDriver() {
    this._addPhones();

    this._phonesService.setSubmitted(true);

    if (!this.form?.valid) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Potrebno je uneti sva polja!');
      return;
    }
    this.form.value.partnerId = this.partnerId;

    this._loaderService.setLoadingText = 'Poziv se šalje vozaču...';
    this._loaderService.setShowLoader = true;
    this._dialogConfig.styleClass = 'display: hidden';

    this._subs.add(
      this._driverClient
        .createDriver(this.form.value as AddPartnerDriverCommand)
        .pipe(
          finalize(() => {
            this._loaderService.reset();
            this._dialogConfig.styleClass = 'display: block';
          }),
        )
        .subscribe((res) => {
          this._dialogRef.close();
          this._alertService.addSuccessMsg(res.result.value);
        }),
    );
  }

  handleEditDriver() {
    this._addPhones();

    this._phonesService.setSubmitted(true);

    const command = this.form.value as UpdateDriverCommand;
    command.driverId = this.driverId;

    this._subs.add(
      this._driverClient.updateDriver(command).subscribe((res) => {
        if (res.result.isSuccess) {
          this._alertService.addSuccessMsg(res.result.value);
          this._dialogRef.close();
        } else {
          this._alertService.addFailedMsg(res.result.value);
          this._dialogRef.close();
        }
      }),
    );
  }

  private _removeEmptyPhones() {
    let phonesFormArray = this.form.controls.phones as FormArray;
    let phonesToRemove = [];
    phonesFormArray.controls.forEach((phoneControl, index) => {
      if (phoneControl.value.label === '') {
        phonesToRemove.push(index);
      }
    });
    phonesToRemove.reverse().forEach((index) => {
      phonesFormArray.removeAt(index);
    });

    if (!this.form.valid) {
      return this._alertService.addWarnMsg('Morate uneti sva neophodna polja');
    }
  }

  private loadDriverInfo() {
    this._subs.add(
      this._driverClient.getForEdit(this.driverId).subscribe((res) => {
        this.form.controls.name.patchValue(res.result.value.name);
        this.form.controls.lastName.patchValue(res.result.value.lastName);
        this.form.controls.email.patchValue(res.result.value.email);
        this.isRegistered = res.result.value.isRegistered;
        this._patchPhones(res);
      }),
    );
  }

  private _patchPhones(res: any) {
    const phones = res.result.value.phones || [];

    const phonesFormArray = this._phonesService.getPhonesArray(phones);
    this._phonesService.setPhones(phonesFormArray);
  }

  handleSendInviteChange(event: any) {
    this.form.controls.sendInvite.patchValue(event.checked);
  }

  ngOnDestroy(): void {
    this._phonesService.setSubmitted(false);
    this._subs.unsubscribe();
    this.form.reset();
  }
}
