import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AdminService } from '@app/administrator/services/admin-service';
import { ControlsOf } from '@shared/forms/forms-common';
import { EmployeeRoleItem, EmployeesClient, IEmployeeRoleItem } from '@shared/model/atlas.api';
import { MultiselectComponent } from '@ui/multiselect/multiselect.component';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-role-multiselect',
  standalone: true,
  imports: [
    MultiselectComponent
  ],
  templateUrl: './role-multiselect.component.html',
  styleUrl: './role-multiselect.component.scss'
})
export class RoleMultiselectComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  roleOptions: IEmployeeRoleItem[] = [];
  formGroup: FormGroup<RolesForm>;

  @Input() rolesCtrl: FormArray;
  @Input() showLabel: boolean = true;

  constructor(
    private _employeeClient: EmployeesClient,
  ) {
    this.formGroup = new FormGroup({
      roles: new FormControl([]),
    });
    this._load();
  }

  ngOnInit(): void {
    if (this.rolesCtrl?.value.length > 0) {
      this._load().then(() => {
          this.formGroup.controls.roles.setValue(this.rolesCtrl.value.map((id) =>
          this.roleOptions.find((opt) => opt.id === id) || null
        ));
      })
    } 

    this._subs.add(
      this.formGroup?.valueChanges.subscribe((value) => {
        this.rolesCtrl.clear();
        value.roles.forEach((x) => {
          this.rolesCtrl.push(new FormControl(x.id));
        });
      }),
    );
  
    this._subs.add(
      this.rolesCtrl?.valueChanges.subscribe((value) => {
        this.formGroup.controls.roles.setValue(value.map((id) =>
          this.roleOptions.find((opt) => opt.id === id) || null
        ), { emitEvent: false });
      }),
    );
  }

  public patchRoles(roles :EmployeeRoleItem[]): void {
    this.formGroup.controls.roles.setValue(roles);
  }

  public resetRolesCtrl(): void {
    this.rolesCtrl.clear();
    this.formGroup.controls.roles.setValue([]);
  }

  private _load(): Promise<any> {
    return this._getRoles().then((res) => {
      this.roleOptions = res.result.roles;
    });
  }

  private async _getRoles() {
    return await firstValueFrom(this._employeeClient.getEmployeeRoles());
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

export type RolesForm = {
  roles: FormControl<IEmployeeRoleItem[]>
} 