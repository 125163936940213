import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { typedKeys } from '@shared/functions/functions';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const _alertService = inject(AlertService);
  const _authService = inject(AuthService);
  const _loaderService = inject(LoaderService);

  const jwtToken = _authService.getToken;
  if (jwtToken) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
  }

  return next(req).pipe(
    catchError((err) => {
      //return throwError(() => err);
      _loaderService.reset();

      if (
        err instanceof HttpErrorResponse &&
        err.error instanceof Blob &&
        (err.error.type === 'application/json' ||
          err.error.type === 'application/problem+json') &&
        [400, 500].includes(err.status)
      ) {
        // https://github.com/angular/angular/issues/19888
        // When request of type Blob, the error is also in Blob instead of object of the json data
        return new Promise<any>((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = (e: Event) => {
            try {
              const errorResponse = JSON.parse((<any>e.target).result);
              if (errorResponse.exception != undefined) {
                _alertService.addFailedMsg(errorResponse.exception);
              } else {
                if (errorResponse.errors instanceof Array) {
                  _alertService.addFailedMsg(errorResponse.errors[0].message);
                } else if (errorResponse.errors instanceof Object) {
                  let messages: string[] = [];
                  typedKeys(errorResponse.errors).forEach((error: string) => {
                    messages.push(
                      `${error}: ${errorResponse.errors[error]
                        .map((k: string) => k)
                        .join(', ')}`,
                    );
                  });
                  _alertService.addFailedMsgs(messages);
                } else if (errorResponse.detail) {
                  _alertService.addFailedMsg(errorResponse.detail);
                } else {
                  _alertService.addFailedMsg(errorResponse.title);
                }
              }
              reject(
                new HttpErrorResponse({
                  error: errorResponse,
                  headers: err.headers,
                  status: err.status,
                  statusText: err.statusText,
                  url: err.url!,
                }),
              );
            } catch (e) {
              reject(err);
            }
          };
          reader.onerror = (e) => {
            reject(err);
          };
          reader.readAsText(err.error);
        });
      }
      return throwError(() => err);
    }),
  );
};
