import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IVehicleTypeItem, VehiclesClient } from '@shared/model/atlas.api';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'atlas-vehicle-type-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './vehicle-type-dropdown.component.html',
  styleUrl: './vehicle-type-dropdown.component.scss',
})
export class VehicleTypeDropdownComponent implements OnInit, OnDestroy {
  searchOptions: SelectItem[] = [];

  private _subs: Subscription;
  @Input() typeCtrl: FormControl<string>;
  @Input() ctrlStyleClass: string = 'w-full';
  @Input() isPartner: boolean = false;

  @Output() public onTypeChanged = new EventEmitter<string>();

  constructor(
    private _client: VehiclesClient,
    private _newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._load();
  }

  private _load() {
    let defaultValue = '';
    this._getTypes().then((res) => {
      if (this.isPartner) {
        this.searchOptions = [
          {
            label: 'Teretna vozila',
            value: 'Teretna vozila',
          },
          {
            label: 'Putnička vozila',
            value: 'Putnička vozila',
          },
        ];
        defaultValue = this.searchOptions.find((x) =>
          x.value.includes('Teretna'),
        ).value;
      } else {
        this.searchOptions = res.result.responseList.map((v) => ({
          label: v.name,
          value: v.name,
        }));
        defaultValue = this.searchOptions.find((x) =>
          x.value.includes('Put'),
        ).value;
      }
      if (!this.typeCtrl.value) {
        //this.typeCtrl.patchValue(defaultValue);
        this.handleTypeChange({ value: defaultValue });
      }
    });
  }

  private async _getTypes() {
    return await firstValueFrom(this._client.getTypes());
  }

  handleTypeChange($event: any) {
    if ($event.value.includes('Mot')) {
      this._newVehicleService.setShowMotorModelInput(true);
    }

    this.onTypeChanged.emit($event.value);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
