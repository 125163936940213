import { CommonModule, NgFor, NgIf, NgOptimizedImage, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { CampaignsClient, IPaginationResponseOfLeadCommentResponse } from '@shared/model/atlas.api';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { BadgeModule } from 'primeng/badge';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-comments-preview',
  standalone: true,
  imports: [BadgeModule,
    NgFor,
    NgIf,
    NgOptimizedImage,
    NgTemplateOutlet,
    NgStyle,
    ScrollPanelModule,
    CommonModule
  ],
  templateUrl: './comments-preview.component.html',
  styleUrl: './comments-preview.component.scss'
})
export class CommentsPreviewComponent implements OnInit, OnChanges {
  campaignId: string;
  paginatedData: IPaginationResponseOfLeadCommentResponse;
  currentPage: number = 1;
  pageSize: number = 6;
  @Input() shouldReloadComments: boolean = false;

  constructor(private route: ActivatedRoute,
    private _camapaignClient: CampaignsClient,
    private _tableService: TableServiceLocal,
    private service: CampaignFormService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shouldReloadComments']?.currentValue) {
      this.load();
      this.shouldReloadComments = false;
    }
  }

  ngOnInit(): void {
    this.campaignId = this.route.snapshot.paramMap.get('id')!;
    this.load()
  }

  private async _getList() {
    return await firstValueFrom(
      this._camapaignClient.getLeadComments(
        this.campaignId,
        this.paginatedData?.currentPage ?? 1,
        this.paginatedData?.pageSize
      )
    )
  }

  load() {
    this._tableService.setIsLoading();
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    })
  }

  getStatusColor(status: number): string {
    return this.service.getColor(status);
  }

}
