import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChangeSellerDialogComponent } from '../change-seller-dialog/change-seller-dialog.component';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { AgentAutocompleteComponent } from '@app/cc-leader/clients/agent-autocomplete/agent-autocomplete.component';
import { Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import {
  AssignPackagesToTempSellerCommand,
  OrdersClient,
} from '@shared/model/atlas.api';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { NgOptimizedImage } from '@angular/common';
import { LoaderService } from '@shared/components/loader/loader.service';
import { DialogConfig } from '@ui/dialog/dialog-config';

@Component({
  selector: 'atlas-add-temp-seller-dialog',
  standalone: true,
  imports: [
    ChangeSellerDialogComponent,
    AgentAutocompleteComponent,
    NgOptimizedImage,
  ],
  templateUrl: './add-temp-seller-dialog.component.html',
  styleUrl: './add-temp-seller-dialog.component.scss',
})
export class AddTempSellerDialogComponent implements OnInit, OnDestroy {
  totalClients: any;
  totalPackages: any;
  agentImageUrl: string;
  agentName: string;
  sellerName: string;
  agentIds: string[] = [];
  cpIds: string[] = [];
  private _subs: Subscription = new Subscription();

  client: any[];
  selectedAgentId: string;

  constructor(
    private clientService: ClientPackageTableService,
    private _alertService: AlertService,
    private _client: OrdersClient,
    private _dialogRef: DialogRef,
    private _loaderService: LoaderService,
    private _dialogConfig: DialogConfig,
  ) {}

  ngOnInit(): void {
    this._loadClientData();
    this._setAgentInfo();
    this._listenForSubmit();
  }

  private _loadClientData(): void {
    this.cpIds = [];
    this._subs.add(
      this.clientService.getSelectedPackages().subscribe((res) => {
        this.agentIds = [];
        res.forEach((pkg) => {
          this.cpIds.push(pkg.clientPackageId);
          this.agentIds.push(pkg.tempSellerId);
        });
      }),
    );

    this.totalClients = this.clientService.getSelectedCpsCount();
    this.totalPackages = this.clientService.getSelectedPackagesCount();
  }

  private _setAgentInfo(): void {
    const clients = this.clientService.getSelectedCps();
    const uniqueSellers = [...new Set(clients.map((client) => client.seller))];
    if (uniqueSellers.length === 1) {
      this.sellerName = uniqueSellers[0];
      this.agentImageUrl =
        clients.find((client) => client.seller === uniqueSellers[0])
          ?.sellerImage || '../../../../assets/images/client-icon.svg';
    } else {
      this.sellerName = `Ukupno ${uniqueSellers.length}`;
      this.agentImageUrl = '../../../../assets/images/client-icon.svg';
    }
  }

  private _listenForSubmit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  onAgentSelected(agent: { id: string; name: string }): void {
    this.selectedAgentId = agent.id;
    this.agentName = agent.name;
  }

  public _handleSave() {
    let isTempSellerAssigned = false;
    this._loaderService.setLoadingText =
      'Dodeljivanje privremenog prodavca ...';
    this._loaderService.setShowLoader = true;
    this._dialogConfig.styleClass = 'display: hidden';
    this._subs.add(
      this._client
        .assignPackagesToTempSeller(
          new AssignPackagesToTempSellerCommand({
            clientPackageIds: this.cpIds,
            toTempSellerId: this.selectedAgentId,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          isTempSellerAssigned = true;
          this._loaderService.reset();

          this._dialogConfig.styleClass = 'display: block';
          this.exit(isTempSellerAssigned);
        }),
    );
  }

  public exit(isTempSellerAssigned: boolean) {
    this._dialogRef.close({
      isTempSellerAssigned: isTempSellerAssigned,
      packageIdsLength: this.cpIds.length,
      agentName: this.agentName,
      agentIds: this.agentIds,
      packageIds: this.cpIds,
    });
  }

  ngOnDestroy(): void {
    this.clientService.initSelectedPackages([]);
    this.clientService.resetSelectedCps();
    this._subs.unsubscribe();
  }
}
