<!-- TITLE -->
<div class="step-title">Korak 1</div>
<div class="text-3xl font-semibold mt-1">Detalji</div>

<!-- PODACI O KLIJENTU -->
<div
  [ngClass]="[
    form.controls.client.invalid &&
    (form.controls.client.dirty || form.controls.client.touched)
      ? 'flat-card mt-4 invalid-card'
      : 'flat-card mt-4',
    isDisabled ? 'disable-wrapper' : ''
  ]"
>
  <div class="header">Podaci o klijentu</div>
  <div class="content flex flex-column gap-3">
    <atlas-inline-wrapper ctrlLabel="Pretraga" formCtrlId="search">
      <div class="flex">
        <atlas-dropdown
          [options]="searchOptions"
          [formCtrl]="searchOptionCtrl"
          [showLabel]="false"
          ctrlStyleClass="input-next-to w-10rem"
        ></atlas-dropdown>
        <atlas-client-for-assistance-autocomplete></atlas-client-for-assistance-autocomplete>
      </div>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <atlas-inline-wrapper
      ctrlLabel="Klijent"
      ctrlDesc="Ime i prezime klijenta"
      formCtrlId="client"
    >
      <atlas-input-text
        [formCtrl]="form.controls.client.controls.clientName! | formControl"
        formCtrlId="client"
        [showLabel]="false"
        formCtrlClass="w-30rem"
      ></atlas-input-text>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <atlas-inline-wrapper
      ctrlLabel="Kontakt telefon"
      ctrlDesc="Dodatni kontakt telefon se veze samo za asistenciju i nece menjati podatke o klijentu"
      formCtrlId="client">
      <atlas-contact-phone-list class="w-30rem"></atlas-contact-phone-list>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <atlas-inline-wrapper ctrlLabel="E-mail" formCtrlId="email">
      <atlas-input-mail
        [formCtrl]="form.controls.client.controls.email! | formControl"
        formCtrlId="email"
        [showLabel]="false"
        formCtrlClass="w-30rem"
      ></atlas-input-mail>
    </atlas-inline-wrapper>
  </div>
</div>

<!-- PODACI O VOZILU -->
<div
  [ngClass]="[
    form.controls.vehicle.invalid &&
    (form.controls.vehicle.dirty || form.controls.vehicle.touched)
      ? 'flat-card mt-4 invalid-card'
      : 'flat-card mt-4',
    isDisabled ? 'disable-wrapper' : ''
  ]"
>
  <div class="header">Podaci o vozilu</div>
  <div class="content flex flex-column gap-5">
    <atlas-inline-wrapper ctrlLabel="Vozilo" formCtrlId="assistance-vehicle">
      <atlas-assistance-vehicle></atlas-assistance-vehicle>
    </atlas-inline-wrapper>
  </div>
</div>

<!-- LOKACIJA -->
<div
  [ngClass]="
    (form.controls.from.invalid &&
      (form.controls.from.dirty || form.controls.from.touched)) ||
    (form.controls.to.invalid &&
      (form.controls.to.dirty || form.controls.to.touched)) ||
    (form.controls.numOfPassengers.invalid &&
      (form.controls.numOfPassengers.dirty ||
        form.controls.numOfPassengers.touched))
      ? 'flat-card mt-4 invalid-card'
      : 'flat-card mt-4'
  "
>
  <div class="header">Lokacija</div>
  <div class="content flex flex-column gap-5">
    <atlas-inline-wrapper
      ctrlLabel="Mesto kvara i odredište"
      formCtrlId="location"
    >
      <atlas-location-map class="w-30rem"></atlas-location-map>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <atlas-inline-wrapper ctrlLabel="Broj putnika" formCtrlId="passengers-num">
      <atlas-num-of-passengers-dropdown
        ctrlStyleClass="w-17rem"
      ></atlas-num-of-passengers-dropdown>
    </atlas-inline-wrapper>
  </div>
</div>

<!-- PODACI O KVARU -->
<div
  [ngClass]="
    (form.controls.failureType.invalid &&
      (form.controls.failureType.dirty || form.controls.failureType.touched)) ||
    (form.controls.failureDescription.invalid &&
      (form.controls.failureDescription.dirty ||
        form.controls.failureDescription.touched))
      ? 'flat-card mt-4 invalid-card'
      : 'flat-card mt-4'
  "
>
  <div class="header">Podaci o kvaru vozila</div>
  <div class="content flex flex-column gap-5">
    <atlas-inline-wrapper ctrlLabel="Vrsta kvara" formCtrlId="failure-type">
      <atlas-failure-type-dropdown></atlas-failure-type-dropdown>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <atlas-inline-wrapper ctrlLabel="Opis kvara" formCtrlId="failure-desc">
      <atlas-input-textarea
        formCtrlId="failure-desc"
        ctrlPlaceholder="Opis kvara"
        [formCtrl]="form.controls.failureDescription"
      ></atlas-input-textarea>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <atlas-inline-wrapper
      ctrlLabel="Napomena (opciono)"
      formCtrlId="failure-note"
    >
      <atlas-input-textarea
        formCtrlId="failure-note"
        ctrlPlaceholder="Napomena"
        [formCtrl]="form.controls.failureNote"
      ></atlas-input-textarea>
    </atlas-inline-wrapper>
  </div>
</div>

<!-- ASISTENCIJA -->
<div
  [ngClass]="
    (form.controls.clientPackageId.invalid &&
      (form.controls.clientPackageId.dirty ||
        form.controls.clientPackageId.touched)) ||
    (form.controls.serviceId.invalid &&
      (form.controls.serviceId.dirty || form.controls.serviceId.touched))
      ? 'flat-card mt-4 invalid-card'
      : 'flat-card mt-4'
  "
>
  <div class="header">Asistencija</div>
  <div class="content flex flex-column gap-5">
    <atlas-inline-wrapper ctrlLabel="Tip usluge" formCtrlId="service-type">
      <atlas-package-for-assistance></atlas-package-for-assistance>
    </atlas-inline-wrapper>
  </div>
</div>
