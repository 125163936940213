<atlas-scrollable-page
  pageTitle="Dodaj paketa"
  submitBtnLabel="Potvrdi kupovinu"
  submitBtnIcon="pi pi-box"
  (onCancelAction)="handleCancelForm()"
>
  <ng-template #content>
    <div class="main-container">
      <div class="left-section">
        <div class="flex align-items-center justify-content-between">
          <!-- Vehicle Icon and Name -->
          <div class="flex gap-3 align-items-center">
            <div
              class="border-round-md flex justify-content-center align-items-center card-icon"
            >
              <i [ngClass]="'text-2xl m-2 icon pi pi-car'"></i>
            </div>
            <div class="flex flex-column justify-content-between gap-1 ml-3">
              <div class="font-semibold text-base">
                {{ packageData.vehicleName }}
              </div>
            </div>
          </div>

          <!-- Active Packages -->
          <div class="font-semibold text-base active-packages">
            <div class="active-packages">
              Aktivni paketi:
              <span style="color: var(--text-color)">1</span> (2)
            </div>
          </div>
        </div>
        <p-divider></p-divider>
        <div style="width: 60%">
          <atlas-package-card-list [isRenew]="false"></atlas-package-card-list>
        </div>
        <p-divider></p-divider>
        <div class="flex flex-column gap-6 mt-5">
          <div>
            <p-selectButton
              [options]="stateOptions"
              [(ngModel)]="stateValue"
              optionLabel="label"
              optionValue="value"
              [allowEmpty]="false"
              (onChange)="handleStateChange($event)"
            />
          </div>
        </div>
        <div class="mt-3">
          <atlas-dropdown
            ctrlLabel="Način isporuke"
            [options]="deliveryTypeOptions"
            optionLabel="label"
            [formCtrl]="deliveryTypeCtrl | formControl"
            ctrlStyleClass="w-full lg:w-19rem"
            ctrlPlaceholder="Način isporuke"
          ></atlas-dropdown>
        </div>
        <div class="mt-3">
          <atlas-dropdown
            *ngIf="stateValue === 'draft'"
            ctrlLabel="Način plaćanja"
            optionLabel="label"
            [options]="draftPaymentTypeOptions"
            [formCtrl]="paymentTypeCtrl | formControl"
            ctrlStyleClass="w-full lg:w-19rem"
            ctrlPlaceholder="Način placanja"
          ></atlas-dropdown>
          <atlas-dropdown
            *ngIf="stateValue === 'new'"
            ctrlLabel="Način plaćanja"
            optionLabel="label"
            [options]="newPaymentTypeOptions"
            [formCtrl]="paymentTypeCtrl | formControl"
            ctrlStyleClass="w-full lg:w-19rem"
            ctrlPlaceholder="Način placanja"
          ></atlas-dropdown>
        </div>
        <div class="mt-3">
          <div class="title-text">Od kada želite da vam se aktivira paket?</div>
          <div class="mt-3" *ngFor="let type of activationOptions">
            <p-radioButton
              [formControl]="orderForm.controls.isUrgent | formControl"
              [value]="type.value"
              [inputId]="type.name"
            ></p-radioButton>
            <label class="ml-3 cursor-pointer" [for]="type.name">{{
              type.name
            }}</label>
          </div>
          <small *ngIf="!orderForm.controls.isUrgent.valid" class="text-red-500"
            >Obavezno polje</small
          >
        </div>
      </div>
      <div class="right-section">
        <div class="purchase-card">
          <h4 class="purchase-title">Kupovina</h4>
          <div class="purchase-content">
            <div *ngIf="validPackages.length === 0" class="flex column center-content">
            <img
              [ngSrc]="'assets/images/package-buy-icon.svg'"
              width="64"
              height="64"
              alt="user-image"
              
            />
            
              <span class="description-title">Bez izabranog paketa</span>
              <span class="description-text"
                >Nije izabran jos ni jedan paket. Ovde ce biti prikazana ukupna
                cena narudzbenice.</span
              >
            </div>
            <div *ngIf="validPackages.length > 0">
              <div *ngFor="let package of packages.value; let idx = index" class="item mt-3">
                <span class="total-amount">{{ package.name }}</span>
                <span class="total-count ml-2">{{ package.price | currency }}</span>
              </div>
            </div>
          </div>
          <div class="purchase-total">
            <span class="total-amount">Ukupno</span>
            <span class="total-count">{{packagesSum}}rsd</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</atlas-scrollable-page>
