@for (pkgCard of packages.controls; let idx = $index; track pkgCard.value.id) {
  <div class="package-container mt-5">
    <!-- Package Header -->
    <div class="header-container mt-2">
      <span class="title-text">Paket {{ idx + 1 }}</span>
      <i
        (click)="!isRenew && removePackage($event, idx)"
        class="pi pi-trash pointer"
        hover-class="hovered"
      ></i>
    </div>

    <div
      class="package-header mt-3 cursor-pointer"
      [ngClass]="{ disabled: dropdownDisabled }"
      (click)="!dropdownDisabled ? togglePackageDropdown(idx) : null"
    >
      <div class="flex align-items-center">
        <img
          ngSrc="../../../../assets/images/package.svg"
          width="40"
          height="40"
          alt="pkg-image"
        />
        <span class="package-text">{{
            pkgCard.value.name || "Izaberi paket"
          }}</span>

        <i
          [ngClass]="
            selectedIdx === idx ? 'pi pi-angle-up' : 'pi pi-angle-down'
          "
          class="ml-1"
        ></i>
      </div>

      <div class="flex flex-column gap-2">
        <div
          class="package-price original"
          *ngIf="pkgCard.value.originalPrice !== pkgCard.value.price"
        >
          {{ pkgCard.value.originalPrice | currencyNumber }}
        </div>
        <div class="package-price">
          {{ pkgCard.value.price | currencyNumber }}
        </div>
      </div>
    </div>

    <div *ngIf="selectedIdx === idx" class="package-dropdown mt-2">
      <div
        *ngFor="let pkg of allPackages"
        (click)="selectPackage(pkg, idx)"
        class="package-item"
      >
        {{ pkg.name }} - {{ decimalPipe.transform(pkg.price, "1.2-2") }} RSD
      </div>
    </div>

    <!-- Cena input -->
    <div class="mt-3" *ngIf="pkgCard && showPriceInput">
      <atlas-input-addon
        [formCtrl]="pkgCard.controls.price"
        addonText="RSD"
        ctrlPlaceholder="Cena"
        [isPriceFormat]="true"
      ></atlas-input-addon>
    </div>

    <!-- ActioinPriceDropdown -->
    <div class="mt-3">
      <atlas-internal-promo-code-dropdown
        [packageId]="pkgCard.value.id"
        (onChange)="handleInternalDiscountChanged($event, idx)"
      >
      </atlas-internal-promo-code-dropdown>
    </div>

    <!--<div class="dropdown-div mt-3" *ngIf="pkg">
      <p-dropdown
        [options]="actionPricesDropdown[idx]"
        optionLabel="label"
        [placeholder]="'Izaberite akcijsku cenu'"
        (onChange)="handleActionPriceChange($event, idx)"
      >
        <ng-template let-selected pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <span class="font-bold text-sm">{{ selected.label }}</span>
            <span *ngIf="!showPriceInput" class="dot">•</span>
            <span class="font-normal text-sm">{{
              decimalPipe.transform(selected.price, "1.2-2")
            }}</span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="flex align-items-center gap-2">
            <span class="font-bold text-sm">{{ item.label }}</span>
            <span *ngIf="!showPriceInput" class="dot">•</span>
            <span class="font-normal text-sm">{{
              decimalPipe.transform(item.price, "1.2-2")
            }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>-->
  </div>
}

<div class="max-w-9rem cursor-pointer">
  <atlas-button
    *ngIf="showAddNewPackageBtn"
    btnIcon="pi pi-plus"
    btnLabel="Dodaj paket"
    btnClass="pl-0"
    (btnClicked)="addEmptyPackageCard()"
  ></atlas-button>
</div>
