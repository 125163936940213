import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  CampaignLeadStatus,
  IUpdateCampaignCommandBody,
  IUpdateLeadStatusCommandBody,
} from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CampaignFormService {
  private _editCampaignBody: EditCampaignForm;
  private campaignId: string | null = null;
  private _statusForm: ChangeStatusForm;

  constructor(private _fb: FormBuilder) {
    this._editCampaignBody = this._initEditForm();
    this._statusForm = this._initStatusForm();
  }

  private leadTransferData = new BehaviorSubject<LeadTransferData>(null);
  leadTransferData$ = this.leadTransferData.asObservable();
  setLeadTransferData(data: LeadTransferData) {
    this.leadTransferData.next(data);
  }

  getLeadTransferData() {
    return this.leadTransferData.asObservable();
  }

  getLeadTransferDataValue() {
    return this.leadTransferData.value;
  }

  setCampaignId(id: string): void {
    this.campaignId = id;
  }

  getCampaignId(): string | null {
    return this.campaignId;
  }

  getEditForm() {
    if (!this._editCampaignBody) {
      this._editCampaignBody = this._initEditForm();
    }
    return this._editCampaignBody;
  }

  getStatusForm() {
    if (!this._statusForm) {
      this._statusForm = this._initStatusForm();
    }
    return this._statusForm;
  }

  private _initStatusForm(model?: IUpdateLeadStatusCommandBody){
    return this._fb.group<ControlsOf<IUpdateLeadStatusCommandBody>>({
      agentId: this._fb.control(model?.agentId ?? null),
      comment:this._fb.control(model?.comment ?? null, {
        validators: [Validators.maxLength(200)]
      }),
      dateTime: this._fb.control<Date>(model?.dateTime ?? null),
      status: this._fb.control<CampaignLeadStatus>(
        model?.status
      )
    })
  }

  private _initEditForm(model?: IUpdateCampaignCommandBody) {
    return this._fb.group<ControlsOf<IUpdateCampaignCommandBody>>({
      name: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.maxLength(100)],
      }),
    });
  }

  getColor(status: number): string {
    const colorMap = {
      [CampaignLeadStatus.DEFAULT]: '#999999',
      [CampaignLeadStatus.PURCHASED]: '#1EC360',
      [CampaignLeadStatus.NOT_BUYING]: '#F04438',
      [CampaignLeadStatus.NOT_ANSWERING_1]: '#EB9229',
      [CampaignLeadStatus.NOT_ANSWERING_2]: '#EB9229',
      [CampaignLeadStatus.NOT_ANSWERING_3]: '#EB9229',
      [CampaignLeadStatus.CALL_AGAIN_1]: '#1E7EC3',
      [CampaignLeadStatus.CALL_AGAIN_2]: '#1E7EC3',
      [CampaignLeadStatus.CALL_AGAIN_3]: '#1E7EC3',
      [CampaignLeadStatus.CLAIMED]: '#1EC360',
      [CampaignLeadStatus.DIED]: '#F04438',
      [CampaignLeadStatus.DO_NOT_CALL]: '#F04438',
      [CampaignLeadStatus.NOT_DRIVER]: '#999999'
    };
    return colorMap[status] || '#999999';
  }

  reset() {
    this._editCampaignBody = undefined;
  }
}

export declare type EditCampaignForm = FormGroup<
  ControlsOf<IUpdateCampaignCommandBody>
>;
export declare type ChangeStatusForm = FormGroup<
  ControlsOf<IUpdateLeadStatusCommandBody>
  >;

export declare type LeadTransferData = {
  address: string;
  leadName: string;
  leadLastName: string;
  leadPhone: string;
  leadEmail: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleYear: string;
  registationNumber: string;
  chassis: string
}

