<div>
  <atlas-input-search
    (onChange)="onSelectChange($event)"
    (onSearch)="handleSearch($event)"
    [suggestions]="agentNames"
    ctrlPlaceholder="Pretraži"
    class="search-input"
    [isDisabled]="false"
    [shouldReset]="resetInput"
  ></atlas-input-search>
</div>
