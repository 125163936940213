@for (phoneItem of phoneItems?.value; let idx = $index; track phoneItem.id) {
  <label *ngIf="showLabel">Broj telefona</label>
  <div class="flex gap-1 phone-list mt-2">
    <atlas-contact-phone-input
      [phone]="phoneItem.label"
      [id]="phoneItem.id"
      class="w-full"
    ></atlas-contact-phone-input>

    <atlas-button
      *ngIf="idx > 0"
      btnIcon="pi pi-trash"
      [btnStyle]="ButtonStyle.TEXT"
      [btnSeverity]="ButtonSeverity.DANGER"
      btnClass="pl-0"
      (btnClicked)="handleRemoveContact(idx)"
    ></atlas-button>
  </div>
}

<atlas-button
  btnIcon="pi pi-plus"
  btnLabel="Dodatni kontakt"
  btnClass="pl-0"
  (btnClicked)="handleAddEmptyContact()"
></atlas-button>
