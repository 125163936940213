import { DatePipe, NgClass, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, NgModel } from '@angular/forms';
import { AgentListImagesComponent } from '@app/cc-leader/campaigns/agents/agent-list-images/agent-list-images.component';
import { CampaignCardComponent } from '@app/cc-leader/campaigns/campaign-card/campaign-card.component';
import { InputButtonComponent } from '@shared/components/input-button/input-button.component';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CampaignsClient,
  CampaignStatus,
  CampaignType,
  ICampaignResponse,
} from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-campaign-table',
  standalone: true,
  imports: [
    PageComponent,
    NgClass,
    NgFor,
    AgentListImagesComponent,
    DataViewModule,
    CampaignCardComponent,
    DatePipe,
    ButtonComponent,
    FormsModule,
    DropdownModule,
    InputButtonComponent,
  ],
  templateUrl: './campaign-table.component.html',
  styleUrl: './campaign-table.component.scss',
})
export class CampaignTableComponent implements OnInit {
  leads = [];
  selectedRows: number;
  countLeads: number;
  campaignTable = new FormControl('');

  rowsOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
  ];

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  constructor(private _camapaignClient: CampaignsClient,
    private _location: LocationService
  ) {
    this.selectedRows = 5;
  }

  ngOnInit(): void {
    this.loadLeads();
  }

  handleMoreDetails() {
    this._location.routeToScheduledCalls()
  }

  getCampaignStatus(status: number): string {
    switch (status) {
      case CampaignStatus.IN_PROGRESS:
        return 'U toku';
      case CampaignStatus.DONE:
        return 'Završena';
      case CampaignStatus.CANCELLED:
        return 'Prekinuta';
      default:
        return 'Nepoznato';
    }
  }

  private getFlagColor(priority: number): string {
    switch (priority) {
      case 4:
        return '#F04438';
      case 3:
        return '#EB9229';
      case 2:
        return '#1E7EC3';
      case 1:
        return '#1EC360';
      case 0:
        return '#999999';
      default:
        return '#999999';
    }
  }

  async loadLeads() {
    await this._fetchLeads().then((res) => {
      this.leads = res.result.data.map((item: ICampaignResponse) => ({
        id: item.id,
        title: item.title,
        user: item.createdBy.name,
        status: this.getCampaignStatus(item.status),
        description: item.description || 'Bez opisa',
        date: item.endsOn,
        flagColor: this.getFlagColor(item.priority),
        info: item.type,
        totalUsers: item.leadsCount,
        agents: [...item.sellers, ...item.tempSellers],
      }));
      this.countLeads = this.leads.length;
    });
  }

  private async _fetchLeads() {
    return await firstValueFrom(
      this._camapaignClient.getCampaigns(CampaignType.LEADS),
    );
  }
}
