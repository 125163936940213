import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  ICreatePromoCodeCommand,
  PriceValueType,
  PromoCodeCountType,
  PromoCodeFromType,
  PromoCodeToType,
} from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeService {
  constructor(private _fb: FormBuilder) {}

  createPromoCode: PromoCodeForm;

  selectedPromoCode$ = new BehaviorSubject<string>(undefined);

  set setSelectedPromoCodeId(value: string) {
    this.selectedPromoCode$.next(value);
  }

  get getSelectedPromoCodeId() {
    return this.selectedPromoCode$.asObservable();
  }

  // ToDo: prebaciti u poseban fajl
  initCodeForm(model?: ICreatePromoCodeCommand) {
    this.createPromoCode = this._fb.group<ControlsOf<ICreatePromoCodeCommand>>({
      id: this._fb.control(model?.id, {
        nonNullable: false,
      }),
      name: this._fb.control(model?.name, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      value: this._fb.control(model?.value, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      valueType: this._fb.control<PriceValueType>(
        model?.valueType ?? PriceValueType.CASH,
        {
          nonNullable: true,
          validators: [Validators.required],
        },
      ),
      countType: this._fb.control<PromoCodeCountType>(
        model?.countType ?? PromoCodeCountType.TOTAL_NUMBER,
        {
          nonNullable: true,
          validators: [Validators.required],
        },
      ),
      count: this._fb.control<number | null>(model?.count ?? null, {
        validators: [Validators.min(1)],
      }),
      fromType: this._fb.control<PromoCodeFromType>(
        model?.fromType ?? PromoCodeFromType.NOW,
        {
          nonNullable: true,
          validators: [Validators.required],
        },
      ),
      toType: this._fb.control<PromoCodeToType>(
        model?.toType ?? PromoCodeToType.UNLIMITED,
        {
          nonNullable: true,
          validators: [Validators.required],
        },
      ),
      forAllPackages: this._fb.control(model?.forAllPackages ?? false),
      packageIds: this._fb.array<FormControl<string>>([], {
        validators: [Validators.required],
      }),
      from: this._fb.control(model?.from) as any,
      to: this._fb.control(model?.to) as any,
      isOnWeb: this._fb.control<boolean>(model?.isOnWeb ?? true, {
        nonNullable: true,
      }),
      isOnMobile: this._fb.control<boolean>(model?.isOnMobile ?? true, {
        nonNullable: true,
      }),
      isInternal: this._fb.control<boolean>(model?.isInternal ?? false, {
        nonNullable: true,
      }),
    });
    return this.createPromoCode;
  }

  reset() {}
}

export declare type PromoCodeForm = FormGroup<
  ControlsOf<ICreatePromoCodeCommand>
>;
