import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import { UserService } from '@shared/services/user.service';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { ButtonModule } from 'primeng/button';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { LocationService } from '@shared/services/location.service';
import { SpeedDialModule } from 'primeng/speeddial';
import { MenuItem } from 'primeng/api';

enum MenuSection {
  UPRAVLJANJE = 0,
  NAVIGACIJA = 1,
  OSTALO = 2,
}

@Component({
  selector: 'atlas-menu',
  standalone: true,
  imports: [
    ScrollPanelModule,
    NgIf,
    NgForOf,
    MenuItemComponent,
    ButtonModule,
    ButtonComponent,
    NgTemplateOutlet,
    NgOptimizedImage,
    SpeedDialModule,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  /** Menu items */
  allMenuItems: IAtlasMenuItem[] = [];
  menuItems: IAtlasMenuItem[] = [];
  adminActionItems: MenuItem[] = [];

  @Input() isMenuVisible: boolean = true;
  @Output() menuVisibilityChange = new EventEmitter<boolean>();

  /** Finite props */
  hasOstalo: boolean = false;
  ostaloSectionValue: MenuSection = MenuSection.OSTALO;

  /** Props */
  userName: string;
  userImage: string;
  userRoles: string;

  constructor(
    public userService: UserService,
    private _locationService: LocationService,
  ) {
    this.userName = this.userService.getUserData.fullName;
    this.userImage = this.userService.getUserData.imageUrl;
    const roles = userService.getFriendlyRoles.split(',');
    if (roles.length > 1) {
      this.userRoles = roles[0].concat(
        ' (+',
        (roles.length - 1).toString(),
        ')',
      );
    } else {
      this.userRoles = roles[0];
    }
    this._setMenuItems();
    this.adminActionItems = [
      {
        icon: 'pi pi-plus',
        tooltip: 'Dodaj asistenciju',
        tooltipPosition: 'right',
        command: () => this.handleCreateAssitance(),
      },
      {
        icon: 'pi pi-plus',
        title: 'Dodaj klijenta',
        command: () => this.handleCreateClient(),
      },
    ];
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
    this.menuVisibilityChange.emit(this.isMenuVisible);
  }

  private _setMenuItems() {
    this.allMenuItems = [
      ...this.adminMenuItems,
      ...this.sosAgentMenuItems,
      ...this.financeAdminMenuItems,
      ...this.ccLeaderMenuItems,
      ...this.ccAgentMenuItems,
      ...this.guestMenuItems,
    ];
    this.menuItems = this.allMenuItems.filter((x) => x.isVisible);
    this.hasOstalo = this.menuItems.some(
      (x) => x.section === MenuSection.OSTALO,
    );
  }

  handleLogOut() {
    this._locationService.routeToSettings();
    // this._authService.logout();
  }

  handleCreateAssitance() {
    this._locationService.routeToCreateAssitanceForm();
  }

  protected readonly ButtonStyle = ButtonStyle;

  private adminMenuItems: IAtlasMenuItem[] = [
    {
      label: 'Zaposleni',
      routerLink: '/admin/employees',
      icon: 'employees-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Saradnici',
      routerLink: '/admin/partners',
      icon: 'partners-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Paketi',
      routerLink: '/admin/packages',
      icon: 'packages-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Promo kodovi',
      routerLink: '/admin/promo-codes',
      icon: 'promo-code-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Parking',
      routerLink: '/admin/parking',
      icon: 'parkings-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Vozila',
      routerLink: '/admin/vehicles',
      icon: 'partners-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Izveštaji',
      routerLink: '/admin/reports',
      icon: 'settings-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
  ];

  private sosAgentMenuItems: IAtlasMenuItem[] = [
    {
      label: 'Ugovori',
      routerLink: '/sos/contracts',
      icon: 'contracts-icon',
      isVisible: this.userService.isSosAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Asistencije',
      routerLink: '/sos/assistances',
      icon: 'assistances-icon',
      isVisible: this.userService.isSosAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Saradnici',
      routerLink: '/sos/partners',
      icon: 'partners-icon',
      isVisible: this.userService.isSosAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    // {
    //   label: 'Podešavanja',
    //   routerLink: '/sos/settings',
    //   icon: 'settings-icon',
    //   isVisible: this.userService.isSosAgent(),
    //   section: MenuSection.OSTALO,
    // },
  ];

  private financeAdminMenuItems: IAtlasMenuItem[] = [
    {
      label: 'Narudžbenice',
      routerLink: '/finance/purchases',
      icon: 'purchase-icon',
      isVisible: this.userService.isFinanceAdmin(),
      section: MenuSection.NAVIGACIJA,
    },
  ];

  private ccLeaderMenuItems: IAtlasMenuItem[] = [
    {
      label: 'Klijenti',
      routerLink: '/cc/clients',
      icon: 'clients-icon',
      isVisible: this.userService.isCcLeader(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Kampanje',
      routerLink: '/cc/campaigns',
      icon: 'campaigns-icon',
      isVisible: this.userService.isCcLeader(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Preporuke',
      routerLink: '/cc/recommendations',
      icon: 'recommendations-icon',
      isVisible: this.userService.isCcLeader(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Agenti',
      routerLink: '/cc/agents',
      icon: 'agents-icon',
      isVisible: this.userService.isCcLeader(),
      section: MenuSection.NAVIGACIJA,
    },
  ];

  private ccAgentMenuItems: IAtlasMenuItem[] = [
    {
      label: 'Klijenti',
      routerLink: '/cc/clients',
      icon: 'clients-icon',
      isVisible: this.userService.isCcAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Kampanje',
      routerLink: '/cc/campaigns',
      icon: 'campaigns-icon',
      isVisible: this.userService.isCcAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Preporuke',
      routerLink: '/cc/recommendations',
      icon: 'recommendations-icon',
      isVisible: this.userService.isCcAgent(),
      section: MenuSection.NAVIGACIJA,
    },
  ];

  private guestMenuItems: IAtlasMenuItem[] = [
    {
      label: 'Ugovori',
      routerLink: '/guest/contracts',
      icon: 'contracts-icon',
      isVisible: this.userService.isGuest(),
      section: MenuSection.NAVIGACIJA,
    },
  ];
  protected readonly MenuSection = MenuSection;

  handleCreateClient() {
    this._locationService.routeToCreateClientForm().then();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
}

interface IAtlasMenuItem {
  label: string;
  routerLink: string;
  icon: string;
  isVisible: boolean;
  section: MenuSection;
}
