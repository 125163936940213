import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Guid } from 'guid-typescript';
import {
  IRegisterClientPersonCommand,
  PhoneItem,
} from '@shared/model/atlas.api';
import { ControlsOf } from '@shared/forms/forms-common';
import { strictEmailValidator } from '@app/features/partners/services/emailValidator';

@Injectable({
  providedIn: 'root',
})
export class RegistrationFormService {
  private form: FormGroup<ControlsOf<IRegisterClientPersonCommand>> | null;

  constructor(private _fb: FormBuilder) {
    this.form = null;
  }

  getForm(): FormGroup<ControlsOf<IRegisterClientPersonCommand>> {
    if (!this.form) {
      this.init();
    }
    return this.form!;
  }

  private init() {
    this.form = this._fb.group<ControlsOf<IRegisterClientPersonCommand>>({
      email: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.email,
          Validators.required,
          strictEmailValidator(),
        ],
      }),
      firstName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      lastName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(6)],
      }),
      confirmedPassword: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          confirmPasswordValidator,
          Validators.minLength(6),
        ],
      }),
      verificationCode: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  initDriverContacts() {
    return this._fb.group({
      phones: this._fb.array<FormGroup<ControlsOf<PhoneItem>>>([]),
    });
  }

  getPhoneForm(model?: PhoneItem): FormGroup<ControlsOf<PhoneItem>> {
    return this._fb.group<ControlsOf<PhoneItem>>({
      label: this._fb.control(model?.label ?? '', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(64)],
      }),
      id: this._fb.control(model?.id ?? Guid.create().toString(), {
        nonNullable: true,
      }),
      init: undefined,
      toJSON: undefined,
    });
  }

  getFormValidationErrors() {
    Object.keys(this.form!.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.form!.get(key)!.errors!;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError],
          );
        });
      }
    });
  }
}

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return control.parent == null
    ? null
    : control.parent!.value.password === control.value
      ? null
      : { PasswordNoMatch: true };
};
