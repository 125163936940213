import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectItem} from "primeng/api";
import {firstValueFrom, Subscription} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {
  CampaignsClient,
  IContractCampaignDropdownResponse,
} from "@shared/model/atlas.api";
import {DropdownComponent} from "@ui/dropdown/dropdown.component";

@Component({
  selector: 'atlas-contract-campaign-dropdown',
  standalone: true,
  imports: [
    DropdownComponent
  ],
  templateUrl: './contract-campaign-dropdown.component.html',
  styleUrl: './contract-campaign-dropdown.component.scss'
})
export class ContractCampaignDropdownComponent implements OnInit, OnDestroy {
  searchOptions: SelectItem[] = [];
  selectedCampaignId: FormControl<SelectItem> = new FormControl<SelectItem>(undefined);

  private _subs: Subscription;
  @Input() campaignIdCtrl: FormControl<string>;
  @Input() hideErrors: boolean = false;
  @Input() ctrlStyleClass: string = 'w-full';

  campaigns: IContractCampaignDropdownResponse[] | undefined;
  clientId: string | undefined = undefined;

  @Output() public onTypeChanged = new EventEmitter<string>();

  constructor(private _client: CampaignsClient) {
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._load();
  }

  private _load() {
    this._getCampaigns().then((res) => {
      this.campaigns = [...res.result];

      this.searchOptions = this.campaigns!.map((v) => ({
        label: v.name,
        value: v.id,
      }));

      this.searchOptions.unshift({
        label: 'Sve kampanje',
        value: null,
      })
    });
  }

  private async _getCampaigns() {
    return await firstValueFrom(this._client.getContractCampaignDropdown());
  }

  handleChange($event: any) {
    this.onTypeChanged.emit($event.value);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
