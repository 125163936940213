<atlas-scrollable-page
  pageTitle="Dodavanje kampanje"
  submitBtnLabel="Dodaj kampanju"
  (onCancelAction)="handleCancelForm()"
  (onSubmitAction)="handleSubmitForm()"
>
  <ng-template #content>
    <div class="header">
      Detalji
      <div class="header-desc font-normal mt-1">Vrsta i detalji kampanje</div>
    </div>
    <p-divider></p-divider>
    <div class="mt-5">
      <!--Tipovi kampanje-->
      <atlas-inline-wrapper
        formCtrlId="vehicle-types"
        ctrlLabel="Vrsta kampanje">
        <div class="flex gap-3 info-page-width">
          <div class="w-full">
            <atlas-icon-checkbox
              name="Kampanja leadova"
              imageUrl="assets/images/leads.svg"
              [isSelected]="selectedOption === 'Leads'"
              (handleSelect)="selectedOption = 'Leads'"
            ></atlas-icon-checkbox>
          </div>
          <div class="w-full">

            <atlas-icon-checkbox
              name="Kampanja iz ugovora"
              [isSelected]="selectedOption === 'Contracts'"
              imageUrl="assets/images/contracts.svg"
              (handleSelect)="selectedOption = 'Contracts'"
            ></atlas-icon-checkbox>
          </div>
          <div class="w-full">
            <atlas-icon-checkbox
              name="Samoobnavljajuća kampanja"
              [isSelected]="selectedOption === 'SelfRenewing'"
              imageUrl="assets/images/circuit-svgrepo-com.svg"
              (handleSelect)="selectedOption = 'SelfRenewing'"
            ></atlas-icon-checkbox>
          </div>
        </div>
      </atlas-inline-wrapper>
    </div>
    <p-divider></p-divider>
    <atlas-add-campaign-contract
      *ngIf="selectedOption === 'Contracts'"
    ></atlas-add-campaign-contract>
    <atlas-add-campaign-leads
      *ngIf="selectedOption === 'Leads'"
    ></atlas-add-campaign-leads>
    <atlas-add-campaign-self-renewing
      *ngIf="selectedOption === 'SelfRenewing'"
    ></atlas-add-campaign-self-renewing>
  </ng-template>
</atlas-scrollable-page>
