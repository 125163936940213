import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReportsClient } from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-report-years-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './report-years-dropdown.component.html',
  styleUrl: './report-years-dropdown.component.scss'
})
export class ReportYearsDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  yearsOptions: number[] = [];

  @Input() yearCtrl: FormControl;

  constructor(
    private _client: ReportsClient
  ) { }

  ngOnInit(): void {
    this._subs.add(
      this._client.getOrderYears().subscribe((result) => {
        this.yearsOptions = result.result;
      })
    )
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
