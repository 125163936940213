import { DatePipe, NgClass, NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, OnInit, Pipe } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { ClientForAssistanceAutocompleteComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/client-for-assistance-autocomplete/client-for-assistance-autocomplete.component';
import { PageComponent } from '@shared/components/page/page.component';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableComponent } from '@ui/table/table.component';
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { CampaignCardComponent } from '../campaign-card/campaign-card.component';
import { DropdownModule } from 'primeng/dropdown';
import { LocationService } from '@shared/services/location.service';
import {
  CampaignsClient,
  CampaignStatus,
  CampaignType,
  CreateSelfRenewingCampaignCommand,
  ICampaignResponse,
} from '@shared/model/atlas.api';
import { firstValueFrom, Subscription } from 'rxjs';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { InputButtonComponent } from '@shared/components/input-button/input-button.component';
import { SelfRenewingForm } from '@app/cc-leader/services/self-renewing-campaign-form.service';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'atlas-campaign-table',
  standalone: true,
  imports: [
    PageComponent,
    TabViewModule,
    TableComponent,
    InputTextModule,
    ReactiveFormsModule,
    InputSearchComponent,
    ClientForAssistanceAutocompleteComponent,
    DropdownComponent,
    InlineWrapperComponent,
    InputTextComponent,
    FormsModule,
    TableComponent,
    ButtonComponent,
    ContextMenuModule,
    PageComponent,
    TabViewModule,
    ConfirmDialogModule,
    InputTextModule,
    InputTextComponent,
    BadgeModule,
    NgIf,
    NgClass,
    NgFor,
    NgForOf,
    DataViewModule,
    DatePipe,
    CampaignCardComponent,
    DropdownModule,
    DropdownInputButtonComponent,
    InputButtonComponent,
    SkeletonModule,
  ],
  templateUrl: './campaign-table.component.html',
  styleUrl: './campaign-table.component.scss',
})
export class CampaignTableComponent implements OnInit {
  leads = [];
  contracts: any[] = [];
  selfRenewing: any[] = [];
  private _subs: Subscription = new Subscription();
  activeTabIndex: number = 0;
  campaignTable = new FormControl('');
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;
  countContracts: number;
  countSelfRenewing: number;
  countLeads: number;
  selectedRows: number;
  isLoading: boolean = true;
  skeletonItems = Array.from({ length: 9 }).map((_, i) => i);
  rowsOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
  ];

  constructor(
    private _locationService: LocationService,
    private _campaign: CampaignsClient,
  ) {
    this.countContracts = 0;
    this.countLeads = 0;
    this.countSelfRenewing = 0;
    this.selectedRows = 5;
  }

  ngOnInit(): void {
    this.loadLeads().then(() => {
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
      this.loadContracts().then(() => {
        this.loadSelfRenewing().then();
      });
    });
  }

  addCampaign() {
    this._locationService.routeToCreateCampaign().then();
  }

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
    // if (this.activeTabIndex === 0) {
    //   this.loadLeads().then();
    // } else if (this.activeTabIndex === 1) {
    //   this.loadContracts().then();
    // } else {
    //   this.loadSelfRenewing().then();
    // }
  }

  getCampaignStatus(status: number): string {
    switch (status) {
      case CampaignStatus.IN_PROGRESS:
        return 'U toku';
      case CampaignStatus.DONE:
        return 'Završena';
      case CampaignStatus.CANCELLED:
        return 'Prekinuta';
      default:
        return 'Nepoznato';
    }
  }

  async loadContracts() {
    await this._fetchContracts().then((res) => {
      this.contracts = res.result.data.map((item: ICampaignResponse) => ({
        id: item.id,
        title: item.title,
        user: item.createdBy.name,
        status: this.getCampaignStatus(item.status),
        description: item.description || 'Bez opisa',
        date: item.createdOn,
        dealTime: item.endsOn,
        totalUsers: item.contractCount,
        agents: [...item.sellers, ...item.tempSellers],
      }));
      this.countContracts = this.contracts.length;
    });
  }

  async loadLeads() {
    await this._fetchLeads().then((res) => {
      this.leads = res.result.data.map((item: ICampaignResponse) => ({
        id: item.id,
        title: item.title,
        user: item.createdBy.name,
        status: this.getCampaignStatus(item.status),
        description: item.description || 'Bez opisa',
        date: item.endsOn,
        flagColor: this.getFlagColor(item.priority),
        info: item.type,
        totalUsers: item.leadsCount,
        agents: [...item.sellers, ...item.tempSellers],
      }));
      this.countLeads = this.leads.length;
    });
  }

  async loadSelfRenewing() {
    await this._fetchSelfRenewing().then((res) => {
      this.selfRenewing = res.result.data.map((item: ICampaignResponse) => ({
        id: item.id,
        title: item.title,
        user: item.createdBy.name,
        status: this.getCampaignStatus(item.status),
        description: item.description || 'Bez opisa',
        date: item.endsOn,
        flagColor: this.getFlagColor(item.priority),
        info: item.type,
        totalUsers: item.contractCount,
        agents: [...item.sellers, ...item.tempSellers],
        selfRenewingCondition: item.selfRenewingCondition,
      }));
      this.countSelfRenewing = this.selfRenewing.length;
    });
  }

  private getFlagColor(priority: number): string {
    switch (priority) {
      case 4:
        return '#F04438';
      case 3:
        return '#EB9229';
      case 2:
        return '#1E7EC3';
      case 1:
        return '#1EC360';
      case 0:
        return '#999999';
      default:
        return '#999999';
    }
  }

  private async _fetchLeads() {
    return await firstValueFrom(
      this._campaign.getCampaigns(CampaignType.LEADS),
    );
  }

  private async _fetchContracts() {
    return await firstValueFrom(
      this._campaign.getCampaigns(CampaignType.CONTRACTS),
    );
  }

  private async _fetchSelfRenewing() {
    return await firstValueFrom(
      this._campaign.getCampaigns(CampaignType.SELF_RENEWING),
    );
  }
}
