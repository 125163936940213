<atlas-page
 pageTitle="Kampanje">
<!-- Header -->
<div class="header-stats flex justify-content-between gap-4">
    <!-- Zakazani pozivi -->
    <div class="stats-card-container">
        <div class="card-header flex justify-content-between mb-3">
            <div class="font-semibold text-lg">Zakazani pozivi</div>
            <div class="flex header-btn" (click)="handleMoreDetails()">
                <div class="font-semibold text-sm">Vidi detalje</div> 
                <div class="pi pi-chevron-right text-xs align-self-center ml-2"></div>
            </div>
        </div>
        <div class="card-content flex justify-content-between gap-2">
            <div class="flex gap-3">
                <div class="m-1 p-2 border-round-lg flex justify-content-center align-items-center card-icon">
                    <i [ngClass]="'pi text-3xl m-2 icon pi-phone'"></i>
                </div>
                <div class="align-self-center font-semibold text-lg">16 poziva</div>
            </div>
            <div class="align-self-center">
                <div class="font-medium text-sm content-txt">Sledeci poziv</div>
                <div class="flex">
                    <div class="content-txt-dark text-base font-semibold">Danas </div>
                    <div class="dot ml-2 mr-2 mt-1">•</div>
                    <div class="content-txt-dark text-base font-semibold"> 14:24 </div>
                    <div class="text-base font-semibold content-txt-red ml-2">(Za 10 min.)</div>
                </div>
            </div>
        </div>
    </div>

    <!-- Preporuke -->
    <div class="stats-card-container">
        <div class="card-header flex justify-content-between mb-3">
            <div class="font-semibold text-lg">Preporuke</div>
            <div class="flex header-btn">
                <div class="font-semibold text-sm">Vidi detalje</div> 
                <div class="pi pi-chevron-right text-xs align-self-center ml-2"></div>
            </div>
        </div>
        <div class="card-content flex justify-content-between gap-2">
            <div class="flex gap-3">
                <div class="m-1 p-2 border-round-lg flex justify-content-center align-items-center card-icon">
                    <i [ngClass]="'pi text-3xl m-2 icon pi-users'"></i>
                </div>
                <div class="align-self-center font-semibold text-lg">315 preporuka</div>
            </div>
            <div class="align-self-center">
                <div class="font-medium text-sm content-txt">Poslednja</div>
                <div class="flex">
                    <div class="content-txt-dark text-base font-semibold">Danas </div>
                    <div class="dot ml-2 mr-2 mt-1">•</div>
                    <div class="content-txt-dark text-base font-semibold"> 14:24 </div>
                    <div class="text-base font-semibold content-txt-red ml-2">
                        <atlas-agent-list-images></atlas-agent-list-images>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="flex justify-content-between mb-3 mt-3">
    <div class="w-full mt-3">
        <div class="text-xl font-semibold mb-2">Leadovi</div>
        <div class="text-sm font-normal content-txt">Sve kampanje leadova</div>
    </div>

    <div class="flex justify-content-end w-full gap-3 mt-3 mb-3">
        <div>
      <div class="search-bar gap-3">
        <atlas-input-button
          [searchCtrl]="campaignTable"
          (onAction)="loadLeads()">
        </atlas-input-button>
      </div>
    </div>
    <div class="filters-button">
      <atlas-button
        btnIcon="pi pi-sliders-h"
        class="filters-button"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
        btnLabel="Filteri"
      ></atlas-button>
    </div>
    <div class="flex align-items-center gap-2">
      <p-dropdown
        [options]="rowsOptions"
        [(ngModel)]="selectedRows"
        placeholder="Select rows"
        class="dropdown-number"
      ></p-dropdown>
    </div>
    </div>
</div>

<div class="table-container">
   <p-dataView [value]="leads" paginator="true" [rows]="selectedRows">
      <ng-template let-items pTemplate="list">
        <div *ngFor="let item of items" class="col-12 p-md-4">
          <atlas-campaign-card
            [campaignId]="item.id"
            [flagColor]="item.flagColor"
            [title]="item.title"
            [showProgressBar]="true"
            [showFlagIcon]="true"
            [showFooterUserIcon]="true"
            [status]="item.status"
            [description]="item.description"
            [date]="item.date | date"
            [user]="item.user"
            [info]="item.info"
            [totalLeads]="item.totalUsers"
            [agents]="item.agents"
          ></atlas-campaign-card>
        </div>
      </ng-template>
    </p-dataView>
</div>

</atlas-page>

