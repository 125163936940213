import { NgClass, NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import {IMediumItem, ISourceItem, MediumItem, MediumsClient, SourcesClient} from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { FormControl, FormsModule } from '@angular/forms';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { ReccomendationFormService } from '@app/cc-leader/services/reccomendation-form.service';
import {MediumsDropdownComponent} from "@shared/components/mediums-dropdown/mediums-dropdown.component";
import {SourcesDropdownComponent} from "@shared/components/sources-dropdown/sources-dropdown.component";

@Component({
  selector: 'atlas-create-order-form-step-1',
  standalone: true,
  imports: [
    NgIf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    IconCheckboxComponent,
    FormsModule,
    MediumsDropdownComponent,
    SourcesDropdownComponent,
  ],
  templateUrl: './create-order-form-step-1.component.html',
  styleUrl: './create-order-form-step-1.component.scss',
})
export class CreateOrderFormStep1Component implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  mediumItems: IMediumItem[] = [];
  sourceItems: ISourceItem[] = [];
  selectedOption: 'Fizicko' | 'Pravno' = 'Fizicko';

  orderForm: OrderForm;

  constructor(
    private _mediumsClient: MediumsClient,
    private _sourcesClient: SourcesClient,
    private _orderFormService: OrderFormService,
    private _reccomendService: ReccomendationFormService
  ) {
    this.orderForm = _orderFormService.getOrderForm;
  }

  ngOnInit(): void {
    this._getMediums();
    this._getSources();

    const reccData = this._reccomendService.getReccData();
    if (reccData) {
      this.orderForm.controls.client.controls.name.patchValue(reccData.name);
      this.orderForm.controls.client.controls.lastName.patchValue(reccData.lastName);
      this.orderForm.controls.client.controls.phone.patchValue(reccData.phoneNum);
    }
  }

  private _getMediums() {
    this._subs.add(
      this._mediumsClient.getMediums().subscribe((res) => {
        this.mediumItems = res.result.responseList;
      }),
    );
  }

  private _getSources() {
    this._subs.add(
        this._sourcesClient.getSources().subscribe((res) => {
          this.sourceItems = res.result.responseList;
        }),
    );
  }

  selectFizicko() {
    this.selectedOption = 'Fizicko';
  }

  selectPravno() {
    this.selectedOption = 'Pravno';
  }

  handleMediumChange(event: any) {
    this.orderForm.value.client.mediumId = event.value.id;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  handleSourceChange($event: any) {
    this.orderForm.value.client.mediumId = $event.value.id;
  }
}
