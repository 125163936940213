import {
  CommonModule,
  NgClass,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '@ui/checkbox/checkbox.component';
import { CheckboxModule } from 'primeng/checkbox';
import { HoverClassDirective } from '@shared/directives/hover-class.directive';

@Component({
  selector: 'atlas-icon-checkbox',
  standalone: true,
  imports: [
    CheckboxModule,
    CheckboxComponent,
    FormsModule,
    NgClass,
    NgTemplateOutlet,
    NgOptimizedImage,
    CommonModule,
    HoverClassDirective,
  ],
  templateUrl: './icon-checkbox.component.html',
  styleUrl: './icon-checkbox.component.scss',
})
export class IconCheckboxComponent {
  @Input() name: string = '';
  @Input() isSelected: boolean = false;
  @Output() handleSelect: EventEmitter<void> = new EventEmitter<void>();
  @Input() iconClass: string = '';
  @Input() imageUrl?: string;

  onSelect() {
    this.handleSelect.emit();
  }
}
