import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  EmployeeRoleItem,
  IEditEmployeeCommand,
  IEditParkingCityCommand,
  IEditParkingZoneCommand,
  IEmployeeRequestItem,
  IEmployeeRoleItem,
  IGetEmployeeByIdQueryResponse,
  IParkingCityItem,
  IParkingCityResponseItem,
  IParkingZoneItem,
  ZoneColor,
  ZoneType,
} from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';
import { strictEmailValidator } from '@app/features/partners/services/emailValidator';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private _inviteForm: InvatationForm;
  private _editForm: EditEmployeeForm;
  private _editCityForm: EditCityForm;
  private _editZoneForm: EditZoneForm;
  private readonly _addForm: AddCityForm;
  private readonly _addZone: AddZoneForm;
  private cityIdSource = new BehaviorSubject<string | null>(null);
  cityId$ = this.cityIdSource.asObservable();
  private zoneIdSource = new BehaviorSubject<string | null>(null);
  zoneId$ = this.zoneIdSource.asObservable();
  // private packageIdSource = new BehaviorSubject<any>(null);
  // currentPackageId = this.packageIdSource.asObservable();

  constructor(private _fb: FormBuilder) {
    this._inviteForm = this._initInviteForm();
    this._addForm = this._addCityForm();
    this._addZone = this._addZoneForm();
  }

  getInviteEmployeeForm() {
    if (!this._inviteForm) {
      this._inviteForm = this._initInviteForm();
    }
    return this._inviteForm;
  }

  getAddCityForm() {
    if (!this._addForm) {
      this._addCityForm();
    }
    return this._addForm;
  }

  setCityId(cityId: string) {
    this.cityIdSource.next(cityId);
  }

  setZoneId(zoneId: string) {
    this.zoneIdSource.next(zoneId);
  }

  selectedRoles$ = new BehaviorSubject<EmployeeRoleItem[]>([]);

  // getSelectedRoles() {
  //   return this.selectedRoles$.asObservable();
  // }

  setSelectedRoles(roles: EmployeeRoleItem[]) {
    this.selectedRoles$.next(roles);
  }

  getAddZoneForm() {
    if (!this._addZone) {
      this._addZoneForm();
    }
    return this._addZone;
  }

  private _addCityForm() {
    return this._fb.group<ControlsOf<IParkingCityItem>>({
      cityName: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  private _addZoneForm() {
    return this._fb.group<ControlsOf<IParkingZoneItem>>({
      name: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(100)],
      }),
      phoneNumber: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      color: this._fb.control<ZoneColor>(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      type: this._fb.control<ZoneType>(ZoneType.PO_SATU, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  private _initInviteForm() {
    return this._fb.group<ControlsOf<IEmployeeRequestItem>>({
      email: this._fb.control('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.email,
          strictEmailValidator(),
        ],
      }),
      roleIds: this._fb.array([], Validators.required),
    });
  }

  getEditEmployeeForm(model: IGetEmployeeByIdQueryResponse) {
    if (!this._editForm) {
      this._editForm = this._initEditForm(model);
    }
    return this._editForm;
  }

  getEditCityForm(model: IParkingCityResponseItem) {
    if (!this._editCityForm) {
      this._editCityForm = this._initEditCityForm(model);
    }
    return this._editCityForm;
  }

  getEditZoneForm(model: IEditParkingZoneCommand) {
    if (!this._editZoneForm) {
      this._editZoneForm = this._initEditZoneForm(model);
    }
    return this._editZoneForm;
  }

  private _getEmployeeRoleItemForm(model: IEmployeeRoleItem) {
    return this._fb.group<ControlsOf<IEmployeeRoleItem>>({
      id: this._fb.control(model.id, {
        nonNullable: true,
      }),
      roleName: this._fb.control(model.roleName, {
        nonNullable: true,
      }),
    });
  }

  private _initEditForm(model: IGetEmployeeByIdQueryResponse) {
    return this._fb.group<ControlsOf<IEditEmployeeCommand>>({
      employeeId: this._fb.control(model.id, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      roleIds: this._fb.array(model.roleItems, {
        validators: [Validators.required],
      }) as unknown as FormArray<FormGroup<ControlsOf<EmployeeRoleItem>>>,
      isActive: this._fb.control(model.isActive, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  private _initEditCityForm(model: IParkingCityResponseItem) {
    return this._fb.group<ControlsOf<IEditParkingCityCommand>>({
      cityId: this._fb.control(model.id, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      cityName: this._fb.control(model.name, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  private _initEditZoneForm(model: IEditParkingZoneCommand) {
    return this._fb.group<ControlsOf<IEditParkingZoneCommand>>({
      zoneId: this._fb.control(model.zoneId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      name: this._fb.control(model.name, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: this._fb.control(model.description, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      phoneNumber: this._fb.control(model.phoneNumber, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      color: this._fb.control<ZoneColor>(model.color, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      type: this._fb.control<ZoneType>(model.type, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  reset() {
    this._inviteForm = undefined;
    this._editForm = undefined;
    this._editCityForm = undefined;
    this._editZoneForm = undefined;
    this._addCityForm = undefined;
    this._addZoneForm = undefined;
  }
}

export declare type InvatationForm = FormGroup<
  ControlsOf<IEmployeeRequestItem>
>;
export declare type EditEmployeeForm = FormGroup<
  ControlsOf<IEditEmployeeCommand>
>;
export declare type AddCityForm = FormGroup<ControlsOf<IParkingCityItem>>;
export declare type AddZoneForm = FormGroup<ControlsOf<IParkingZoneItem>>;
export declare type EditCityForm = FormGroup<
  ControlsOf<IEditParkingCityCommand>
>;
export declare type EditZoneForm = FormGroup<
  ControlsOf<IEditParkingZoneCommand>
>;
