import { Component } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { ReportsCardComponent } from '../reports-card/reports-card.component';

@Component({
  selector: 'atlas-reports-page',
  standalone: true,
  imports: [
    PageComponent,
    ReportsCardComponent
  ],
  templateUrl: './reports-page.component.html',
  styleUrl: './reports-page.component.scss'
})
export class ReportsPageComponent {

  reports: any[] = []

  constructor() {
    this.initReports()
  }

  private initReports() {
    this.reports = [
      {
        name: 'Ukupni izveštaj',
        icon: 'pi pi-chart-bar',
        desc: 'Ukupni izveštaj svih prodaja paketa do današnjeg datuma',
        route: 'overall-report'
      },
      {
        name: 'Mesečni izveštaj',
        icon: 'pi pi-chart-line',
        desc: 'Ukupni izveštaj svih prodaja paketa po mesecima',
        route: 'monthly-report'
      }
    ]
  }


}
