<atlas-scrollable-page
  pageTitle="Obnova paketa"
  submitBtnLabel="Obnovi paket"
  submitBtnIcon="pi pi-sync"
  (onCancelAction)="handleCancelForm()"
  (onSubmitAction)="handleSubmitForm()"
>
  <ng-template #content>
       <div class="flex justify-content-between">
          <!-- Package Card -->
          <div class="package-card">
            <atlas-package-card-list [isRenew]="true"></atlas-package-card-list>
          </div>

          <!-- Package Price / Desna strana -->
          <div class="summary-box">
            <div class="package-text mb-3">Obnova</div>

            @for (
              package of packages?.value;
              let idx = $index;
              track package.Id
            ) {
              <div class="item mt-3">
                <span class="sub-text"
                  >{{ idx + 1 + "." }} {{ package.packageName }}</span
                >
                <span class="sub-texte">{{
                  package.packagePrice | currencyNumber
                }}</span>
              </div>
            }

            <div class="total mt-3">
              <span class="mt-3 package-text">Ukupno</span>
              <span class="mt-3 package-text">{{
                packagesSum ?? 0 | currencyNumber
              }}</span>
            </div>
          </div>
        </div>

        <p-divider></p-divider>

        <div class="mt-5 ml-1">

          <div class="mb-4">
            <p-selectButton
              [options]="stateOptions"
              [(ngModel)]="stateValue"
              optionLabel="label"
              optionValue="value"
              [allowEmpty]="false"
              (onChange)="handleStateChange($event)"
            />
          </div>

          <div class="mb-4">
            <div class="mb-5 font-semibold text-base">Način isporuke</div>
            <atlas-dropdown
              [ctrlLabel]="'Način isporuke'"
              [options]="deliveryType"
              [formCtrl]="renewForm.controls.deliveryType"
              ctrlStyleClass="w-17rem"
            ></atlas-dropdown>
          </div>

          <div class="mt-4 mb-4">
            <div class="mb-5 font-semibold text-base">Način plaćanja</div>
            <atlas-dropdown
              [ctrlLabel]="'Način plaćanja'"
              [options]="paymentType"
              [formCtrl]="renewForm.controls.paymentType"
              ctrlStyleClass="w-17rem"
            ></atlas-dropdown>
          </div>

          <!-- Medium -->
          <div class="mt-4 mb-4 dropdown-container">
            <div class="mb-2 font-semibold text-base">Medij</div>
            <atlas-mediums-dropdown
              [mediumCtrl]="renewForm.controls.mediumId"
              [ctrlStyleClass]="'w-17rem'"
            ></atlas-mediums-dropdown>
          </div>

          <!-- Source -->
          <div class="mt-4 mb-4 w-17.5rem dropdown-container">
            <div class="mb-2 font-semibold text-base">Izvor</div>
            <atlas-sources-dropdown
              [sourceCtrl]="renewForm.controls.sourceId"
              [ctrlStyleClass]="'w-17rem'"
            ></atlas-sources-dropdown>
          </div>
        </div>

        <p-divider></p-divider>

        <div class="mt-4 mb-4 title-text">
          Od kada želite da Vam se aktivira paket?
        </div>

        <div class="mb-3" *ngFor="let type of activationType">
          <p-radioButton
            [formControl]="renewForm.controls.isUrgent | formControl"
            [value]="type.value"
          ></p-radioButton>
          <label class="ml-3" [for]="type.label">{{ type.label }}</label>
        </div>
  </ng-template>
</atlas-scrollable-page>
