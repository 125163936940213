import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AutoComplete, AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'atlas-input-search',
  standalone: true,
  imports: [
    AutoCompleteModule,
    FormsModule,
    NgIf,
    NgTemplateOutlet,
  ],
  templateUrl: './input-search.component.html',
  styleUrl: './input-search.component.scss',
})
export class InputSearchComponent implements OnChanges {
  // model: any;
  @Input() suggestions: any[] = [];
  @ViewChild('autocomplete') autocomplete: AutoComplete;
  @Input() fieldName: string = '';
  @Input() ctrlPlaceholder: string = '';
  @Input() formCtrlClass: string = '';
  @Input() isDisabled: boolean = false;
  @Input() shouldFocus: boolean = false;
  @Input() shouldReset: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() multiple: boolean = false;
  @Input() forceSelection: boolean = true;

  @Output() onSearch = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() enterClicked = new EventEmitter();
  @Output() model: any;

  @ContentChild('item') item!: TemplateRef<ElementRef>;
  @ContentChild('selectedItem') selectedItem!: TemplateRef<ElementRef>;

  handleSearch($event: any) {
    this.onSearch.emit($event.query);
  }

  onSelectChange($event: any) {
    this.onChange.emit($event);
  }

  handleEnterClick(event: any) {
    if (event.keyCode === 13) {
      this.enterClicked?.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['shouldReset']?.currentValue !=
      changes['shouldReset']?.previousValue
    ) {
      this.model = undefined;
    }
  }
}
