import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActionMode,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@ui/table/table-common';
import { firstValueFrom, Subscription } from 'rxjs';
import { SharedModule } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table/table.interface';
import {
  ClientClient,
  GetClientPackagesTableQuery,
  GetClientPackagesTableStatsQuery,
  GetClientPackageTableExportDataQuery,
  IClientPackagesTableResponse,
  IPaginationResponseOfClientPackagesTableResponse,
  PaymentStatus,
  PaymentType,
} from '@shared/model/atlas.api';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { AlertService } from '@shared/services/alert.service';
import { DialogService } from '@ui/dialog/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { PageComponent } from '@shared/components/page/page.component';
import { TabViewModule } from 'primeng/tabview';
import { TableComponent } from '@ui/table/table.component';
import {
  ChangePaymentStatusFormComponent,
  PaymentStatusForm,
} from '@app/finance-administrator/order-purchase-table/change-payment-status-form/change-payment-status-form.component';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { OrderTableFilterComponent } from '@app/finance-administrator/order-purchase-table/order-table-filter/order-table-filter.component';
import { OrderTableService } from '@app/finance-administrator/services/order-table.service';
import { ActiveFiltersComponent } from '@app/finance-administrator/order-purchase-table/active-filters/active-filters.component';
import { OrderTableFilterService } from '@app/finance-administrator/services/order-table-filter.service';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { LoaderService } from '@shared/components/loader/loader.service';
import { TableServiceLocal } from '@ui/table/table-service-local.service';

@Component({
  selector: 'atlas-order-purchase-table',
  standalone: true,
  imports: [
    BadgeModule,
    PageComponent,
    SharedModule,
    TabViewModule,
    TableComponent,
    CurrencyNumberPipe,
    PageInfoCardComponent,
    ButtonComponent,
    OrderTableFilterComponent,
    ActiveFiltersComponent,
    DropdownInputButtonComponent,
  ],
  templateUrl: './order-purchase-table.component.html',
  styleUrl: './order-purchase-table.component.scss',
})
export class OrderPurchaseTableComponent
  implements OnInit, OnDestroy, OnTableInit
{
  private _subs: Subscription;

  totalNonPaid: number | undefined;
  tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfClientPackagesTableResponse;
  activeIndex: number | undefined;
  resetPagination: boolean | undefined;

  packageSum: number = 0;
  exportData: IClientPackagesTableResponse[] = [];

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  constructor(
    private client: ClientClient,
    private _dialogService: DialogService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    public service: OrderTableService,
    public filterService: OrderTableFilterService,
    private _loaderService: LoaderService,
    private _tableService: TableServiceLocal,
  ) {
    this.totalNonPaid = 0;
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }

  ngOnInit() {
    this.countNonPaid();

    this._subs.add(
      this.alertService.requestCountChanged$.subscribe(() => {
        this.countNonPaid();
      }),
    );

    this._subs.add(
      this.filterService.getReloadTable.subscribe((reloadTable) => {
        if (reloadTable) {
          this.load();
        }
      }),
    );

    this.setTableConfig().then();

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { tab: 'sve' },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this.activeIndex = 0;
        this.load();
      });
  }

  countNonPaid() {
    this._subs.add(
      this.client.getAllUnpaid().subscribe((res) => {
        this.totalNonPaid = res.result;
      }),
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez narudžbenica',
        description: 'Ovde će biti prikazana tabela narudžbenica',
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      selectMode: SelectMode.MULTI,
      columns: [
        {
          header: 'Status uplate',
          field: 'paymentStatusBadge',
          type: 'badge',
          badgeClass: 'payment-status-',
          styleClass: 'w-8rem',
          isFrozen: true,
        },
        {
          header: 'Broj narudžbenice',
          field: 'number',
          type: 'text',
          styleClass: 'w-6rem',
          isFrozen: true,
        },
        {
          header: 'Klijent',
          field: 'client',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold w-10rem',
          columns: [
            {
              header: '',
              field: 'clientPhone',
              type: 'text',
            },
          ],
        },
        // {
        //   header: 'Adresa',
        //   field: 'clientAddress',
        //   type: 'text',
        // },
        {
          header: 'Paket',
          field: 'package',
          type: 'text',
          styleClass: 'w-10rem',
        },
        {
          header: 'Vozilo',
          field: 'vehicle',
          type: 'text',
          styleClass: 'w-10rem',
          columns: [
            {
              header: '',
              field: 'licencePlate',
              type: 'text',
            },
          ],
        },
        {
          header: 'Cena',
          field: 'price',
          type: 'currency',
          columns: [
            {
              header: '',
              field: 'oldPrice',
              type: 'currency',
              styleClass: 'line-through',
            },
          ],
        },
        {
          header: 'Tip uplate',
          field: 'paymentTypeBadge',
          type: 'badge',
          badgeClass: 'payment-type-',
          styleClass: 'w-7rem',
        },
        {
          header: 'Datum kupovine',
          field: 'createdOn',
          type: 'text',
        },
        {
          header: 'Datum aktivacije',
          field: 'activatedOn',
          type: 'text',
        },
        {
          header: 'Napomena',
          field: 'note',
          type: 'text',
          styleClass: 'w-10rem',
          tooltipField: 'note',
        },
        {
          header: 'Kupon',
          field: 'promoCode',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Promeni status uplate',
          shouldDisplayByCondition: (rowIdx: number) => {
            return true;
            // const rowData: IWebPurchasesTableResponse =
            //   this._getPaginatedItem(rowIdx);
            // return rowData.paymentStatus !== PaymentStatus.WC_COMPLETED;
          },
          icon: 'pi pi-sync',
          callback: (rowIdx: number) => {
            const rowData: IClientPackagesTableResponse =
              this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Promena statusa uplate';
            this.dialogConfig.headerDescription =
              'Odaberite novi status uplate';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi',
              icon: 'pi pi-check',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.data = {
              clientPackageId: rowData.id,
              paymentStatus: rowData.paymentStatusBadge.value,
              paymentStatusStr: rowData.paymentStatusBadge.label,
            };
            this.dialogConfig.closable = true;

            this.openDialog(rowIdx);
          },
        },
      ],
    });
  }

  async handleCSVExport() {
    this._loaderService.setLoadingText = 'Preuzimanje podataka...';
    this._loaderService.setShowLoader = true;
    this._loadExportData().then(() => {
      this._loaderService.reset();
      const customData = this.exportData.map((item) => {
        return {
          statusUplate: item.paymentStatusBadge.label,
          brojNarudzbenice: item.number,
          klijent: item.client,
          kontaktKlijenta: item.clientPhone,
          paketi: item.package,
          vozilp: item.vehicle + ' (' + item.licencePlate + ')',
          cena: item.price,
          tipUplate: item.paymentTypeBadge.label,
          datumKupovine: item.createdOn,
          datumAktivacije: item.activatedOn,
          napomena: item.note,
          kupon: item.promoCode,
        };
      });

      const csvContent = this.convertToCSV(customData);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = 'CustomData.csv';
      link.click();
    });
  }

  convertToCSV(data: any[]): string {
    const headers = Object.keys(data[0]).join(',');
    const rows = data
      .map((item) => {
        const values = Object.values(item).map((value) => {
          if (Array.isArray(value)) {
            return value.join('\\');
          } else {
            return value;
          }
        });
        return values.join(',');
      })
      .join('\n');
    return `${headers}\n${rows}`;
  }

  private _loadExportData(): Promise<void> {
    return new Promise((resolve) => {
      this._subs.add(
        this.client
          .getDataForExport(
            new GetClientPackageTableExportDataQuery({
              filter: this.filterService.getOrderTableFilter(),
            }),
          )
          .subscribe((res) => {
            this.exportData = res.result || [];
            resolve();
          }),
      );
    });
  }

  openDialog(rowIdx: number): void {
    const ref = this._dialogService.open(
      ChangePaymentStatusFormComponent,
      this.dialogConfig,
    );
    this._subs.add(
      ref.onClose.subscribe((statusRes: PaymentStatusForm) => {
        if (statusRes) {
          this.alertService.notifyRequestCountChanged();
          this.load();
        }
      }),
    );
  }

  handleTabChange(idx: any) {
    let selectedTab: string;
    switch (idx) {
      case 1:
        this.filterService.clearPaymentStatus();
        this.filterService.clearPaymentType();
        this.filterService.addPaymentType(PaymentType.CORVUS_PAY);
        selectedTab = 'karticne';
        break;
      case 2:
        this.filterService.clearPaymentStatus();
        this.filterService.clearPaymentType();
        this.filterService.addPaymentType(PaymentType.PAYMENT_SLIP);
        selectedTab = 'uplatnice';
        break;
      case 3:
        this.filterService.clearPaymentStatus();
        this.filterService.clearPaymentType();
        this.filterService.addPaymentStatus(PaymentStatus.EMPTY);
        this.filterService.addPaymentStatus(PaymentStatus.WC_ON_HOLD);
        this.filterService.addPaymentStatus(PaymentStatus.WC_PENDING);
        this.filterService.addPaymentStatus(PaymentStatus.WC_PROCESSING);
        selectedTab = 'neplacene';
        break;
      default:
        this.filterService.clearPaymentStatus();
        this.filterService.clearPaymentType();
        this.filterService.addPaymentStatus(PaymentStatus.EMPTY);
        this.filterService.addPaymentType(PaymentType.EMPTY);
        selectedTab = 'sve';
    }

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { tab: selectedTab },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this.resetPagination = !this.resetPagination;
        this.paginatedData = { currentPage: 0, pageSize: 10 };
        this.load();
      });
  }

  load() {
    this._tableService.setIsLoading();
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
    this._getStats().then((res) => {
      this.packageSum = res.result.sum;
    });
  }

  private async _getList() {
    return await firstValueFrom(
      this.client.getClientPackagesTable(
        new GetClientPackagesTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          filter: this.filterService.getOrderTableFilter(),
        }),
      ),
    );
  }

  private async _getStats() {
    return await firstValueFrom(
      this.client.getClientPackagesTableStats(
        new GetClientPackagesTableStatsQuery({
          filter: this.filterService.getOrderTableFilter(),
        }),
      ),
    );
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;
}
