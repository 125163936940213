import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Dropdown, DropdownModule} from 'primeng/dropdown';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {firstValueFrom, Subscription} from 'rxjs';
import {IVehicleBrandItem, VehicleBrandItem, VehiclesClient,} from '@shared/model/atlas.api';
import {NewVehicleService} from '@app/features/services/new-vehicle-service';
import {JsonPipe, NgIf} from '@angular/common';
import {ErrorRequiredComponent} from '@ui/errors/error-required/error-required.component';
import {DropdownComponent} from '@ui/dropdown/dropdown.component';

@Component({
  selector: 'atlas-brand-dropdown',
  standalone: true,
  imports: [
    DropdownModule,
    ReactiveFormsModule,
    JsonPipe,
    ErrorRequiredComponent,
    DropdownComponent,
    NgIf,
  ],
  templateUrl: './brand-dropdown.component.html',
  styleUrl: './brand-dropdown.component.scss',
})
export class BrandDropdownComponent implements OnInit, OnChanges, OnDestroy {
  private _subs: Subscription;
  brands: IVehicleBrandItem[] = [];
  brandNames: string[] = [];
  shouldResetInput: boolean = false;
  selectedBrandCtrl: FormControl<string> = new FormControl('', {
    validators: [Validators.required],
  });

  @Input() type: string | undefined = undefined;
  @Input() isDisabled: boolean = true;
  @Input() brandNameCtrl: FormControl<string>;
  @Input() validateControl: boolean;

  @ViewChild('dropdownElement') dropdownElement: Dropdown;
  @ViewChild('dropdownElement') dropdownElement2: ElementRef;

  @Output() public onBrandChanged = new EventEmitter<IVehicleBrandItem>();

  constructor(
    private _client: VehiclesClient,
    public newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._subs.add(
      this.newVehicleService.shouldResetBrandInput$.subscribe((shouldReset) => {
        if (shouldReset) {
          this.selectedBrandCtrl.reset();
        }
      }),
    );

    this._subs.add(
      this.selectedBrandCtrl.valueChanges.subscribe((value: string) => {
        if (!value) {
          //this.brandNameCtrl.patchValue(value as string);
          return;
        } else if (value === this.brandNameCtrl.value) {
          return;
        }

        this.brandNameCtrl.patchValue(value as string);
        this.newVehicleService.setBrandNameCtrl(value as string);
        const brandId = this.brands.find((x) => x.name === value);

        this.onBrandChanged.emit(
          new VehicleBrandItem({
            name: value as string,
            brandId: brandId?.brandId,
          }),
        );
      }),
    );
    this.newVehicleService.setBrandNameRequired();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['type']?.currentValue !== changes['type']?.previousValue &&
      changes['type']?.currentValue
    ) {
      this.brands = [];
      this.brandNames = [];
      this.selectedBrandCtrl.reset();
      this._load();
    }
    if (
      changes['brandNameCtrl']?.currentValue?.value !==
      changes['brandNameCtrl']?.previousValue?.value
    ) {
      this.newVehicleService.setBrandNameCtrl(this.brandNameCtrl.value);
      this.selectedBrandCtrl.patchValue(
        changes['brandNameCtrl'].currentValue.value,
      );
    }
    if (
      changes['validateControl']?.currentValue !=
      changes['validateControl']?.previousValue
    ) {
      this.selectedBrandCtrl.markAsTouched();
    }

    if (
      changes['isDisabled']?.currentValue !==
      changes['isDisabled']?.previousValue
    ) {
      if (changes['isDisabled'].currentValue) {
        this.selectedBrandCtrl.disable();
      } else {
        this.selectedBrandCtrl.enable();
      }
    }
  }

  private _load() {
    this._getBrands().then((res) => {
      this.brands = [...res.result.responseList];
      this.brandNames = [...this.brands.map((x) => x.name)];
    });
  }

  private async _getBrands() {
    return await firstValueFrom(this._client.getVehicleBrands(this.type));
  }

  ngOnDestroy() {
    this.newVehicleService.resetCtrl();
    this.shouldResetInput = true;
    this.newVehicleService.shouldResetBrandInput$.next(false);
    this._subs.unsubscribe();
  }
}
