import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/services/auth.guard';
import { AgentTableComponent } from '@app/cc-leader/agents/agent-table/agent-table.component';
import { RecommendationTableComponent } from '@app/cc-leader/recommendations/recommendation-table/recommendation-table.component';
import { CampaignTableComponent } from '@app/cc-leader/campaigns/campaign-table/campaign-table.component';
import { RenewPackageFormComponent } from '@app/features/orders/renew-package-form/renew-package-form.component';
import { AddCampaignComponent } from './campaigns/add-campaign/add-campaign.component';
import { ContractDetailsComponent } from './campaigns/contract-details/campaign-details/contract-details.component';
import { AgentDetailPageComponent } from './agents/agent-detail-page/agent-detail-page.component';
import { ClientWithPackagesPageComponent } from '@app/features/orders/client-with-packages-page/client-with-packages-page.component';
import { CreateOrderFormComponent } from '@app/features/orders/create-order-form/create-order-form.component';
import { CreateOrderFormStep1Component } from '@app/features/orders/create-order-form/create-order-form-step-1/create-order-form-step-1.component';
import { CreateOrderFormStep2Component } from '@app/features/orders/create-order-form/create-order-form-step-2/create-order-form-step-2.component';
import { CreateOrderFormStep3Component } from '@app/features/orders/create-order-form/create-order-form-step-3/create-order-form-step-3.component';
import { ViewLeadsComponent } from './campaigns/edit-leads/view-leads/view-leads.component';
import { BuyPackageVehicleComponent } from '@app/features/orders/buy-package-vehicle/buy-package-vehicle.component';

export const ccLeaderRoutes: Routes = [
  {
    path: '',
    data: {
      isLeader: true,
    },
    children: [
      {
        path: '',
        redirectTo: 'clients',
        pathMatch: 'full',
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            component: ClientWithPackagesPageComponent,
          },
          {
            path: 'create',
            canActivate: [AuthGuard],
            component: CreateOrderFormComponent,
            children: [
              {
                path: 'step-1',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep1Component,
              },
              {
                path: 'step-2',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep2Component,
              },
              {
                path: 'step-3',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep3Component,
              },
            ],
          },
          {
            path: 'renew-package',
            canActivate: [AuthGuard],
            component: RenewPackageFormComponent,
          },
          {
            path: 'renew-package/:id',
            canActivate: [AuthGuard],
            component: RenewPackageFormComponent,
          },
          {
            path: 'buy-package-vehicle/:id',
            canActivate: [AuthGuard],
           component: BuyPackageVehicleComponent,
                    },
        ],
      },
      {
        path: 'campaigns',
        children: [
          {
            path: '',
            component: CampaignTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create-campaign',
            component: AddCampaignComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'details-campaign/:id',
            component: ContractDetailsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'view-leads/:id',
            canActivate: [AuthGuard],
            component: ViewLeadsComponent,
          },
        ],
      },
      {
        path: 'recommendations',
        canActivate: [AuthGuard],
        component: RecommendationTableComponent,
        pathMatch: 'full',
      },
      {
        path: 'agents',
        children: [
          {
            path: '',
            component: AgentTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'agent-details/:id',
            component: AgentDetailPageComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'clients',
  },
];
