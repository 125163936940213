import { NgIf } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import {
  AssignPackagesToSellerCommand,
  AssignPackagesToSellersBatchCommand,
  AssignPackagesToTempSellerCommand,
  OrdersClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { Subscription } from 'rxjs';
import { ClientWithPackageTableFilterService } from '@app/features/orders/services/client-with-package-table-filter.service';
import { LoaderService } from '@shared/components/loader/loader.service';

@Component({
  selector: 'atlas-toast-message',
  standalone: true,
  imports: [NgIf],
  templateUrl: './toast-message.component.html',
  styleUrl: './toast-message.component.scss',
})
export class ToastMessageComponent implements OnInit, OnDestroy, OnChanges {
  @Input() agentName: string;
  @Input() packageIdsLength: number;
  @Input() agentIds: string[];
  @Input() packageIds: string[];
  @Input() isTempSellerAssigned: boolean;
  @Input() originalPackageSellers: Map<string, string[]> = new Map();
  @Input() originalPackageTempSellers: Map<string, string[]> = new Map();
  groupedPackages: any[] = [];
  showToast: boolean;

  private _subs: Subscription = new Subscription();

  constructor(
    private _alertService: AlertService,
    private _loader: LoaderService,
    private _client: OrdersClient,
    private _service: ClientWithPackageTableFilterService,
  ) {
    this._subs = new Subscription();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes', changes);
    if (
      changes['agentName'].currentValue != changes['agentName'].previousValue
    ) {
      this.agentName = changes['agentName'].currentValue;
      if (this.isTempSellerAssigned) {
        this.groupPackagesByClientIdTemp();
      } else {
        this.groupPackagesByClientId();
      }
    }
  }

  ngOnInit(): void {}

  private groupPackagesByClientId(): void {
    const clientPackagesMap: { [key: string]: string[] } = {};

    this.originalPackageSellers.forEach((packages, agentId) => {
      this.showToast = true;
      if (clientPackagesMap[agentId]) {
        clientPackagesMap[agentId].push(...packages);
      } else {
        clientPackagesMap[agentId] = [...packages];
      }
    });

    this.groupedPackages = Object.entries(clientPackagesMap).map(
      ([agentId, packageIds]) => ({
        agentId: agentId,
        packageIds: packageIds,
      }),
    );
  }

  private groupPackagesByClientIdTemp(): void {
    const clientPackagesMap: { [key: string]: string[] } = {};
    this.originalPackageTempSellers.forEach((packages, agentId) => {
      this.showToast = true;
      if (clientPackagesMap[agentId]) {
        clientPackagesMap[agentId].push(...packages);
      } else {
        clientPackagesMap[agentId] = [...packages];
      }
    });

    this.groupedPackages = Object.entries(clientPackagesMap).map(
      ([agentId, packageIds]) => ({
        agentId: agentId,
        packageIds: packageIds,
      }),
    );
  }

  closeToast() {
    this.agentName = '';
    this.showToast = false;
  }

  undoAction() {
    this.undoSellers();
  }

  private undoSellers() {
    this.closeToast();

    this._loader.setLoadingText = 'Poništavanje izmena ...';
    this._loader.setShowLoader = true;

    const commands: AssignPackagesToSellerCommand[] = this.groupedPackages.map(
      (group) => {
        const command = new AssignPackagesToSellerCommand();
        command.clientPackageIds = group.packageIds;
        command.toSellerId = group.agentId;
        return command;
      },
    );

    const batchCommand = new AssignPackagesToSellersBatchCommand({
      commands: commands,
      isTempSeller: this.isTempSellerAssigned,
    });

    this._subs.add(
      this._client
        .assignPackagesToSellersBatch(batchCommand)
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._loader.reset();
          this._service.reloadTable();
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
