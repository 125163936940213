<div *ngIf="form">
<div class="form-container h-13rem" >
  <div class="dropdown">
     <atlas-role-multiselect
       [rolesCtrl]="form.controls.roleIds"
     ></atlas-role-multiselect>
  </div>

  <div class="switch-isActive mt-6">
    <p class="font-bold mb-3">Status</p>
    <div class="mt-2 flex">
      <atlas-input-switch [formGroup]="form"></atlas-input-switch>
      <p class="m-1 ml-2">Aktivan</p>
    </div>
  </div>
</div>
</div>