<atlas-multiselect
    [formGroup]="formGroup"
    formCtrlName="roles"
    [options]="roleOptions"
    optionLabel="roleName"
    ctrlLabel="Role"
    ctrlDataKey="id"
    ctrlPlaceholder="Odaberi role"
    ctrlStyleClass="w-full"
    [showLabel]="showLabel"
    class="role-multiselect"
></atlas-multiselect>


