<!--
<ng-container [ngSwitch]="column.type">
  &lt;!&ndash; BADGE &ndash;&gt;
  <ng-container *ngSwitchCase="'badge'">
    <ng-container
      *ngIf="column.subField; then badgeIsObject; else badgeIsSimple"
    >
    </ng-container>
  </ng-container>

  &lt;!&ndash; LINK &ndash;&gt;
  <ng-container *ngSwitchCase="'link'">
    <ng-container *ngIf="column.subField; then linkIsObject; else linkIsSimple">
    </ng-container>
  </ng-container>

  &lt;!&ndash; NUMBER &ndash;&gt;
  <ng-container *ngSwitchCase="'number'">
    <ng-container
      *ngIf="column.subField; then numberIsObject; else numberIsSimple"
    >
    </ng-container>
  </ng-container>

  &lt;!&ndash; BOOLEAN &ndash;&gt;
  <ng-container *ngSwitchCase="'bool'">
    <ng-container *ngIf="column.subField; then boolIsObject; else boolIsSimple">
    </ng-container>
  </ng-container>

  &lt;!&ndash; TEXT &ndash;&gt;
  <ng-container *ngSwitchCase="'text'">
    <ng-container *ngIf="column.subField; then textIsObject; else textIsSimple">
    </ng-container>
  </ng-container>

  &lt;!&ndash; CURRENCY &ndash;&gt;
  <ng-container *ngSwitchCase="'currency'">
    <ng-container
      *ngIf="column.subField; then currencyIsObject; else currencyIsSimple"
    >
    </ng-container>
  </ng-container>

  &lt;!&ndash; LIST &ndash;&gt;
  <ng-container *ngSwitchCase="'list'">
    <ng-container *ngIf="column.subField; then listIsObject; else listIsSimple">
    </ng-container>
  </ng-container>

  &lt;!&ndash; INITIALS &ndash;&gt;
  <ng-container *ngSwitchCase="'initials'">
    <ng-container
      *ngIf="column.subField; then initialsIsObject; else initialsIsSimple"
    >
    </ng-container>
  </ng-container>

  &lt;!&ndash; ICON &ndash;&gt;
  <ng-container *ngSwitchCase="'icon'">
    <ng-container *ngTemplateOutlet="iconIsObject"></ng-container>
  </ng-container>

  &lt;!&ndash; TODO: Prebaciti sve templates u novi fajl i onda ga iz njega citati &ndash;&gt;
  &lt;!&ndash; TEMPLATES &ndash;&gt;

  <ng-template #badgeIsObject>
    <span
      [ngClass]="
        'item-badge ' +
        column.styleClass +
        '-' +
        item[column.field][column.styleClassField]
      "
    >{{
      item[column.field] === null ? '&#45;&#45;' : item[column.field][column.subField]
      }}</span
    >
  </ng-template>

  <ng-template #badgeIsSimple>
    <span
      *ngIf="item[column.field]"
      [ngClass]="
        'item-badge ' +
        column.styleClass +
        '-' +
        item[column.field].toLowerCase()
      "
    >
      {{ item[column.field] }}
    </span>

    <div
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span
        *ngIf="item[col2.field]"
        [ngClass]="
          'item-badge ' +
          column.styleClass +
          '-' +
          item[col2.field].toLowerCase()
        "
      >
        {{ item[col2.field] }}
      </span>
    </div>
  </ng-template>

  <ng-template #linkIsSimple>
    <div>
      <button
        (click)="column.linkCallbackFunction(rowIndex)"
        [label]="item[column.field]"
        class="p-text-left p-button-link p-p-0 text-underline"
        pButton
        style="text-align: initial"
        type="button"
      ></button>
    </div>
    <span
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span *ngIf="!col2.subField" class="p-pr-2 p-label"
      >{{ col2.header }}:
        <span class="p-text">{{ item[col2.field] }}</span>
      </span>
      <span
        *ngIf="
          col2.subField &&
          col2.type === 'link' &&
          item[col2.field] &&
          item[col2.field]?.length > 0
        "
      >
        &lt;!&ndash; {{ item[col2.field] | typeof }}
        {{ item[col2.field] | json }} &ndash;&gt;
        <ng-container [ngSwitch]="item[col2.field] | typeof">
          <ng-container *ngSwitchCase="objectIsArray">
            <div class="p-d-inline-flex">
              <span *ngIf="item[col2.field].length > 0"
              >{{ col2.header }}:
              </span>
              <div class="p-d-flex p-flex-column">
                <div
                  *ngFor="
                    let el of item[col2.field] | slice: 0:9;
                    let i = index
                  "
                >
                  <button
                    (click)="col2.linkCallbackFunction(rowIndex, i)"
                    *ngIf="el[col2.subField]"
                    [label]="el[col2.subField]"
                    class="p-button-link p-p-0 text-underline"
                    pButton
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="objectIsString">
            u pitanju je string
          </ng-container>
        </ng-container>
      </span>
      <span *ngIf="col2.subField && col2.type !== 'link'">
        {{ col2.header }}:
        <span class="p-text">{{ item[col2.field][col2.subField] }}</span>
      </span>

      <span *ngIf="column.columns.length != i + 1" class="p-pr-2">|</span>
    </span>
  </ng-template>

  <ng-template #linkIsObject>
    <div>
      <button
        (click)="column.linkCallbackFunction(rowIndex)"
        *ngIf="item[column.field] && item[column.field][column.subField]"
        [label]="item[column.field][column.subField]"
        class="p-text-left p-button-link p-p-0 text-underline"
        pButton
        type="button"
      ></button>
      <span
        *ngIf="item[column.field] && item[column.field][column.emptyCellField]"
      >{{ item[column.field][column.emptyCellField] }}</span
      >
    </div>
    <div>
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        <span
          *ngIf="item[col2.field] && item[col2.field][col2.subField]"
          [ngClass]="
            'p-text-nowrap p-text-truncate ' +
            col2.styleClass +
            '-' +
            item[col2.field][col2.styleClassField]
          "
          class="p-text-nowrap p-text-truncate"
        >
          {{ item[col2.field][col2.subField] }}
        </span>
        <span *ngIf="column.columns.length != i + 1" class="p-pr-2">|</span>
      </span>
    </div>
    <span *ngIf="!item[column.field]">&#45;&#45;</span>
  </ng-template>

  <ng-template #numberIsSimple>
    <div style="text-align: end">
      <span class="p-text-nowrap p-text-truncate">
        {{ item[column.field] }}
      </span>
    </div>

    <div style="text-align: end">
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        <span
          [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
          class="p-label"
        >{{ col2.header }}
          <span *ngIf="col2.header != ''">:</span>
          <span class="p-text">{{ item[col2.field] }}</span>
        </span>
        <span
          *ngIf="column.columns.length != i + 1"
          [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
        >|</span
        >
      </span>
    </div>
  </ng-template>

  <ng-template #numberIsObject>
    <span>#TODO: add numberIsObject template</span>
  </ng-template>

  <ng-template #textIsSimple>
    <div>
      <span
        *ngIf="item[column.field]"
        [ngClass]="
          'p-text-nowrap p-text-truncate ' +
          column.styleClass +
          '-' +
          item[column.field][column.styleClassField]
        "
      >
        {{ item[column.field] }}
      </span>
      <span *ngIf="!item[column.field]">&#45;&#45;</span>
    </div>

    &lt;!&ndash; <div>
    <span
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span
        *ngIf="col2.header"
        [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
        class="p-label"
        >{{ col2.header + ': ' }}
      </span>
      <span
        [ngClass]="
          col2.styleClass + '-' + item[col2.field][col2.styleClassField]
        "
        >{{ item[col2.field] }}</span
      >
      <span
        [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
        *ngIf="column.columns.length != i + 1"
        >|</span
      >
    </span>
  </div> &ndash;&gt;
    <div>
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        <span class="p-pr-2 p-label"
        >{{ col2.header }}:
          <span class="p-text">{{ item[col2.field] }}</span>
        </span>
        <span *ngIf="column.columns.length != i + 1" class="p-pr-2">|</span>
      </span>
    </div>
  </ng-template>

  <ng-template #textIsObject>
    <div>
      <span
        [ngClass]="
          'p-text-nowrap p-text-truncate ' +
          column.styleClass +
          '-' +
          item[column.field][column.styleClassField]
        "
      >
        {{ item[column.field][column.subField] }}
      </span>
    </div>
    <div>
      &lt;!&ndash; <span
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span *ngIf="col2.header" class="p-label p-pr-2"
        >{{ col2.header + ': ' }}
      </span>
      <span
        [ngClass]="
          col2.styleClass + '-' + item[col2.field][col2.styleClassField]
        "
        >{{ item[col2.field][col2.subField] }}</span
      >
      <span class="p-pr-2" *ngIf="column.columns.length != i + 1">|</span>
    </span> &ndash;&gt;
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        {{ item[col2.field][col2.subField] }}
      </span>
      <span *ngIf="column.columns.length != i + 1" class="p-pr-2">| </span>
    </div>
  </ng-template>

  <ng-template #currencyIsSimple>
    <div style="text-align: end">
      <kodit-helper-icon
        *ngIf="column.helperText"
        [helperText]="column.helperText"
      ></kodit-helper-icon>
      <span
        *ngIf="column.useColorsForCurrency"
        [currencyValue]="item[column.field]"
        kodit-currency-directive
      >{{
        item[column.field]
          | currencyNumber: item[column.currencyAlphaCharField]
        }}</span
      >
      <span *ngIf="!column.useColorsForCurrency">{{
        (item[column.field] ?? 0) | currencyNumber: item[column.currencyAlphaCharField]
        }}</span>
    </div>
    &lt;!&ndash; multi-row &ndash;&gt;
    <div *ngIf="column.columns" style="text-align: end">
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        <span
          *ngIf="col2.shouldDisplayByCondition(item[col2.field])"
          [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
        >{{ col2.header }}
          <span *ngIf="col2.header">:</span>
          <kodit-helper-icon
            *ngIf="col2.helperText"
            [helperText]="col2.helperText"
          ></kodit-helper-icon>
          <span
            *ngIf="col2.useColorsForCurrency"
            [currencyValue]="item[col2.field]"
            kodit-currency-directive
          >{{
            item[col2.field]
              | currencyNumber: item[column.currencyAlphaCharField]
            }}</span
          >
          <span *ngIf="!col2.useColorsForCurrency">{{
            item[col2.field] | currencyNumber: item[col2.currencyAlphaCharField]
            }}</span>
        </span>
        <span
          *ngIf="column.columns.length != i + 1"
          [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
        >|</span
        >
      </span>
    </div>
  </ng-template>

  <ng-template #currencyIsObject>
    <span>#TODO: add currencyIsObject template</span>
  </ng-template>

  <ng-template #listIsSimple>
    <div *ngFor="let el of item[column.field] | slice: 0:9" class="p-text">
      <div [pTooltip]="el.label" class="p-text-nowrap p-text-truncate">
        - {{ el.value }}
      </div>
    </div>
    <div *ngIf="item[column.field].length > 9">...</div>
  </ng-template>

  <ng-template #listIsObject>
    <div
      *ngFor="let el of item[column.field] | slice: 0:9; let i = index"
      class="p-text"
    >
      <button
        (click)="column.linkCallbackFunction(rowIndex, i)"
        *ngIf="column.linkCallbackFunction && el[column.subField]"
        [label]="el[column.subField]"
        class="p-text-left p-button-link p-p-0 text-underline"
        pButton
        type="button"
      ></button>
      <span *ngIf="el && column.emptyCellField && el[column.emptyCellField]">{{
        el[column.emptyCellField]
        }}</span>
    </div>
    <div *ngIf="item[column.field].length > 9">...</div>
    <div *ngIf="item[column.field].length == 0">&#45;&#45;</div>
  </ng-template>

  <ng-template #initialsIsSimple>
    <div class="profile-image row-initials" height="50" width="50">
      {{ item[column.field][0] }}
    </div>
  </ng-template>

  <ng-template #initialsIsObject>
    <span>ToDo: initialsIsObject</span>
  </ng-template>

  <ng-template #iconIsObject>
    <div role="menuitem">
      <span class="fa-stack k-fa-3x">
        <i
          [ngStyle]="{ color: item[column.field].backgroundColor }"
          class="_icon_back fas fa-square fa-stack-2x"
        ></i>
        <i
          [ngClass]="'_icon_front fa-stack-1x ' + item[column.field].iconName"
          [ngStyle]="{ color: item[column.field].iconColor }"
        ></i>
      </span>
    </div>
  </ng-template>

  <ng-template #boolIsSimple>
    <div>
      <span
        *ngIf="item[column.field]"
        [ngClass]="
          'p-text-nowrap p-text-truncate ' +
          column.styleClass +
          '-' +
          item[column.field][column?.styleClassField ?? -1]
        "
      >
        Da
      </span>
      <span *ngIf="!item[column.field]">Ne</span>
    </div>
  </ng-template>

  <ng-template #boolIsObject>
    <span>#TODO: add boolIsObject template</span>
  </ng-template>
</ng-container>
-->

<ng-container [ngSwitch]="column.type">
  <!-- TEXT -->
  <ng-container *ngSwitchCase="'text'">
    <ng-container *ngIf="column.subField; then textIsSimple; else textIsSimple">
    </ng-container>
  </ng-container>
  <!-- BADGE -->
  <ng-container *ngSwitchCase="'badge'">
    <ng-container
      *ngIf="column.subField; then badgeIsSimple; else badgeIsSimple"
    >
    </ng-container>
  </ng-container>
  <!-- NUMBER -->
  <ng-container *ngSwitchCase="'number'">
    <ng-container
      *ngIf="column.subField; then numberIsSimple; else numberIsSimple"
    >
    </ng-container>
  </ng-container>
  <!-- COMPLEX -->
  <ng-container *ngSwitchCase="'image'">
    <ng-container *ngIf="column.subField; then image; else image">
    </ng-container>
  </ng-container>
  <!-- CURRENCY -->

  <ng-container *ngSwitchCase="'currency'">
    <ng-container
      *ngIf="column.subField; then currencyIsSimple; else currencyIsSimple"
    >
    </ng-container>
  </ng-container>
</ng-container>

<!-- ************************************* text template ********************************************* -->

<!-- text START -->
<ng-template #textIsSimple>
  <div
    class="max-h-2rem overflow-hidden text-overflow-ellipsis"
    showDelay="500"
    hideDelay="300"
    [pTooltip]="item[column.tooltipField]"
  >
    <div
      *ngIf="item[column.field]"
      [ngClass]="
        'p-text-nowrap p-text-truncate ' +
        column.styleClass +
        ' ' +
        item[column.field][column.styleClassField!]
      "
    >
      {{ item[column.field] }}
    </div>
    <span *ngIf="!item[column.field]">--</span>
  </div>
  <div class="pt-1">
    <div
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
      [ngClass]="column.styleClass"
    >
      <div class="p-pr-2 p-label">
        <span *ngIf="col2.header"> {{ col2.header }}:</span>
        <span class="p-text">{{ item[col2.field] }}</span>
      </div>
      <span *ngIf="column.columns!.length != i + 1" class="p-pr-2">|</span>
    </div>
  </div>
</ng-template>
<!-- text END -->

<!-- ************************************* badge template ********************************************* -->

<!-- badge START -->
<ng-template #badgeIsSimple>
  <!--  <span-->
  <!--    class="item-badge flex gap-2 align-items-center justify-content-center"-->
  <!--    [ngStyle]="{-->
  <!--      color: item[column.field].textColor,-->
  <!--      background: item[column.field].backgroundColor-->
  <!--    }"-->
  <!--  >-->
  <!--    <span-->
  <!--      *ngIf="item[column.field].prefixColor"-->
  <!--      class="dot"-->
  <!--      [ngStyle]="{ background: item[column.field].prefixColor }"-->
  <!--    ></span>-->
  <!--    <span>{{ item[column.field].label }}</span>-->
  <!--  </span>-->
  <!--  <div class="text-center pt-1">-->
  <!--    <span-->
  <!--      *ngFor="let col2 of column.columns; let i = index"-->
  <!--      [attr.data-index]="i"-->
  <!--    >-->
  <!--      <span class="p-pr-2 p-label"-->
  <!--        ><span *ngIf="col2.header"> {{ col2.header }}:</span>-->
  <!--        <span class="p-text">{{ item[col2.field] }}</span>-->
  <!--      </span>-->
  <!--      <span *ngIf="column.columns!.length != i + 1" class="p-pr-2">|</span>-->
  <!--    </span>-->
  <!--  </div>-->
  <div
    [ngClass]="
      'table-badge ' +
      column.badgeClass +
      item[column.field].value +
      ' ' +
      column.styleClass
    "
  >
    <span class="dot"></span>
    <span>{{ item[column.field].label }}</span>
  </div>
</ng-template>
<!-- badge END -->

<!-- ************************************* number template ********************************************* -->
<!-- number START -->
<ng-template #numberIsSimple>
  <div style="text-align: end">
    <span class="p-text-nowrap p-text-truncate">
      {{ item[column.field] }}
    </span>
  </div>

  <div style="text-align: end">
    <span
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span
        [ngClass]="{ 'p-pr-2': column.columns!.length !== i + 1 }"
        class="p-label"
      >{{ col2.header }}
        <span *ngIf="col2.header !== ''">:</span>
        <span class="p-text">{{ item[col2.field] }}</span>
      </span>
      <span
        *ngIf="column.columns!.length !== i + 1"
        [ngClass]="{ 'p-pr-2': column.columns!.length !== i + 1 }"
      >|</span
      >
    </span>
  </div>
</ng-template>
<!-- number END -->

<!-- ************************************* complicated template ********************************************* -->
<!-- complicated START -->
<ng-template #image>
  <div>
    <span
      *ngIf="item[column.field]"
      [ngClass]="
        'p-text-nowrap p-text-truncate ' +
        column.styleClass +
        '-' +
        item[column.field][column.styleClassField!]
      "
    >
      <div class="flex gap-2 profile-image">
        <img
          [ngSrc]="
            item[column.imageField]
              ? item[column.imageField]
              : 'assets/images/client-icon.svg'
          "
          alt="field-image"
          width="32"
          height="32"
        />
        <div class="flex flex-column">
          <div>
            {{ item[column.field] }}
          </div>
          <div>
            {{ item[column.columns.at(0).field] }}
          </div>
        </div>
      </div>
    </span>
    <span *ngIf="!item[column.field]">--</span>
  </div>
</ng-template>
<!-- complicated END -->

<!-- ************************************* currency template ********************************************* -->
<!-- currency START -->
<ng-template #currencyIsSimple>
  <div style="text-align: end">
    <span
      [ngClass]="'p-text-nowrap p-text-truncate ' + item[column.styleClass]"
    >
      {{ item[column.field] | currencyNumber }}
    </span>
  </div>

  <div style="text-align: end">
    <span
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span
        [ngClass]="{ 'p-pr-2': column.columns!.length !== i + 1 }"
        class="p-label"
      >{{ col2.header }}
        <span *ngIf="col2.header !== ''">:</span>
        <span [ngClass]="'p-text ' + col2.styleClass">{{
            item[col2.field] | currencyNumber
          }}</span>
      </span>
      <span
        *ngIf="column.columns!.length !== i + 1"
        [ngClass]="{ 'p-pr-2': column.columns!.length !== i + 1 }"
      >|</span
      >
    </span>
  </div>
</ng-template>

<!-- currency END -->
