import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { Subscription } from 'rxjs';
import { AddDriverAutocompleteComponent } from "./add-driver-autocomplete/add-driver-autocomplete.component";
import { AssignPartnerDriverCommand, DriversClient } from '@shared/model/atlas.api';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { LocationService } from '@shared/services/location.service';

@Component({
  selector: 'atlas-add-driver',
  standalone: true,
  imports: [AddDriverAutocompleteComponent],
  templateUrl: './add-driver.component.html',
  styleUrl: './add-driver.component.scss'
})
export class AddDriverComponent implements OnInit, OnDestroy {
  selectedDriver: any;
  vehicleId: string;
  driverId: string;
  driverName: string;
  private _subs: Subscription = new Subscription();

  constructor(
      private _dialogRef: DialogRef,
      private _dialogConfig: DialogConfig,
      private _alertService: AlertService,
      private _client: DriversClient,
    ) {
      this.vehicleId = this._dialogConfig.data.id;
      this.driverId = this._dialogConfig.data.driverId;
      this.driverName = this._dialogConfig.data.driverName;
    }

    ngOnInit(): void {
      this._subs.add(
        this._dialogRef.onSubmit.subscribe(() => {
          this._handleSave();
        }),
      );
    }

    handleDriverChange(driver: any) {
      this.selectedDriver = driver; 
    }

    _handleSave(){
      if (!this.selectedDriver?.id) {
        this._alertService.addWarnMsg('Potrebno je uneti sva polja!');
        return; 
      }
      this._subs.add(
            this._client
              .assingPartnerDriverOnVehicle(
                new AssignPartnerDriverCommand({
                  vehicleId: this.vehicleId, 
                  driverId: this.selectedDriver?.id
                }),
              )
              .subscribe((res) => {
                this._alertService.addSuccessMsg(res.result.message);
                this.exit(true);
              }),
          );
    }

    exit(value: boolean) {
      this._dialogRef.close({
        shouldRefresh: value
      });;
    }
  
    ngOnDestroy(): void {
      this._subs.unsubscribe();
    }

}
