import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { IPartnerVehicleAssistanceItem } from '@shared/model/atlas.api';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { DriverDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-2/partner-preview-form/driver-dropdown/driver-dropdown.component';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';

@Component({
  selector: 'atlas-partner-preview-form',
  standalone: true,
  imports: [AvatarModule, ButtonComponent, DriverDropdownComponent],
  templateUrl: './partner-preview-form.component.html',
  styleUrl: './partner-preview-form.component.scss',
})
export class PartnerPreviewFormComponent implements OnChanges {
  @Input() previewPartnerId: number;
  previewPartner: IPartnerVehicleAssistanceItem;

  constructor(
    public service: AssistanceService,
    public formService: AssistanceFormService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
  if (
    changes['previewPartnerId'] &&
    changes['previewPartnerId'].currentValue !== changes['previewPartnerId'].previousValue
  ) {
    this.previewPartner = this.service.getPartners.at(this.previewPartnerId);

    this.formService.getForm.controls.driverId.reset();
    this.formService.getForm.controls.driverPhone.reset();
  }
}

  handleSelect() {
    if (this.formService.getForm.controls.driverId.invalid) {
      this.formService.getForm.controls.driverId.markAsTouched();
      return;
    }
    this.service.setSelectedPartner = this.previewPartner;
  }

  protected readonly ButtonStyle = ButtonStyle;
}
