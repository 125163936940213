<atlas-dropdown
  [options]="searchOptions"
  [formCtrl]="campaignIdCtrl"
  [ctrlStyleClass]="ctrlStyleClass"
  ctrlPlaceholder="Izaberi kampanju"
  (optionChanged)="handleChange($event)"
  [showLabel]="false"
  [hideErrors]="hideErrors"
>
</atlas-dropdown>
