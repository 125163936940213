import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import {
  ClientClient,
  DriversClient,
  EmployeesClient,
  PhoneItem,
  RegisterClientPersonCommand,
  RegisterDriverCommand,
  RegisterEmployeeCommand,
} from '@shared/model/atlas.api';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocationService } from '@shared/services/location.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { WhiteLogoComponent } from '@ui/white-logo/white-logo.component';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'atlas-validation-code',
  standalone: true,
  templateUrl: './validation-code.component.html',
  styleUrl: './validation-code.component.scss',
  providers: [ClientClient, HttpClient],
  imports: [
    InputTextComponent,
    ButtonComponent,
    FormControlPipe,
    WhiteLogoComponent,
  ],
})
export class ValidationCodeComponent implements OnInit, OnDestroy {
  employeeCreate: boolean;

  constructor(
    private router: Router,
    private _locationService: LocationService,
    private _loaderService: LoaderService,
    private _client: ClientClient,
    private _driverClient: DriversClient,
    private _employeeClient: EmployeesClient,
    private _alertService: AlertService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state['roleIds'] !== undefined) {
      this.employeeCreate = true;
    }

    const state = navigation!.extras.state as {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      roleIds: string[];
      isDriver: boolean;
      partnerId: string;
      driverContacts: any;
      driverPhone: string;
    };
    this.firstName = state.firstName;
    this.lastName = state.lastName;
    this.email = state.email;
    this.password = state.password;
    this.roleIds = state.roleIds;
    this.isDriver = state.isDriver;
    this.driverContacts = state.driverContacts;
  }

  protected readonly ButtonStyle = ButtonStyle;
  private _subs: Subscription = new Subscription();

  firstName: string = '';
  lastName: string = '';
  email: string = '';
  password: string = '';
  roleIds: string[] = [];
  isDriver: boolean = false;
  driverContacts: any;

  form = new FormGroup({
    verificationCode: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  ngOnInit() {
    console.log('RoleId u validation comp.' + this.roleIds);
  }

  handleRegister() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this._loaderService.setLoadingText = 'Provera tokena...';
    this._loaderService.setShowLoader = true;
    if (this.employeeCreate) {
      this.handleEmployeeRegister();
    }
    else if (this.isDriver) {
      this.handleDriverRegister();
    } else {
      this.handleClientRegister();
    }
  }

  handleEmployeeRegister() {
    this._subs.add(
      this._employeeClient
        .registerEmployee(
          new RegisterEmployeeCommand({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            confirmedPassword: this.password,
            verificationCode: this.form.value.verificationCode!,
            roleIds: this.roleIds,
          }),
        )
        .pipe(
          catchError((err) => {
            this._alertService.addFailedMsg(err.exception);
            throw err;
          }),
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe({
          next: () => {
            this._locationService.routeToLogin('');
          },
        }),
    );
  }

  handleDriverRegister() {
    let phoneItems: PhoneItem[] = this.driverContacts.map((x: any) =>
      PhoneItem.fromJS(x),
    );

    this._subs.add(
      this._driverClient
        .resgisterDriver(
          new RegisterDriverCommand({
            email: this.email,
            phones: phoneItems,
            password: this.password,
            confirmedPassword: this.password,
          }),
        )
        .pipe(
          catchError((err) => {
            this._alertService.addFailedMsg(err.exception);
            throw err;
          }),
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe({
          next: (res) => {
            this._locationService.routeToLogin('');
          },
        }),
    );
  }

  handleClientRegister() {
    this._subs.add(
      this._client
        .registerClientPerson(
          new RegisterClientPersonCommand({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            confirmedPassword: this.password,
            verificationCode: this.form.value.verificationCode!,
          }),
        )
        .pipe(
          catchError((err) => {
            this._alertService.addFailedMsgs(err.exception);
            throw err;
          }),
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe({
          next: (res) => {
            this._locationService.routeToLogin('');
          },
        }),
    );
  }

  handleBackToLogin() {
    this._locationService.routeToLogin();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
