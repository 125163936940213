import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AssistanceType,
  IAssistanceClient,
  IAssistanceVehicle,
  ICreateAssistanceCommand,
  ILocationItem,
  IPhoneItem,
} from '@shared/model/atlas.api';
import { ControlsOf, guidValidator } from '@shared/forms/forms-common';
import { AssistanceVehilceForm } from '@app/sos-agent/assistances/assistance-form/services/assistance-vehilce-form.service';
import { AssistanceClientForm } from '@app/sos-agent/assistances/assistance-form/services/assistance-client-form.service';
import { BehaviorSubject } from 'rxjs';
import { ContactFormService } from '@app/features/partners/services/contact-form.service';

@Injectable({
  providedIn: 'root',
})
export class AssistanceFormService {
  private status: number | null = null;

  constructor(
    private _fb: FormBuilder,
    private _clientForm: AssistanceClientForm,
    private _vehicleForm: AssistanceVehilceForm,
    private _phonesService: ContactFormService,
  ) {}

  private _form: AssistanceForm | undefined;

  get getForm(): AssistanceForm {
    if (!this._form) {
      this.setForm();
    }
    return this._form;
  }

  selectedServiceId$ = new BehaviorSubject<string>(null);

  setSelecedServiceId(serviceId: string) {
    this.selectedServiceId$.next(serviceId);
  }

  getSelectedServiceId() {
    return this.selectedServiceId$.asObservable();
  }

  private _makeServiceRequired() {
    this._form.controls.serviceId.setValidators([Validators.required]);
    this._form.controls.serviceId.updateValueAndValidity();
  }

  private _makeServiceNonRequired() {
    this._form.controls.serviceId.clearValidators();
    this._form.controls.serviceId.updateValueAndValidity();
  }

  updateSelectedService(
    serviceId: string = null,
    selectedPackageName: string = null,
  ): void {
    this._form.controls.serviceId.patchValue(serviceId);
    this.updateServiceValidators(selectedPackageName);
  }

  updateServiceValidators(selectedPackageName: string | null): void {
    if (
      (!selectedPackageName && this._form.value.type === AssistanceType.CASH) ||
      (selectedPackageName &&
        (selectedPackageName.toLowerCase().includes('guma') ||
          selectedPackageName.toLowerCase().includes('moj')))
    ) {
      this._makeServiceNonRequired();
    } else {
      this._makeServiceRequired();
    }
  }

  setForm(model?: ICreateAssistanceCommand) {
    if (!this._form) {
      this._form = this._fb.group<ControlsOf<ICreateAssistanceCommand>>({
        client: this._clientForm.getForm(model?.client) as FormGroup,
        vehicle: this._vehicleForm.getForm(model?.vehicle) as FormGroup,
        from: this._getLocationForm(model?.from) as FormGroup,
        to: this._getLocationForm(model?.to) as FormGroup,
        distanceMeters: this._fb.control(model?.distanceMeters ?? 0, {}),
        durationSeconds: this._fb.control(model?.durationSeconds ?? 0, {}),
        durationText: this._fb.control(model?.durationText ?? '0km (0min)', {}),
        numOfPassengers: this._fb.control(model?.numOfPassengers, {
          validators: [Validators.required, Validators.min(1)],
        }),
        failureType: this._fb.control(model?.failureType, {
          nonNullable: true,
          validators: [Validators.required, guidValidator()],
        }),
        failureDescription: this._fb.control(model?.failureDescription, {
          nonNullable: true,
          validators: [Validators.maxLength(128), Validators.required],
        }),
        failureNote: this._fb.control(model?.failureNote, {
          validators: [Validators.maxLength(128)],
        }),
        clientPackageId: this._fb.control(model?.clientPackageId, {
          nonNullable: true,
        }),
        type: this._fb.control(model?.type ?? AssistanceType.CASH),
        serviceId: this._fb.control(model?.serviceId, {
          nonNullable: true,
        }),
        partnerVehicleId: this._fb.control(model?.partnerVehicleId, {
          nonNullable: true,
          validators: [Validators.required, guidValidator()],
        }),
        driverPhone: this._fb.control(model?.driverPhone, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        driverId: this._fb.control(model?.driverId, {
          validators: [Validators.required, guidValidator()],
        }),
        id: this._fb.control(model?.id, {}),
      });
    } else {
      this._form.patchValue(model);
      this.setClient(model?.client);
      this.setVehicle(model?.vehicle);
    }
  }

  get getClientForm(): ClientForm {
    return this._form.controls.client as FormGroup;
  }

  get getClientIdCtrl() {
    if (!this._form) {
      return null;
    }
    return this._form.controls.client.controls.clientId;
  }

  get getClientName(): string | null {
    if (!this._form) {
      return null;
    }
    return this._form.value.client.clientName;
  }

  get getClientPhones(): string | null {
    if (!this._form) {
      return null;
    }
    return this._form.value.client.phones.map((p) => p.label).join(', ');
  }

  setClient(model?: IAssistanceClient) {
    if (!this._form) {
      return;
    }

    this._form.controls.client.patchValue({
      clientId: model?.clientId,
      clientName: model?.clientName,
      email: model?.email,
      destinationAddress: model?.destinationAddress,
    });
    const phones = model?.phones;
    const phonesFormArray = this._phonesService.getPhonesArray(phones);
    this._phonesService.setPhones(phonesFormArray);

    this.getClientVehiclesArray.clear();
    model?.vehicles.forEach((vehicle) => {
      const veh = this._vehicleForm.getForm(vehicle);
      this.getClientVehiclesArray.push(veh);
    });
  }

  get getClientVehiclesArray(): FormArray<VehicleForm> {
    return this._form.controls.client.controls.vehicles as FormArray;
  }

  get getVehicleForm(): VehicleForm {
    return this._form.controls.vehicle as FormGroup;
  }

  setStatus(status: number): void {
    this.status = status;
  }

  getStatus(): number | null {
    return this.status;
  }

  setVehicle(model?: IAssistanceVehicle) {
    this._form.controls.vehicle.patchValue({
      type: model?.type,
      licencePlate: model?.licencePlate,
      vehicleId: model?.vehicleId,
      fullName: model?.fullName,
      brand: model?.brand,
      model: model?.model,
    });
  }

  private _getLocationForm(model?: ILocationItem): LocationForm {
    return this._fb.group<ControlsOf<ILocationItem>>({
      latitude: this._fb.control(model?.latitude, {
        validators: [Validators.required],
      }),
      longitude: this._fb.control(model?.longitude, {
        validators: [Validators.required],
      }),
      place: this._fb.control(model?.place, {
        validators: [Validators.required, Validators.maxLength(256)],
      }),
    });
  }

  set setPartnerVehicle(vehicleId: string) {
    if (!this._form) {
      return;
    }
    this._form.controls.partnerVehicleId.patchValue(vehicleId);
  }

  get isStep1CreateValid(): boolean {
    return (
      this._form.controls.client.valid &&
      this._form.controls.vehicle.valid &&
      this._form.controls.from.valid &&
      this._form.controls.to.valid &&
      this._form.controls.numOfPassengers.valid &&
      this._form.controls.failureType.valid &&
      this._form.controls.failureDescription.valid &&
      (this._form.value.type === AssistanceType.CASH ||
        (this._form.controls.clientPackageId.valid &&
          this._form.controls.serviceId.valid))
    );
  }

  // get isStep1DraftValid(): boolean {
  //   this.setStep1DraftValidation();
  //   return (
  //     this._form.controls.client.valid &&
  //     this._form.controls.vehicle.valid &&
  //     this._form.controls.from.valid &&
  //     this._form.controls.to.valid &&
  //     this._form.controls.numOfPassengers.valid &&
  //     this._form.controls.failureType.valid &&
  //     this._form.controls.failureDescription.valid
  //   );
  // }
  //
  // setStep1CreateValidation() {
  //   this.getForm.controls.packageId.setValidators([Validators.required]);
  //   this.getForm.controls.packageId.updateValueAndValidity();
  // }
  //
  // setStep1DraftValidation() {
  //   this.getForm.controls.packageId.clearValidators();
  //   this.getForm.controls.packageId.updateValueAndValidity();
  // }

  get isStep2Valid(): boolean {
    return (
      this._form.controls.partnerVehicleId.valid &&
      this._form.controls.driverId.valid &&
      this._form.controls.driverPhone.valid
    );
  }

  resetForm() {
    this.setStatus(null);
    this._form = undefined;
  }
}

export declare type AssistanceForm = FormGroup<
  ControlsOf<ICreateAssistanceCommand>
>;

export declare type LocationForm = FormGroup<ControlsOf<ILocationItem>>;
export declare type ClientForm = FormGroup<ControlsOf<IAssistanceClient>>;
export declare type PhonesForm = FormGroup<ControlsOf<IPhoneItem>>;
export declare type VehicleForm = FormGroup<ControlsOf<IAssistanceVehicle>>;
