import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,} from '@angular/core';
import {DropdownComponent} from '@ui/dropdown/dropdown.component';
import {firstValueFrom, Subscription} from 'rxjs';
import {IBrandAutocompleteItem, IVehicleBrandItem, VehicleBrandItem, VehiclesClient,} from '@shared/model/atlas.api';
import {InputSearchComponent} from '@ui/input-search/input-search.component';
import {JsonPipe, NgClass, NgIf} from '@angular/common';
import {ErrorRequiredComponent} from '@ui/errors/error-required/error-required.component';
import {NewVehicleService} from '@app/features/services/new-vehicle-service';
import {DropdownModule} from 'primeng/dropdown';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'atlas-brand-autocomplete',
  standalone: true,
  imports: [
    DropdownComponent,
    InputSearchComponent,
    NgIf,
    JsonPipe,
    NgClass,
    ErrorRequiredComponent,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './brand-autocomplete.component.html',
  styleUrl: './brand-autocomplete.component.scss',
})
export class BrandAutocompleteComponent
  implements OnInit, OnChanges, OnDestroy {
  private _subs: Subscription;
  brands: IBrandAutocompleteItem[] = [];
  shouldResetInput: boolean = false;
  selectedBrandCtrl: FormControl<IBrandAutocompleteItem> = new FormControl();

  @Input() type: string | undefined = undefined;
  @Input() brandName: string | undefined;

  @Output() public onBrandChanged = new EventEmitter<IVehicleBrandItem>();

  constructor(
    private _client: VehiclesClient,
    public newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
    this.newVehicleService.setBrandNameCtrl(this.brandName);
    this.newVehicleService.setBrandNameRequired();
  }

  ngOnInit() {
    this._subs.add(
      this.newVehicleService.shouldResetBrandInput$.subscribe((res) => {
        this.shouldResetInput = !this.shouldResetInput;
      }),
    );
    this._subs.add(
      this.selectedBrandCtrl.valueChanges.subscribe((value) => {
        console.log(value);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type']?.currentValue !== changes['type']?.previousValue) {
      this.brands = [];
    }
    if (
      changes['brandName']?.currentValue !== changes['brandName']?.previousValue
    ) {
      this.newVehicleService.setBrandNameCtrl(this.brandName);
    }
  }

  private _load(keyword?: string) {
    this._getBrands(keyword).then((res) => {
      this.brands = [...res.result.responseList];
    });
  }

  private async _getBrands(keyword: string) {
    return await firstValueFrom(
      this._client.getBrandAutocomplete(this.type, keyword),
    );
  }

  searchBrand(searchValue: string) {
    this._load(searchValue);
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }

    const {id, label} = item.value;
    if (!id) {
      return;
    }

    this.newVehicleService.setBrandNameCtrl(label);
    this.onBrandChanged.emit(
      new VehicleBrandItem({
        name: label,
        brandId: id,
      }),
    );
  }

  ngOnDestroy() {
    this.newVehicleService.resetCtrl();
    this.shouldResetInput = true;
    this.newVehicleService.shouldResetBrandInput$.next(false);
    this._subs.unsubscribe();
  }
}
