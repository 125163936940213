<div class="center-div p-4">
  <atlas-white-logo></atlas-white-logo>
  <div class="main-div">
    <h1 class="text-center">Verifikacioni kod</h1>
    <div class="flex justify-content-center align-items-center">
      <div class="col-6">
        <div class="text-lg text-center">
          Unesite verifikacioni kod koji je poslat na vašu e-mail adresu!
        </div>
        <div class="mt-7 flex flex-column gap-6">
          <atlas-input-text
            [formCtrl]="form.controls.verificationCode | formControl"
            ctrlLabel="Verifikacioni kod"
            ctrlPlaceholder="Verifikacioni kod"
            formCtrlId="verificationCode"
          ></atlas-input-text>
        </div>
        <div class="flex flex-column mt-2 gap-3">
          <atlas-button
            (btnClicked)="handleRegister()"
            [btnStyle]="ButtonStyle.BASIC"
            btnClass="w-full"
            btnLabel="Registracija"
          ></atlas-button>
        </div>
        <div class="mt-4 flex justify-content-center align-items-center">
          <i class="pi pi-arrow-left" (click)="handleBackToLogin()"></i>
          <atlas-button
            (btnClicked)="handleBackToLogin()"
            btnClass="p-0"
            btnLabel="Nazad na logovanje"
          ></atlas-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-base mt-2 text-gray-600 text-center footer">
  &copy; Atlas24h
</div>
