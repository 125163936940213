import {
  NgIf,
  NgClass,
  NgFor,
  NgForOf,
  NgStyle,
  DatePipe,
  NgOptimizedImage,
} from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClientForAssistanceAutocompleteComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/client-for-assistance-autocomplete/client-for-assistance-autocomplete.component';
import { PageComponent } from '@shared/components/page/page.component';
import {
  AgentAutocompleteResponse,
  CampaignsClient,
  CampaignStatisticsResponse,
  GetLeadsTableQuery,
  IAgentAutocompleteResponse,
  ICampaignResponse,
} from '@shared/model/atlas.api';
import { ButtonComponent, ButtonSeverity } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableComponent } from '@ui/table/table.component';
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom, Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { ConfirmationService } from 'primeng/api';
import { LocationService } from '@shared/services/location.service';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { EditCampaignComponent } from '@app/cc-leader/campaigns/edit-campaign/edit-campaign.component';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { ChangeAgentsFormComponent } from '@app/cc-leader/campaigns/agents/change-agents-form/change-agents-form.component';
import { ClientWithPackagesTableComponent } from '@app/features/orders/client-with-packages-table/client-with-packages-table.component';
import { AgentListImagesComponent } from '@app/cc-leader/campaigns/agents/agent-list-images/agent-list-images.component';
import { EditLeadsComponent } from '../../edit-leads/edit-leads/edit-leads.component';
import { LeadCampaignDetailsTableComponent } from '@app/cc-leader/campaigns/contract-details/lead-campaign-details-table/lead-campaign-details-table.component';
import { AddCampaignContractComponent } from '@app/cc-leader/campaigns/add-campaign/add-campaign-contract/add-campaign-contract.component';
import { AddCampaignLeadsComponent } from '@app/cc-leader/campaigns/add-campaign/add-campaign-leads/add-campaign-leads.component';
import { DividerModule } from 'primeng/divider';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import { SkeletonModule } from 'primeng/skeleton';
import { EditPackagesClientTableComponent } from '../../edit-packages-client-table/edit-packages-client-table.component';
import { ClientWithPackageTableFilterService } from '@app/features/orders/services/client-with-package-table-filter.service';

@Component({
  selector: 'atlas-contract-details',
  standalone: true,
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    InputSearchComponent,
    ClientForAssistanceAutocompleteComponent,
    DropdownComponent,
    InlineWrapperComponent,
    InputTextComponent,
    FormsModule,
    TableComponent,
    ButtonComponent,
    ContextMenuModule,
    PageComponent,
    TabViewModule,
    ConfirmDialogModule,
    InputTextModule,
    InputTextComponent,
    BadgeModule,
    TooltipModule,
    NgIf,
    NgClass,
    NgFor,
    NgForOf,
    ProgressBarModule,
    BadgeModule,
    NgStyle,
    DatePipe,
    OverlayPanelModule,
    InputSwitchComponent,
    InputSwitchModule,
    ClientWithPackagesTableComponent,
    PageComponent,
    NgOptimizedImage,
    AgentListImagesComponent,
    LeadCampaignDetailsTableComponent,
    AddCampaignContractComponent,
    AddCampaignLeadsComponent,
    DividerModule,
    IconCheckboxComponent,
    ScrollablePageComponent,
    SkeletonModule,
  ],
  templateUrl: './contract-details.component.html',
  styleUrl: './contract-details.component.scss',
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  campaignId: string;
  campaignData: ICampaignResponse;
  campaignStats: CampaignStatisticsResponse;
  showDetails = true;
  campaignType: number;
  agents: IAgentAutocompleteResponse[] = [];

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);
  dialogConfigAgents: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);
  dialogCongifLeads: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);
  dialogConfigPackages: DialogConfig = new DialogConfig(
    DialogSize.SMALL_MEDIUM,
  );

  constructor(
    private _client: CampaignsClient,
    private route: ActivatedRoute,
    private _confirmationService: ConfirmationService,
    private _alertService: AlertService,
    private location: LocationService,
    private _dialogService: DialogService,
    private service: CampaignFormService,
    public filterService: ClientWithPackageTableFilterService,
  ) {}

  ngOnInit(): void {
    this.campaignId = this.route.snapshot.paramMap.get('id')!;
    this.load();
    if (localStorage.getItem('reopenDialog') === 'true') {
      this.changeLeads(this.campaignId);
      localStorage.removeItem('reopenDialog');
    }
  }

  getPriorityText(priority: number): string {
    switch (priority) {
      case 4:
        return 'Hitno';
      case 3:
        return 'Visok prioritet';
      case 2:
        return 'Srednji prioritet';
      case 1:
        return 'Nizak prioritet';
      case 0:
        return 'Bez prioriteta';
      default:
        return 'Bez prioriteta';
    }
  }

  getFlagColor(priority: number): string {
    switch (priority) {
      case 4:
        return '#F04438';
      case 3:
        return '#EB9229';
      case 2:
        return '#1E7EC3';
      case 1:
        return '#1EC360';
      case 0:
        return '#999999';
      default:
        return '#999999';
    }
  }

  deleteCampaign(id: string) {
    this._confirmationService.confirm({
      message: 'Da li želiš da izbrišeš kampanju? Akcija se ne može poništiti.',
      acceptLabel: 'Izbriši',
      rejectLabel: 'Odustani',
      header: 'Brisanje kampanje',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._client.deleteCampaign(id).subscribe((x) => {
            this._alertService.addSuccessMsg(x.result);
            this.routeToTable();
          }),
        );
      },
    });
  }

  changeAgents(campaignId: any) {
    this.service.setCampaignId(campaignId);
    this.dialogConfigAgents.header = 'Izmeni agente';
    this.dialogConfigAgents.customSubmitButton = {
      label: 'Sačuvaj',
      icon: '',
    };
    this.dialogConfigAgents.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfigAgents.closable = true;
    this.dialogConfigAgents.data = { campaignId };
    const dialogRef = this._dialogService.open(
      ChangeAgentsFormComponent,
      this.dialogConfigAgents,
    );
    this._subs.add(
      dialogRef.onClose.subscribe((res) => {
        if (res === true) {
          this.load();
          this.filterService.reloadTable();
        }
      }),
    );
  }

  changeLeads(campaignId: any) {
    this.service.setCampaignId(campaignId);
    this.dialogCongifLeads.header = 'Izmeni leadove';
    this.dialogCongifLeads.hideFooter = true;
    this.dialogCongifLeads.closable = true;
    this.dialogCongifLeads.data = { campaignId };
    const dialogRef = this._dialogService.open(
      EditLeadsComponent,
      this.dialogCongifLeads,
    );
    this._subs.add(
      dialogRef.onClose.subscribe((res) => {
        if (res === true) {
          this.load();
          this.filterService.reloadTable();
        }
      }),
    );
  }

  editCampaign(campaignId: any) {
    this.service.setCampaignId(campaignId);
    this.dialogConfig.header = 'Izmena kampanje';
    this.dialogConfig.customSubmitButton = {
      label: 'Izmeni',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.closable = true;
    this.dialogConfig.data = { campaignId };
    const dialogRef = this._dialogService.open(
      EditCampaignComponent,
      this.dialogConfig,
    );
    this._subs.add(
      dialogRef.onClose.subscribe((res) => {
        if (res === true) {
          this.load();
        }
      }),
    );
  }

  editPackages(campaignId: any) {
    this.service.setCampaignId(campaignId);
    this.dialogConfigPackages.header = 'Izmena ugovora';
    this.dialogConfigPackages.customSubmitButton = {
      label: 'Sačuvaj',
      icon: '',
    };
    this.dialogConfigPackages.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfigPackages.closable = true;
    this.dialogConfigPackages.data = { campaignId };
    const dialogRef = this._dialogService.open(
      EditPackagesClientTableComponent,
      this.dialogConfigPackages,
    );
    this._subs.add(
      dialogRef.onClose.subscribe((res) => {
        if (res === true) {
          this.load();
          this.filterService.reloadTable();
        }
      }),
    );
  }

  deactivateCamapign(id: string, isActive: boolean) {
    this.service.setCampaignId(id);
    isActive ? this._deactivationDialog() : this._activationDialog();
  }

  private _deactivationDialog() {
    this._confirmationService.confirm({
      header: 'Deaktivacija kampanje',
      message: 'Da li želite da deaktivirate trenutnu kampanju?',
      acceptButtonStyleClass: ButtonSeverity.DANGER,
      acceptLabel: 'Deaktiviraj',
      rejectLabel: 'Odustani',
      icon: 'confirm-cancel.svg',
      accept: () => {
        alert('ToDo update camapign status...');
      },
    });
  }

  private _activationDialog() {
    this._confirmationService.confirm({
      header: 'Aktivacija kampanje',
      message: 'Da li želite da aktivirate trenutnu kampanju?',
      acceptButtonStyleClass: 'background-color: #green !important;',
      acceptLabel: 'Aktiviraj',
      rejectLabel: 'Odustani',
      icon: 'confirm-cancel.svg',
      accept: () => {
        alert('ToDo update camapign status...');
      },
    });
  }

  routeToTable() {
    this.location.routeToCampaignTable();
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  onInfoEllipsis(event: Event, overlayPanel: any) {
    overlayPanel.toggle(event);
  }

  getCampaignStatusText(status: number): string {
    switch (status) {
      case 0:
        return 'U toku';
      case 1:
        return 'Završena';
      case 2:
        return 'Prekinuta';
      default:
        return 'Nepoznato';
    }
  }

  getCampaignClass(status: number): string {
    switch (status) {
      case 0: // In Progress
        return 'badge-info';
      case 1: // Done
        return 'badge-success';
      case 2: // Cancelled
        return 'badge-warning';
      default:
        return 'badge-default';
    }
  }

  load() {
    this._subs.add(
      this._client.getCampaignById(this.campaignId).subscribe((res) => {
        this.campaignData = res.result;
        this.campaignType = this.campaignData.type;
        this.agents = [
          ...this.campaignData.sellers,
          ...this.campaignData.tempSellers,
        ];
        if (this.campaignType === 0) {
          this.loadStats();
        }
      }),
    );
  }

  loadStats() {
    this._subs.add(
      this._client.getCampaignStatistics(this.campaignId).subscribe((res) => {
        this.campaignStats = res.result;
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
