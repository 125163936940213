<atlas-page
  [pageTitle]="pageTitle"
  [pageDescription]="pageDescription"
  btnSvgUrl="/assets/images/buttons/add-client.svg"
  actionLabel="Dodaj klijenta"
  (onHandleAction)="handleCreateClient()"
>
  <atlas-client-with-packages-table
    [canMultiselect]="true"
  ></atlas-client-with-packages-table>

</atlas-page>
