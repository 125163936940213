<div>
  <div class="font-bold text-lg mb-5">Detalji</div>
  <div>
    <p class="font-bold mb-0">Ime</p>
    <atlas-input-text
      ctrlPlaceholder="Ime vozača"
      [formCtrl]="form.controls.name"
    ></atlas-input-text>
  </div>
  <div>
    <p class="font-bold mb-0">Prezime</p>
    <atlas-input-text
      ctrlPlaceholder="Prezime vozača"
      [formCtrl]="form.controls.lastName"
    ></atlas-input-text>
  </div>
  <div>
    <p class="font-bold mb-2">Broj telefona</p>
    <atlas-contact-phone-list></atlas-contact-phone-list>
  </div>
  <div>
    <p class="font-bold mb-0">E-mail adresa</p>
    <atlas-input-mail
      ctrlPlaceholder="E-mail adresa vozača"
      [formCtrl]="form.controls.email"
    ></atlas-input-mail>
  </div>
  <div *ngIf="showSwitch">
    <p class="font-bold mb-3">Pristup</p>
    <div class="mt-2 flex">
      <p-inputSwitch
        (onChange)="handleSendInviteChange($event)"
      ></p-inputSwitch>
      <p class="m-1 ml-3">Pošalji pristup aplikaciji</p>
    </div>
  </div>
</div>
