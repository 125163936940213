<atlas-dropdown
  [options]="internalOptions"
  optionLabel="name"
  ctrlDataKey="id"
  [formCtrl]="formCtrl"
  ctrlStyleClass="w-full"
  [hideErrors]="true"
  ctrlPlaceholder="Izaberi popust"
  (optionChanged)="handleChange($event)"
  [canClear]="true"
>
  <!-- Template for the selected item -->
  <ng-template #selectedItem let-obj>
    <div class="selected-item">
      {{ obj.name }} - ({{ obj.description }})
    </div>
  </ng-template>

  <!-- Template for each item in the dropdown list -->
  <ng-template #item let-obj>
    <div class="dropdown-item">
      {{ obj.name }} - ({{ obj.description }})
    </div>
  </ng-template>
</atlas-dropdown>

