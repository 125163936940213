import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormsModule, Validators } from '@angular/forms';
import { PackageCardListComponent } from '@app/features/packages/package-card-list/package-card-list.component';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import {
  IVehicleBrandItem,
  VehiclesClient,
  VehicleTypeItem,
} from '@shared/model/atlas.api';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { ModelAutocompleteComponent } from '@app/features/autocompletes/model-autocomplete/model-autocomplete.component';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { BrandDropdownComponent } from '@app/features/dropdowns/brand-dropdown/brand-dropdown.component';
import { ModelDropdownComponent } from '@app/features/dropdowns/model-dropdown/model-dropdown.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { VehicleTypeIconPipe } from '@shared/pipes/vehicle-type-icon.pipe';
import { LicencePlateRegExp } from '@shared/extensions-common';
import {
  CampaignFormService,
  LeadTransferData,
} from '@app/cc-leader/services/campaign-form.service';

@Component({
  selector: 'atlas-create-order-form-step-2',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgForOf,
    CheckboxModule,
    InputTextComponent,
    IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    PackageCardListComponent,
    BrandAutocompleteComponent,
    ModelAutocompleteComponent,
    BrandDropdownComponent,
    ModelDropdownComponent,
    FormControlPipe,
    SkeletonModule,
    VehicleTypeIconPipe,
  ],
  templateUrl: './create-order-form-step-2.component.html',
  styleUrl: './create-order-form-step-2.component.scss',
})
export class CreateOrderFormStep2Component implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  selectedOption: 'Vozilo' | 'Licni' = 'Vozilo';
  vehicleTypes: VehicleTypeItem[];
  selectedBrandId: string | null;
  selectedVehicleType: string | null;
  isPackageDropdownOpen: boolean = false;
  selectedPackage: any;
  orderForm: OrderForm;
  isKombiSelected: boolean;
  leadTransferData: LeadTransferData;

  showModelTxtInput: boolean = false;

  constructor(
    private _vehiclesClient: VehiclesClient,
    private _packageListService: PackageCardListService,
    private _orderFormService: OrderFormService,
    private _newVehicleService: NewVehicleService,
    private _campaignFormService: CampaignFormService,
  ) {
    this.loadVehicleTypes();
    this.orderForm = this._orderFormService.getOrderForm;
  }

  ngOnInit(): void {
    this._packageListService.setLicniPaketUnselected();

    this._newVehicleService
      .getShowMotorModelInput()
      .pipe(distinctUntilChanged())
      .subscribe((show) => (this.showModelTxtInput = show));

    this.selectedOption = this.orderForm.value.isForVehicle
      ? 'Vozilo'
      : 'Licni';

    this.orderForm.controls.isForVehicle.valueChanges.subscribe(
      (isForVehicle) => {
        if (isForVehicle) {
          this.handleTypeChange(this.vehicleTypes[1]);
          this._packageListService.clearAllPackages();
          this._packageListService.setSelectedPackageId(null, 0);
          this._packageListService.setLicniPaketUnselected();
        }
      },
    );
  }

  selectPackageForVehicle() {
    this.selectedOption = 'Vozilo';
    this.orderForm.controls.isForVehicle.patchValue(true);
    this.orderForm.controls.client.controls.nationalNumber.clearValidators();
    this.orderForm.controls.client.controls.nationalNumber.updateValueAndValidity();
  }

  selectPackageForPerson() {
    this.selectedOption = 'Licni';
    this.orderForm.controls.isForVehicle.patchValue(false);
    this._packageListService.setLicniPaketSelected();
    this._packageListService.setVehicleTypeId(null);
    this.orderForm.controls.client.controls.nationalNumber.addValidators([
      Validators.required,
    ]);
    this.orderForm.controls.client.controls.nationalNumber.updateValueAndValidity();
  }

  setLeadTransferData() {
    this._subs.add(
      this._campaignFormService.leadTransferData$.subscribe((x) => {
        this.leadTransferData = x;

        this._newVehicleService.setBrandNameCtrl(
          this.leadTransferData.vehicleBrand,
        );
        this._newVehicleService.setModelNameCtrl(
          this.leadTransferData.vehicleModel,
        );

        this.orderForm.controls.vehicle.controls.brand.patchValue(
          this.leadTransferData.vehicleBrand,
        );
        this.orderForm.controls.vehicle.controls.model.patchValue(
          this.leadTransferData.vehicleModel,
        );
        this.orderForm.controls.vehicle.controls.productionYear.patchValue(
          Number(this.leadTransferData.vehicleYear),
        );
        this.orderForm.controls.vehicle.controls.licencePlate.patchValue(
          this.leadTransferData.registationNumber,
        );
        this.orderForm.controls.vehicle.controls.chassis.patchValue(
          this.leadTransferData.chassis,
        )
      }),
    );
  }

  selectPackage(pkg: any) {
    this.selectedPackage = pkg;
    this.isPackageDropdownOpen = false;
  }

  private loadVehicleTypes() {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList;
      }),
    );
  }

  handleTypeChange(vehicleTypeItem: VehicleTypeItem) {
    this._newVehicleService.setShowMotorModelInput(
      vehicleTypeItem.name.includes('Mot'),
    );
    this._newVehicleService.resetCtrlValues();

    const vehicleGroup = this.orderForm.controls.vehicle as FormGroup;
    vehicleGroup.reset({
      type: vehicleTypeItem.name,
      id: null,
      brand: null,
      model: null,
      licencePlate: null,
      productionYear: null,
      color: null,
    });

    this.setLeadTransferData();

    this.isKombiSelected = vehicleTypeItem.name.includes('Kombi');
    this.selectedVehicleType = vehicleTypeItem.name;
    this.selectedBrandId = null;

    this._packageListService.setVehicleTypeId(vehicleTypeItem.typeId);
    this._packageListService.clearAllPackages();
    this.orderForm.controls.packages.clear();
  }

  handleBrandChange(brandItem: IVehicleBrandItem) {
    this.selectedBrandId = brandItem.brandId;
    this.orderForm.controls.vehicle.controls.brand.patchValue(brandItem.name);
    this.orderForm.controls.vehicle.controls.model.patchValue(null);
  }

  handleModelChanged(model: string) {
    this.orderForm.controls.vehicle.controls.model.patchValue(model);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  protected readonly LicencePlateRegExp = LicencePlateRegExp;
}
