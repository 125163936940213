import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  DeliveryType,
  ICreateOrderInboundCommand,
  IOrderClient,
  IOrderVehicle,
  IPackageCardItem,
  IRenewOrderInboundCommand,
  PaymentType,
} from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderFormService {
  renewPackageForm: RenewPackageForm;

  constructor(private _fb: FormBuilder) {}

  //#region renewPackage

  initRenewPackageForm(model?: IRenewOrderInboundCommand) {
    this.renewPackageForm = this._fb.group<
      ControlsOf<IRenewOrderInboundCommand>
    >({
      packageItems: this._fb.array<FormGroup<ControlsOf<IPackageCardItem>>>(
        [],
      ) as FormArray,
      deliveryType: this._fb.control(DeliveryType.BEX, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      paymentType: this._fb.control(PaymentType.CASH, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      isUrgent: this._fb.control(true, {
        nonNullable: false,
        validators: [Validators.required],
      }),
      clientId: this._fb.control(model?.clientId),
      mediumId: this._fb.control(model?.mediumId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      sourceId: this._fb.control(model?.sourceId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
    return this.renewPackageForm;
  }

  private _renewClientId: string;

  setRenewClientId(clientId: string) {
    this._renewClientId = clientId;
  }

  getRenewClientId(): string {
    return this._renewClientId;
  }

  setRenewToDraft() {
     this.renewPackageForm.controls.paymentType.patchValue(PaymentType.CORVUS_PAY)
        this.renewPackageForm.controls.paymentType.updateValueAndValidity();
        this.renewPackageForm.controls.deliveryType.patchValue(DeliveryType.E_CONTRACT);
        this.renewPackageForm.controls.deliveryType.updateValueAndValidity();
        this.renewPackageForm.controls.isUrgent.patchValue(true);
        this.renewPackageForm.controls.isUrgent.updateValueAndValidity();
        // this.renewPackageForm.controls.paymentType.disable();
        // this.renewPackageForm.controls.deliveryType.disable();
  }

  setRenewToNew() {
    this.renewPackageForm.controls.paymentType.patchValue(PaymentType.PAYMENT_SLIP);
      this.renewPackageForm.controls.paymentType.updateValueAndValidity();
      this.renewPackageForm.controls.paymentType.enable();
      this.renewPackageForm.controls.deliveryType.enable();
  }

  //#endregion renewPackage

  //#region Order

  private readonly STEP_KEY = 'currentStep';
  private stepSubject = new BehaviorSubject<number>(this.getInitialStep());

  currentStep$ = this.stepSubject.asObservable();

  private getInitialStep(): number {
    const storedStep = localStorage.getItem(this.STEP_KEY);
    return storedStep ? +storedStep : 1;
  }

  setStep(step: number): void {
    localStorage.setItem(this.STEP_KEY, step.toString());
    this.stepSubject.next(step);
  }

  restartStep() {
    localStorage.removeItem(this.STEP_KEY);
    this.stepSubject.next(1);
  }

  resetOrderForm() {
    this._orderForm = undefined;
  }

  get isStep2Valid(): boolean {
    return (
      this._orderForm.controls.vehicle.valid &&
      this._orderForm.controls.packages.valid
    );
  }

  get isStep2ForPersonPackageValid(): boolean {
    return (
      this._orderForm.controls.towingLocation.valid &&
      this._orderForm.controls.packages.valid
    );
  }

  private _orderForm: OrderForm | undefined;

  get getOrderForm(): OrderForm {
    if (!this._orderForm) {
      this._initOrderForm();
    }
    return this._orderForm;
  }

  private _initOrderForm(model?: ICreateOrderInboundCommand) {
    if (!this._orderForm) {
      this._orderForm = this._fb.group<ControlsOf<ICreateOrderInboundCommand>>({
        id: this._fb.control(model?.id, {
          nonNullable: true,
        }),
        client: this.getClientForm(model?.client) as FormGroup,
        isForVehicle: this._fb.control(true, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        vehicle: this.getVehicleForm(model?.vehicle) as FormGroup,
        packages: this._fb.array<FormGroup<ControlsOf<IPackageCardItem>>>(
          [],
        ) as FormArray,
        towingLocation: this._fb.control(model?.towingLocation, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(128)],
        }),
        deliveryType: this._fb.control(DeliveryType.E_CONTRACT, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        paymentType: this._fb.control(PaymentType.CASH, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        isUrgent: this._fb.control(true, {
          nonNullable: true,
          validators: [Validators.required],
        }),
      });
    }
  }

  //#region OrderClientForm
  getClientForm(model?: IOrderClient): OrderClientForm {
    return this._fb.group<ControlsOf<IOrderClient>>({
      clientId: this._fb.control(model?.clientId),
      name: this._fb.control(model?.name, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(64)],
      }),
      lastName: this._fb.control(model?.lastName, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(64)],
      }),
      phone: this._fb.control(model?.phone, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)],
      }),
      email: this._fb.control(model?.email, {
        validators: [Validators.email, Validators.maxLength(64)],
      }),
      companyName: this._fb.control(model?.companyName, {
        nonNullable: true,
        validators: [Validators.maxLength(64)],
      }),
      companyAddress: this._fb.control(model?.companyAddress, {
        nonNullable: true,
        validators: [Validators.maxLength(256)],
      }),
      nationalNumber: this._fb.control(model?.nationalNumber, {
        nonNullable: true,
        validators: [Validators.maxLength(24)],
      }),
      taxId: this._fb.control(model?.taxId, {
        nonNullable: true,
        validators: [Validators.maxLength(32)],
      }),
      mediumId: this._fb.control(model?.mediumId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      sourceId: this._fb.control(model?.sourceId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  //#endregion OrderClientForm

  //#region OrderVehicleForm
  getVehicleForm(model?: IOrderVehicle): OrderVehicleForm {
    return this._fb.group<ControlsOf<IOrderVehicle>>({
      id: this._fb.control(model?.id),
      brand: this._fb.control(model?.brand, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)],
      }),
      type: this._fb.control(model?.type, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)],
      }),
      model: this._fb.control(model?.model, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)],
      }),
      licencePlate: this._fb.control(model?.licencePlate, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(8)],
      }),
      chassis: this._fb.control(model?.chassis, {
        validators: [Validators.maxLength(24)],
      }),
      productionYear: this._fb.control(model?.productionYear, {
        validators: [Validators.maxLength(4)],
      }),
      color: this._fb.control(model?.color, {
        validators: [Validators.maxLength(64)],
      }),
    });
  }

  //#endregion OrderVehicleForm

  //#endregion Order
  setToDraft() {
    this._orderForm.controls.paymentType.patchValue(PaymentType.CORVUS_PAY);
    this._orderForm.controls.paymentType.updateValueAndValidity();
    this._orderForm.controls.deliveryType.patchValue(DeliveryType.E_CONTRACT);
    this._orderForm.controls.deliveryType.updateValueAndValidity();
    this._orderForm.controls.isUrgent.patchValue(true);
    this._orderForm.controls.isUrgent.updateValueAndValidity();
  }

  setToNew() {
    this._orderForm.controls.paymentType.enable();
    this._orderForm.controls.deliveryType.enable();
    this._orderForm.controls.isUrgent.enable();
    this._orderForm.controls.paymentType.patchValue(PaymentType.PAYMENT_SLIP);
  }
}

export declare type RenewPackageForm = FormGroup<
  ControlsOf<IRenewOrderInboundCommand>
>;
export declare type OrderForm = FormGroup<
  ControlsOf<ICreateOrderInboundCommand>
>;
export declare type OrderClientForm = FormGroup<ControlsOf<IOrderClient>>;
export declare type OrderVehicleForm = FormGroup<ControlsOf<IOrderVehicle>>;
