import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormsModule } from '@angular/forms';
import {
  EditEmployeeCommand,
  EmployeeRoleItem,
  EmployeesClient,
  IEmployeeRoleItem,
} from '@shared/model/atlas.api';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { firstValueFrom, Subscription } from 'rxjs';
import { InputSwitchModule } from 'primeng/inputswitch';
import {
  AdminService,
  EditEmployeeForm,
} from '@app/administrator/services/admin-service';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { AlertService } from '@shared/services/alert.service';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { NgIf } from '@angular/common';
import { EmployeeRoleDropdownComponent } from '@app/administrator/employees/employee-role-dropdown/employee-role-dropdown.component';
import { RoleMultiselectComponent } from '@app/features/multiselects/role-multiselect/role-multiselect.component';
import { ControlsOf } from '@shared/forms/forms-common';

@Component({
  selector: 'atlas-edit-employee',
  standalone: true,
  providers: [DynamicDialogConfig],
  imports: [
    NgIf,
    FormsModule,
    InputSwitchModule,
    InputSwitchComponent,
    RoleMultiselectComponent
  ],
  templateUrl: './edit-employee.component.html',
  styleUrl: './edit-employee.component.scss',
})
export class EditEmployeeComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form: EditEmployeeForm | undefined;

  constructor(
    private _dailogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private _employeeClient: EmployeesClient,
    private _adminService: AdminService,
    private _alertService: AlertService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this._loadEmployeeInfo(this._dailogConfig.data.id)

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
  }

  private _loadEmployeeInfo(id: string): Promise<any> {
    return this._getEmployee(id).then((res) => {
      this.form = this._adminService.getEditEmployeeForm(res.result);
    });
  }

  private async _getEmployee(id: string) {
    return await firstValueFrom(this._employeeClient.getEmployeeById(id))
  }

  handleSubmit() {
    this._subs.add(
      this._employeeClient
        .editEmployee(this.form.value as EditEmployeeCommand)
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close({
            success: true
          })
        }),
    );
  }

  ngOnDestroy(): void {
    this._adminService.reset();
    this._subs.unsubscribe();
  }
}
