import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { AgentAutocompleteComponent } from '@app/cc-leader/clients/agent-autocomplete/agent-autocomplete.component';
import {
  AssignPackagesToSellerCommand,
  OrdersClient,
} from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { NgOptimizedImage } from '@angular/common';
import { LoaderService } from '@shared/components/loader/loader.service';
import { DialogConfig } from '@ui/dialog/dialog-config';

@Component({
  selector: 'atlas-change-seller-dialog',
  standalone: true,
  imports: [AgentAutocompleteComponent, NgOptimizedImage],
  templateUrl: './change-seller-dialog.component.html',
  styleUrl: './change-seller-dialog.component.scss',
})
export class ChangeSellerDialogComponent implements OnInit, OnDestroy {
  client: any[];
  agentName: string;
  selectedAgentId: string;
  agentIds: string[] = [];
  sellerName: string;
  cpIds: string[] = [];
  agentImageUrl: string;
  private _subs: Subscription = new Subscription();
  @Output() dataEmitter = new EventEmitter<{
    packageIdsLength: number;
    clientNames: string[];
  }>();

  constructor(
    private service: ClientPackageTableService,
    private _client: OrdersClient,
    private _alertService: AlertService,
    private _dialogRef: DialogRef,
    private _loaderService: LoaderService,
    private _dialogConfig: DialogConfig,
  ) {}

  ngOnInit(): void {
    this._loadSelectedClients();
    this._setAgentInfo();
    this._listenForSubmit();
  }

  private _loadSelectedClients(): void {
    this.cpIds = [];
    this._subs.add(
      this.service.getSelectedPackages().subscribe((res) => {
        this.agentIds = [];
        res.forEach((pkg) => {
          this.cpIds.push(pkg.clientPackageId);
          this.agentIds.push(pkg.sellerId);
        });
      }),
    );
  }

  private _setAgentInfo(): void {
    const cps = this.service.getSelectedCps();
    const uniqueSellers = [...new Set(cps.map((cp) => cp.seller))];
    if (uniqueSellers.length === 1) {
      this.sellerName = uniqueSellers[0];
      this.agentImageUrl =
        cps.find((client) => client.seller === uniqueSellers[0])?.sellerImage ||
        '../../../../assets/images/client-icon.svg';
    } else {
      this.sellerName = `Ukupno ${uniqueSellers.length}`;
      this.agentImageUrl = '../../../../assets/images/client-icon.svg';
    }
  }

  private _listenForSubmit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  onAgentSelected(agent: { id: string; name: string }): void {
    this.selectedAgentId = agent.id;
    this.agentName = agent.name;
  }

  public _handleSave() {
    let isSellerChanged = false;

    const selectedClients = this.service.getSelectedCps();

    const clientNames = this.cpIds.map((cpId) => {
      const client = selectedClients.find((client) =>
        client.packages.some((pkg) => pkg.clientPackageId === cpId),
      );
      return client ? client.name : 'Unknown Client';
    });
    this._loaderService.setLoadingText = 'Promena prodavca ...';
    this._loaderService.setShowLoader = true;
    this._dialogConfig.styleClass = 'display: hidden';
    this._subs.add(
      this._client
        .assignPackagesToSeller(
          new AssignPackagesToSellerCommand({
            clientPackageIds: this.cpIds,
            toSellerId: this.selectedAgentId,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          isSellerChanged = true;

          this.dataEmitter.emit({
            packageIdsLength: this.cpIds.length,
            clientNames: clientNames,
          });
          this._dialogConfig.styleClass = 'display: block';
          this._loaderService.reset();

          this.exit(isSellerChanged);
        }),
    );
  }

  exit(isSellerChanged: boolean) {
    this._dialogRef.close({
      isSellerChanged: isSellerChanged,
      packageIdsLength: this.cpIds.length,
      agentName: this.agentName,
      agentIds: this.agentIds,
      packageIds: this.cpIds,
    });
  }

  ngOnDestroy(): void {
    this.service.initSelectedPackages([]);
    this.service.resetSelectedCps();
    this._subs.unsubscribe();
  }
}
