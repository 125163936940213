import { Component, OnDestroy, OnInit } from '@angular/core';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { ClientWithPackagesTableComponent } from '@app/features/orders/client-with-packages-table/client-with-packages-table.component';
import {
  CampaignsClient,
  EditCampaignPackagesCommand,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-edit-packages-client-table',
  standalone: true,
  imports: [ClientWithPackagesTableComponent],
  templateUrl: './edit-packages-client-table.component.html',
  styleUrl: './edit-packages-client-table.component.scss',
})
export class EditPackagesClientTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  campaignId: string;
  contractIds: string[];

  constructor(
    private service: CampaignFormService,
    private _client: CampaignsClient,
    private _dialogRef: DialogRef,
    private _alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
    this.campaignId = this.service.getCampaignId();
  }

  handleSelectedContracts($event: string[]) {
    this.contractIds = $event;
  }

  handleSubmit() {
    this._subs.add(
      this._client
        .editCampaignPackages(
          new EditCampaignPackagesCommand({
            campaignId: this.campaignId,
            clientPackageIds: this.contractIds,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close(true);
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
