<atlas-scrollable-page
  [pageTitle]="title"
  (onCancelAction)="handleCancelForm()"
>
  <ng-template #content>
    <div *ngIf="!selectedAgent">Učitavanje agenta...</div>
    <div class="flex justify-content-between mb-5" *ngIf="selectedAgent">
      <!-- Agent info -->
      <div>
        <!-- Agent avatar -->
        <div class="mb-5">
          <div class="profile-container profile-image">
            <img
              [ngSrc]="
                selectedAgent.imageUrl
                  ? selectedAgent.imageUrl
                  : 'assets/images/client-icon.svg'
              "
              alt="profile-image"
              width="80"
              height="80"
            />
            <div
              class="table-badge"
              [ngClass]="{
                'is-active-true': selectedAgent.onlineStatusBadge.value,
                'is-active-false': !selectedAgent.onlineStatusBadge.value
              }"
            >
              <div class="dot"></div>
              <span>{{ selectedAgent.onlineStatusBadge.label }}</span>
            </div>
          </div>
        </div>

        <!-- Agent contacts -->
        <div class="flex">
          <div class="flex">
            <div class="agent-info-header pi pi-envelope mr-2"></div>
            <div class="agent-info-header mr-3">{{ selectedAgent.email }}</div>
          </div>
          <div class="flex">
            <div class="agent-info-header pi pi-user mr-2"></div>
            <div class="agent-info-header mr-3">Agent</div>
          </div>
          <div class="flex">
            <div class="agent-info-header pi pi-calendar mr-2"></div>
            <div class="agent-info-header mr-3">
              {{ selectedAgent.createdOn }}
            </div>
          </div>
        </div>
      </div>

      <!-- Agent stats -->
      <div>
        <!-- ToDo: enable this when time comes out -->
        <!--    <div class="header-btn mb-3 flex justify-content-end">-->
        <!--      <atlas-button-->
        <!--        [btnIcon]="'pi pi-ellipsis-v'"-->
        <!--        [btnStyle]="ButtonStyle.OUTLINED"-->
        <!--        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"-->
        <!--        (btnClicked)="handleAgentOptions()"-->
        <!--      ></atlas-button>-->
        <!--    </div>-->

        <div
          class="flex"
          style="display: flex; gap: 20px; flex-wrap: wrap; width: 700px"
        >
          <div class="main-box">
            <div class="box-icon">
              <img
                [ngSrc]="'assets/images/sales-icon.svg'"
                width="20"
                height="20"
                alt="sales-image"
              />
            </div>
            <div class="text-wrap">
              <div class="title h-2rem">Ukupno prodaja</div>
              <div class="count">{{ selectedAgent.totalSales }}</div>
            </div>
          </div>
          <div class="main-box">
            <div class="box-icon">
              <img
                [ngSrc]="'assets/images/calendar-icon.svg'"
                width="20"
                height="20"
                alt="calendar-image"
              />
            </div>
            <div class="text-wrap">
              <div class="title h-2rem">Ovaj mesec</div>
              <div class="count">{{ selectedAgent.salesThisMonth }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p-divider></p-divider>

    <div>
      <atlas-client-with-packages-table
        [showSearchBar]="true"
        [canRenewPackage]="false"
        [userId]="employeeId"
      ></atlas-client-with-packages-table>
    </div>
  </ng-template>
</atlas-scrollable-page>
