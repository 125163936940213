import { PriceValueType } from '@shared/model/atlas.api';

export function typedKeys<T>(o: T): (keyof T)[] {
  // type cast should be safe because that's what really Object.keys() does
  return Object.keys(o) as (keyof T)[];
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getVehicleTypeIcon(vehicleType: string) {
  console.log('get type for: ', vehicleType);
  if (vehicleType.includes('Mot')) {
    return 'assets/images/moto-type.svg';
  }
  if (vehicleType.includes('Kombi')) {
    return 'assets/images/truck-type.svg';
  }
  return 'assets/images/user-vehicle.svg';
}

export function convertToCsv(data: any[], headers: string): string {
  const rows = data
    .map((item) => {
      const values = Object.values(item).map((value) => {
        if (Array.isArray(value)) {
          return value.join('\\');
        } else if (typeof value === 'string' && value.includes(',')) {
          return value.split(',')[0];
        } else {
          return value;
        }
      });
      return values.join(',');
    })
    .join('\n');
  return `${headers}\n${rows}`;
}

export interface IAppliedDiscount {
  originalPrice: number;
  total: number;
}

export function applyDiscount(
  valueType: PriceValueType,
  value: number,
  originalPrice: number,
): IAppliedDiscount {
  if (valueType == PriceValueType.CASH) {
    return { originalPrice: originalPrice, total: originalPrice - value };
  }
  return {
    originalPrice: originalPrice,
    total: originalPrice - (originalPrice * value) / 100,
  };
}
