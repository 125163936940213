import {
  NgIf,
  NgClass,
  NgFor,
  NgForOf,
  NgStyle,
  JsonPipe,
} from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {} from '@app/cc-leader/services/campaign-form.service';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { IAgentAutocompleteResponse } from '@shared/model/atlas.api';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { ButtonComponent } from '@ui/button/button.component';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { VehicleTypeCardComponent } from '@ui/vehicle-type-card/vehicle-type-card.component';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AgentsAutocompleteComponent } from '@app/cc-leader/campaigns/agents/agents-autocomplete/agents-autocomplete.component';
import { AssignedAgentsListComponent } from '@app/cc-leader/campaigns/agents/assigned-agents-list/assigned-agents-list.component';
import {
  LeadCampaignFormService,
  LeadsForm,
} from '@app/cc-leader/services/lead-campaign-form.service';
import { LeadFileUploadComponent } from '@app/cc-leader/campaigns/add-campaign/add-campaign-leads/lead-file-upload/lead-file-upload.component';
import { CampaignPriorityDropdownComponent } from '@app/cc-leader/campaigns/add-campaign/add-campaign-leads/campaign-priority-dropdown/campaign-priority-dropdown.component';
import { ClientWithPackagesTableComponent } from '@app/features/orders/client-with-packages-table/client-with-packages-table.component';

@Component({
  selector: 'atlas-add-campaign-leads',
  standalone: true,
  imports: [
    DividerModule,
    NgIf,
    NgClass,
    NgFor,
    NgForOf,
    NgStyle,
    ScrollPanelModule,
    InlineWrapperComponent,
    VehicleTypeCardComponent,
    IconCheckboxComponent,
    InputTextComponent,
    InputDescriptionComponent,
    ClientWithPackagesTableComponent,
    ButtonComponent,
    RadioButtonModule,
    CalendarModule,
    JsonPipe,
    FormControlPipe,
    ReactiveFormsModule,
    CalendarComponent,
    AgentsAutocompleteComponent,
    AssignedAgentsListComponent,
    CampaignPriorityDropdownComponent,
    LeadFileUploadComponent,
  ],
  templateUrl: './add-campaign-leads.component.html',
  styleUrl: './add-campaign-leads.component.scss',
})
export class AddCampaignLeadsComponent implements OnInit, OnDestroy {
  form: LeadsForm;
  durationLimitCtrl: FormControl<number | null>;

  //additionalServices: string[] = [];
  selectedAgents: IAgentAutocompleteResponse[];

  constructor(public service: LeadCampaignFormService) {
    this.form = this.service.getForm();
    this.selectedAgents = [];
    this.durationLimitCtrl = new FormControl<number | null>(null);
  }

  ngOnInit(): void {}

  setNoLimitDuration() {
    this.service.setDurationLimit(null);
  }

  // handleRoleChange(event: any) {
  //   if (event?.value) {
  //     this.form.controls.priority?.patchValue(event.value.id);
  //     this.colorPlaceHolder = event.value.flagsColor;
  //   }
  // }
  //
  // servicesUpdated(serviceIds: string[]) {
  //   serviceIds.forEach((newServiceId: string) => {
  //     const alreadyExists = this.additionalServices.includes(newServiceId);
  //     if (!alreadyExists) {
  //       this.additionalServices.push(newServiceId);
  //     }
  //   });
  //
  //   this.form.controls.agentIds.clear();
  //   this.additionalServices.forEach((serviceId: string) => {
  //     this.form.controls.agentIds.push(new FormControl(serviceId));
  //   });
  //
  //   this.form.controls.agentIds.updateValueAndValidity();
  // }

  handleAgentSelected(selectedAgent: IAgentAutocompleteResponse) {
    this.selectedAgents.push(selectedAgent);
    this.service.addAgent(selectedAgent.id);
  }

  handleAgentUnselect(agentId: string) {
    const idx = this.selectedAgents.findIndex((x) => x.id === agentId);
    this.selectedAgents.splice(idx, 1);
    this.service.removeAgent(agentId);
  }

  ngOnDestroy(): void {
    this.selectedAgents = [];
  }
}
