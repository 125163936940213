import {
  NgIf,
  NgClass,
  NgFor,
  NgForOf,
  NgStyle,
  JsonPipe,
} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { ButtonComponent } from '@ui/button/button.component';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { VehicleTypeCardComponent } from '@ui/vehicle-type-card/vehicle-type-card.component';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {
  ContractCampaignFormService,
  ContractForm,
} from '@app/cc-leader/services/constract-campaign-form.service';
import { ClientWithPackagesTableComponent } from '@app/features/orders/client-with-packages-table/client-with-packages-table.component';

@Component({
  selector: 'atlas-add-campaign-contract',
  standalone: true,
  imports: [
    DividerModule,
    NgIf,
    NgClass,
    NgFor,
    NgForOf,
    NgStyle,
    ScrollPanelModule,
    InlineWrapperComponent,
    VehicleTypeCardComponent,
    IconCheckboxComponent,
    InputTextComponent,
    InputDescriptionComponent,
    ClientWithPackagesTableComponent,
    ButtonComponent,
    RadioButtonModule,
    CalendarModule,
    JsonPipe,
    FormControlPipe,
    ReactiveFormsModule,
    CalendarComponent,
  ],
  templateUrl: './add-campaign-contract.component.html',
  styleUrl: './add-campaign-contract.component.scss',
})
export class AddCampaignContractComponent implements OnInit {
  form: ContractForm;
  durationLimitCtrl: FormControl<number | null>;

  constructor(private _service: ContractCampaignFormService) {
    this.form = this._service.getForm();
    this.durationLimitCtrl = new FormControl<number | null>(null);
  }

  ngOnInit(): void {}

  setNoLimitDuration() {
    this._service.setDurationLimit(null);
  }

  handleSelectedContracts($event: string[]) {
    this._service.setContractIds($event);
  }
}
