import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  IClientWithPackagesItem,
  IClientWithPackagesTableResponse,
  ICommentItem,
} from '@shared/model/atlas.api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientPackageTableService {
  showPreviewSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showFilterSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private selectedCpsSubject = new BehaviorSubject<
    IClientWithPackagesTableResponse[]
  >([]);
  private _selectedPackages: BehaviorSubject<IClientWithPackagesItem[]> =
    new BehaviorSubject([]);
  private _clientId: string | null = null;
  private readonly _addForm: AddCommentForm;

  addSelectedPackage(model: IClientWithPackagesItem): void {
    this._selectedPackages.next([...this._selectedPackages.value, model]);
  }

  reset() {
    this._selectedPackages.next([]);
    this.selectedCpsSubject.next([]);
    this._addForm.reset();
  }

  initSelectedPackages(model: IClientWithPackagesItem[]): void {
    this._selectedPackages.next([...model]);
  }

  removeSelectedPackage(model: IClientWithPackagesItem): void {
    this._selectedPackages.next([
      ...this._selectedPackages.value.filter(
        (p) => p.clientPackageId !== model.clientPackageId,
      ),
    ]);
  }

  getSelectedPackages(): Observable<IClientWithPackagesItem[]> {
    return this._selectedPackages.asObservable();
  }

  getSelectedPackagesCount() {
    return this._selectedPackages.value.length;
  }

  constructor(private _fb: FormBuilder) {
    this._addForm = this._addComment();
  }

  getAddCommentForm() {
    if (!this._addForm) {
      this._addComment();
    }
    return this._addForm;
  }

  showFilterSidebar() {
    this.showFilterSidebar$.next(true);
  }

  hideFilterSidebar() {
    this.showFilterSidebar$.next(false);
  }

  get getShowFiltersSidebar() {
    return this.showFilterSidebar$.asObservable();
  }

  private _addComment() {
    return this._fb.group<ControlsOf<ICommentItem>>({
      text: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  showPreviewSidebar(itemId: string) {
    this.setSidebarId = itemId;
    this.showPreviewSidebar$.next(true);
  }

  hidePreviewSidebar() {
    this.showPreviewSidebar$.next(false);
  }

  set clientId(id: string) {
    this._clientId = id;
  }

  get clientId(): string | null {
    return this._clientId;
  }

  addClientPackage(client: IClientWithPackagesTableResponse): void {
    const currentSelection = this.selectedCpsSubject.value;
    if (!currentSelection.some((c) => c.id === client.id)) {
      this.selectedCpsSubject.next([...currentSelection, client]);
    }
  }

  // Remove client from the selection
  removeClientPackage(client: IClientWithPackagesTableResponse): void {
    const currentSelection = this.selectedCpsSubject.value.filter(
      (c) => c.id !== client.id,
    );
    this.selectedCpsSubject.next(currentSelection);
  }

  // Get current selection (can be useful for synchronous operations)
  getSelectedCps(): IClientWithPackagesTableResponse[] {
    return this.selectedCpsSubject.value;
  }

  getSelectedCpsCount(): number {
    return this.selectedCpsSubject.value.length;
  }

  resetSelectedCps() {
    this.selectedCpsSubject.next([]);
  }

  get getShowPreviewSidebar() {
    return this.showPreviewSidebar$.asObservable();
  }

  private _sidebarItemId: string | undefined;

  set setSidebarId(id: string) {
    this._sidebarItemId = id;
  }

  get getSidebarId(): string {
    return this._sidebarItemId;
  }
}

export declare type AddCommentForm = FormGroup<ControlsOf<ICommentItem>>;
