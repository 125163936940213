import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { IInternalPromoCode, PromoCodesClient } from '@shared/model/atlas.api';
import { FormControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-internal-promo-code-dropdown',
  standalone: true,
  imports: [DropdownComponent, NgClass, NgTemplateOutlet],
  templateUrl: './internal-promo-code-dropdown.component.html',
  styleUrl: './internal-promo-code-dropdown.component.scss',
})
export class InternalPromoCodeDropdownComponent implements OnInit, OnChanges {
  internalOptions: IInternalPromoCode[] = [];
  formCtrl: FormControl<string>;
  @Input() packageId: string | null = null;
  @Output() onChange = new EventEmitter<IInternalPromoCode>();

  constructor(private _client: PromoCodesClient) {
    this.formCtrl = new FormControl();
  }

  ngOnInit(): void {
    if (this.packageId) {
      this._load();
    }
  }

  private _load() {
    this._getInternals().then((res) => {
      this.internalOptions = res.result.responseList;
    });
  }

  private async _getInternals() {
    return await firstValueFrom(
      this._client.getInternalPromoCodes(this.packageId),
    );
  }

  handleChange($event: any) {
    this.onChange.emit($event.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['packageId'].currentValue &&
      changes['packageId'].previousValue !== changes['packageId'].currentValue
    ) {
      this._load();
    }
  }
}
