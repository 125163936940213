import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import {
  FormArray,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgFor } from '@angular/common';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {
  AdminService,
  InvatationForm,
} from '@app/administrator/services/admin-service';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  EmployeeRequestItem,
  EmployeeRoleItem,
  EmployeesClient,
  IEmployeeRequestItem,
  InviteNewEmployeesCommand,
  UsersClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { ControlsOf, markFormGroupTouched } from '@shared/forms/forms-common';
import { RoleMultiselectComponent } from '@app/features/multiselects/role-multiselect/role-multiselect.component';
import { strictEmailValidator } from '@app/features/partners/services/emailValidator';

@Component({
  selector: 'atlas-invite-employee',
  standalone: true,
  templateUrl: './invite-employee.component.html',
  styleUrl: './invite-employee.component.scss',
  imports: [
    ReactiveFormsModule,
    FormsModule,
    ButtonComponent,
    FormControlPipe,
    NgFor,
    InputMailComponent,
    RoleMultiselectComponent,
  ],
})
export class InviteEmployeeComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;
  roleDropdown: string;

  employees: EmployeeRequestItem[] = [];
  roles: EmployeeRoleItem[] = [];
  form: InvatationForm;

  emailExists: boolean = false;

  @ViewChild('roleMultiselect')
  roleMultiselectComponent: RoleMultiselectComponent;

  constructor(
    private _dialogRef: DialogRef,
    private _dialogConfig: DialogConfig,
    private _adminService: AdminService,
    private _employeeClient: EmployeesClient,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _formBuilder: FormBuilder,
    private _userClient: UsersClient,
  ) {
    this.form = this._adminService.getInviteEmployeeForm();
  }

  ngOnInit(): void {
    this._getRoles();
    this._initForm();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  private checkEmailExistence(): Promise<any> {
    const emailValue = this.form.controls.email.value;
    return this._loadExistingEmail(emailValue).then((res) => {
      this.emailExists = res.result;
    });
  }

  private async _loadExistingEmail(email: string) {
    return await firstValueFrom(this._userClient.doesEmailExist(email));
  }

  private _initForm() {
    this.form = this._formBuilder.group<ControlsOf<IEmployeeRequestItem>>({
      email: this._formBuilder.control('', [
        Validators.required,
        Validators.email,
        strictEmailValidator(),
      ]),
      roleIds: this._formBuilder.array([], Validators.required),
    });
  }

  addNewEmployee() {
    this.checkEmailExistence().then(() => {
      if (this.emailExists) {
        this._alertService.addWarnMsg(
          'Vec postoji zaposleni sa unetom email adresom',
        );
        return;
      } else {
        this.employees.push(
          new EmployeeRequestItem({
            email: this.form.value.email,
            roleIds: this.form.value.roleIds,
          }),
        );
        this.form.reset();
        this._resetForm();
      }
    });

    if (this.form.invalid) {
      this._alertService.addWarnMsg('Potrebno je uneti sva polja!');
      markFormGroupTouched(this.form);
      return;
    }
  }

  private _resetForm() {
    (this.form.get('roleIds') as FormArray).clear();
    this.form.get('roleIds').setValue([]);
    this.roleMultiselectComponent.resetRolesCtrl();
  }

  getEmployeeRoleControl(employee: EmployeeRequestItem): FormArray {
    const index = this.employees.indexOf(employee);
    return this._formBuilder.array(employee.roleIds, Validators.required);
  }

  removeEmployee(email: any) {
    const idx = this.employees.findIndex((x) => x.email == email);
    this.employees.splice(idx, 1);
  }

  private _handleSave() {
    if (this.employees.length === 0) {
      markFormGroupTouched(this.form);
      return this._alertService.addWarnMsg(
        'Mora se uneti barem jedan zaposleni',
      );
    }

    this._loaderService.setLoadingText = 'Poziv se šalje zaposlenima...';
    this._loaderService.setShowLoader = true;
    this._dialogConfig.styleClass = 'display: hidden';

    this._subs.add(
      this._employeeClient
        .inviteNewEmployees(
          new InviteNewEmployeesCommand({
            employeeRequestList: this.employees,
          }),
        )
        .subscribe((res) => {
          this._dialogRef.close();
          this._loaderService.reset();
          this._alertService.addSuccessMsg(res.result);
        }),
    );
  }

  private _getRoles() {
    this._subs.add(
      this._employeeClient.getEmployeeRoles().subscribe((x) => {
        this.roles = x.result.roles;
      }),
    );
  }

  exit() {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this.form?.reset();
    this._subs.unsubscribe();
  }
}
