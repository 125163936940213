import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { CampaignsClient, IPaginationResponseOfLeadCallResponse } from '@shared/model/atlas.api';
import { SelectMode, TableConfig } from '@ui/table/table-common';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { TableComponent } from '@ui/table/table.component';
import { TableLazyLoadEvent, TableService } from 'primeng/table';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-scheduled-calls-table',
  standalone: true,
  imports: [PageComponent, TableComponent],
  templateUrl: './scheduled-calls-table.component.html',
  styleUrl: './scheduled-calls-table.component.scss'
})
export class ScheduledCallsTableComponent implements OnInit {

  protected tableConfig: TableConfig;
  public paginatedData: IPaginationResponseOfLeadCallResponse;

  constructor(private _camapaignClient: CampaignsClient,
    private _tableService: TableServiceLocal
  ) {
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }

  ngOnInit(): void {
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
      this.tableConfig = new TableConfig({
        emptyTableInfo: {
          header: 'Bez zakazanih poziva',
          description: 'Ovde će biti prikazani zakazani pozivi',
          btnLabel: 'Dodaj poziv',
          action: () => alert('ToDo')
        },
        isLazy: true,
        lazyCallbackFunction: (event: TableLazyLoadEvent) => {
          if (event.first === 0) {
            this.paginatedData.currentPage = 1;
          } else {
            this.paginatedData.currentPage = event.first / event.rows + 1;
          }
          this.paginatedData.pageSize = event.rows!;
          this.loadTable();
        },
        columns: [
          {
            header: 'Ugovor',
            field: 'lead',
            subField:'client',
            type: 'text',
          },
          {
            header: 'Vreme',
            field: 'callAt',
            type: 'text',
          },
          {
            header: 'Kampanja',
            field: 'campaignName',
            type: 'badge',
            badgeClass: 'is-active-',
          },
          {
            header: 'Agent',
            field: 'lead',
            subField: 'agent',
            type: 'text',
          },
        ],
      });
    }



  loadTable() {
    this._tableService.setIsLoading();
    this._loadCalls().then((res) => {
      this.paginatedData = res.result;
      console.log(this.paginatedData)
      this._tableService.resetIsLoading();
    });
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private async _loadCalls() {
      return await firstValueFrom(
        this._camapaignClient.getScheduledCalls(),
      );
    }

}
