import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vehicleTypeIcon',
  standalone: true,
})
export class VehicleTypeIconPipe implements PipeTransform {
  constructor() {}

  transform(value: string | null | undefined, ...args: any[]): any {
    if (value.includes('Mot')) {
      return 'assets/images/moto-type.svg';
    }
    if (value.includes('Kombi')) {
      return 'assets/images/truck-type.svg';
    }
    return 'assets/images/user-vehicle.svg';
  }
}
