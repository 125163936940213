<atlas-dropdown
  #dropdownElement
  [options]="brandNames"
  [formCtrl]="selectedBrandCtrl"
  [ctrlPlaceholder]="brandNameCtrl.value || 'Odaberite marku'"
  ctrlPlaceholder="Odaberite marku"
  [isEditable]="true"
  [canClear]="true"
  ctrlStyleClass="w-full"
  [pressEnterOnBlur]="true"
  ctrlLabel="Marka"
>
</atlas-dropdown>
