<atlas-input-search
  (onChange)="onSelectChange($event)"
  (onSearch)="handleSearch($event)"
  [suggestions]="agentSuggestions"
  ctrlPlaceholder="Ime ili prezime agenta"
  class="search-input"
  [isDisabled]="false"
  [shouldReset]="resetInput"
  [multiple]="false"
  fieldName="name"
>
  <!-- Template for Dropdown Items -->
  <ng-template #item let-agent>
    <div class="flex items-center space-x-2 p-2 gap-3">
      <img
        [ngSrc]="
          agent.imageUrl ? agent.imageUrl : 'assets/images/client-icon.svg'
        "
        alt="{{ agent.name }}"
        class="rounded-full"
        height="34"
        width="34"
      />
      <div class="text-wrap">
        <span class="font-semibold">{{ agent.name }}</span>
        <span class="text-sm text-gray-500">{{ agent.email }}</span>
      </div>
    </div>
  </ng-template>

  <!-- Template for Selected Items -->
  <ng-template #selectedItem let-agent>
    <div class="flex items-center space-x-1 px-2 py-1 bg-gray-200 rounded-full gap-3">
      <img
        [ngSrc]="
          agent.imageUrl ? agent.imageUrl : 'assets/images/client-icon.svg'
        "
        alt="{{ agent.name }}"
        class="rounded-full"
        height="20"
        width="20"
      />
      <span>{{ agent.name }}</span>
    </div>
  </ng-template>
</atlas-input-search>
