<atlas-page
    [pageTitle]="'Izveštaji'"
    [pageDescription]="'Pregled i upravljanje izveštajima'"
>
<div class="grid gap-4 pt-2 pl-2">
    @for(report of reports; let idx = $index; track report.name)
{
    <atlas-reports-card
        [name]="report.name"
        [icon]="report.icon"
        [desc]="report.desc"
        [route]="report.route"
    ></atlas-reports-card>
}
</div>
</atlas-page>