<div class="flex">
  <div>
    <atlas-input-search
      (onChange)="onSelectChange($event)"
      (onSearch)="handleSearch($event)"
      [suggestions]="packages"
      ctrlPlaceholder="Kucaj uslugu"
      class="search-input"
      [isDisabled]="false"
      [shouldReset]="resetInput"
    ></atlas-input-search>
  </div>

  <div class="ml-2">
    <atlas-button
      (btnClicked)="handleAddService()"
      class="action-button"
      [btnStyle]="ButtonStyle.BASIC"
      [btnSeverity]="ButtonSeverity.SECONDARY"
      btnLabel="Dodaj"
    ></atlas-button>
  </div>
</div>


<!-- [isBtnDisabled]="!additionalServiceItem || isBtnDisabled" -->
