<atlas-scrollable-page-with-steps
  submitBtnLabel="Dodaj klijenta"
  [stepItems]="menuSteps"
  [shouldDisplayBack]="shouldDisplayBack"
  [shouldDisplayFinish]="shouldDisplayFinish"
  (onCancelAction)="handleCancel()"
  (onBackAction)="handleBack()"
  (onNextAction)="handleNext()"
  (onSubmitAction)="handleNext()"
>
  <ng-template #content>
    <ng-container *ngIf="!showLoader">
      <router-outlet></router-outlet>
    </ng-container>
  </ng-template>
</atlas-scrollable-page-with-steps>

<pre>
  {{ orderForm.value | json }}
</pre>
