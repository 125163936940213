import { JsonPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { Subscription } from 'rxjs';
import { ClientWithPackageTableFilterService } from '../services/client-with-package-table-filter.service';
import { AtlasDatePipe } from '@shared/pipes/date.pipe';
import { typedKeys } from '@shared/functions/functions';

@Component({
  selector: 'atlas-active-filters-client',
  standalone: true,
  imports: [ChipModule, JsonPipe],
  templateUrl: './active-filters-client.component.html',
  styleUrl: './active-filters-client.component.scss',
  providers: [AtlasDatePipe],
})
export class ActiveFiltersClientComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  activeFilters: { key: string; label: string }[];

  constructor(
    private service: ClientWithPackageTableFilterService,
    private _datePipe: AtlasDatePipe,
  ) {
    this._subs = new Subscription();
    this.activeFilters = [];
  }

  ngOnInit(): void {
    this._subs.add(
      this.service.getApply.subscribe((filters) => {
        this.activeFilters = [];
        const formValue = this.service.getForm().value;
  
        typedKeys(formValue).forEach((key) => {
          const value = formValue[key];
          
          // Skip if value is falsy, an empty array, or other invalid conditions.
          if (
            value == null || // Skip null or undefined values
            (Array.isArray(value) && value.length === 0)
          ) {
            return;
          }
  
          // Add the active filter
          this.activeFilters.push({
            key: key,
            label: `${this.getLabel(key)}: ${this.getValue(key)}`,
          });
        });
      }),
    );

    this._subs.add(
      this.service.getReset.subscribe((filters) => {
        this.activeFilters = [];
      }),
    );
  }

  handleChipRemove(idx: number) {
    this.service.clearFilter(this.activeFilters[idx].key);
    if (this.activeFilters[idx].key === 'isActive') {
      this.service.updateIsActive(null);  
    }
    this.service.reloadTable();
  }

  private getLabel(propertyName: string): string {
    const propSwitch = typedKeys(this.service.getForm().value).find(
      (prop) => prop === propertyName,
    );
    switch (propertyName) {
      case 'isActive':
        return 'Aktivan';
      case 'start':
        return 'Počinje';
      case 'startFrom':
        return 'Počinje od';
      case 'createdOn':
        return 'Kreiran';
      case 'createdOnFrom':
        return 'Kreiran od';
      case 'endFrom':
        return 'Datum završetka od';
      case 'endTo':
        return 'Datum završetka do';
      case 'sources':
        return 'Izvori';
      case 'mediums':
        return 'Medijumi';
      default:
        return 'Filter';
    }
  }

  private getValue(propertyName: string): string {
    const propSwitch = typedKeys(this.service.getForm().value).find(
      (prop) => prop === propertyName,
    );
    switch (propSwitch) {
      case 'endFrom':
      case 'endTo':
      case 'start':
      case 'startFrom':
      case 'createdOn':
      case 'createdOnFrom':
        return this._datePipe.transform(
          this.service.getForm().value[propertyName],
        );
      case 'sources':
        const sourceNames = this.service
          .getForm()
          .value[propertyName].map((sourceId: string) => {
            return this.service.sources.find((m) => m.id === sourceId).name;
          });
        return sourceNames.join(', ');
      case 'mediums':
        const mediumNames = this.service
          .getForm()
          .value[propertyName].map((mediumId: string) => {
            return this.service.mediums.find((m) => m.id === mediumId)
              .mediumName;
          });
        return mediumNames.join(', ');
        case 'isActive':
          return this.service.getForm().value[propertyName] ? 'Aktivan' : 'Neaktivan';
      default:
        return this.service.getForm().value[propertyName];
    }
  }
  
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
