import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PageComponent } from '@shared/components/page/page.component';
import { ButtonComponent } from '@ui/button/button.component';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { ActionMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import {
  DriversClient,
  GetPartnerInfoResponse,
  IPaginationResponseOfPartnerVehiclesItem,
  IVehicleSidebarResponse,
  PartnersClient,
  RemovePartnerDriverCommand,
} from '@shared/model/atlas.api';
import { NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { LocationService } from '@shared/services/location.service';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { ActivatedRoute } from '@angular/router';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { TableLazyLoadEvent } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { ConfirmationService } from 'primeng/api';
import { VehicleSidebarComponent } from '@app/features/partners/vehicle-sidebar/vehicle-sidebar.component';
import {
  DialogResponse,
  VehicleDialogService,
} from '@app/features/partners/services/vehilce-dialog.service';
import { PartnerFormService } from '@app/features/partners/services/partner-form.service';
import { PartnerVehicleFormComponent } from '@app/features/partners/partner-vehicle-form/partner-vehicle-form.component';
import { PartnerDriverFormComponent } from '@app/features/partners/partner-driver-form/partner-driver-form.component';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'atlas-partner-info-page',
  standalone: true,
  imports: [
    PageComponent,
    TableComponent,
    PageInfoCardComponent,
    TabViewModule,
    InputTextModule,
    FormsModule,
    ButtonComponent,
    NgFor,
    NgIf,
    ScrollPanelModule,
    VehicleSidebarComponent,
    NgOptimizedImage,
    SkeletonModule,
  ],
  templateUrl: './partner-info-page.component.html',
  styleUrl: './partner-info-page.component.scss',
})
export class PartnerInfoPageComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  skeletonItems: any[] = [];
  vehiclePagData: IPaginationResponseOfPartnerVehiclesItem;
  driversPaginatedData: any;
  currentPartnerId: string;
  vehicleTableConfig: TableConfig;
  driverTableConfig: TableConfig;
  partnerData: GetPartnerInfoResponse;
  shouldResetId = true;
  driversCount: number;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.LARGE);

  constructor(
    private _formService: PartnerFormService,
    private _partnerClient: PartnersClient,
    private _dialogService: DialogService,
    private _locationService: LocationService,
    private _alertService: AlertService,
    private vehicleDialogService: VehicleDialogService,
    private service: ClientPackageTableService,
    private _route: ActivatedRoute,
    private _tableService: TableServiceLocal,
    private _userService: UserService,
    private _confirmationService: ConfirmationService,
    private _driverClient: DriversClient,
  ) {
    this.vehicleTableConfig = new TableConfig({
      columns: [],
    });
    this.vehiclePagData = {
      data: [],
      currentPage: 0,
    };

    this.driverTableConfig = new TableConfig({
      columns: [],
    });
    this.driversPaginatedData = {
      data: [],
      currentPage: 0,
    };
    this.skeletonItems = Array.from({ length: 3 }).map((_, i) => i);
  }

  ngOnInit(): void {
    this.setVehicleTableConfig().then();
    this.setDriverTableConfig().then();
    this._subs.add(
      this._route.paramMap.subscribe((params) => {
        const partnerId = params.get('id');
        if (partnerId) {
          this.currentPartnerId = partnerId;
          this._tableService.setIsLoading();
          this.loadPartnerInfo().then(() => {
            this.loadVehicleTableData().then(() => {
              this.loadDriverTableData().then(() => {
                this._tableService.resetIsLoading();
              });
            });
          });
        } else {
          this._locationService.routeToPartnerTable(
            this._userService.getRoleRoutePrefix,
          );
        }
      }),
    );
  }

  async setVehicleTableConfig(): Promise<void> {
    this.vehicleTableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez vozila',
        description: 'Ovde će biti prikazana tabela vozila',
        btnLabel: 'Dodaj vozilo',
        action: () => this.handleAddVehicle(),
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.vehiclePagData.currentPage = 1;
        } else {
          this.vehiclePagData.currentPage = event.first! / event.rows + 1;
        }
        this.vehiclePagData.pageSize = event.rows;
        this.loadVehicleTableData();
      },
      columns: [
        {
          header: 'Vozilo',
          field: 'model',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
        {
          header: 'Vozač',
          field: 'driverName',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'driverNumber',
              type: 'text',
              styleClass: 'font-gray-600',
            },
          ],
        },
        {
          header: 'Reg. oznaka',
          field: 'licencePlate',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
        {
          header: 'Cena',
          field: 'pricePerKm',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Pogledaj detaljnije',
          icon: 'pi pi-info-circle',
          callback: (rowIdx: number) => {
            const rowData: IVehicleSidebarResponse =
              this._getVehicleItem(rowIdx);
            this.service.showPreviewSidebar(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Izmeni podatke vozila',
          icon: 'pi pi-pencil',
          callback: (rowIdx: number) => {
            const rowData: IVehicleSidebarResponse =
              this._getVehicleItem(rowIdx);
            const dialogRef = this.vehicleDialogService.show(
              this.currentPartnerId,
              rowData.id,
              true,
            );
            this._subs.add(
              dialogRef.onClose.subscribe((res: DialogResponse) => {
                if (res) {
                  const idx = this.vehiclePagData.data.findIndex(
                    (x) => x.id == res.vehicleItem.id,
                  );
                  this.vehiclePagData.data.at(idx).licencePlate =
                    res.vehicleItem.licencePlate;
                  this.vehiclePagData.data.at(idx).pricePerKm =
                    res.vehicleItem.pricePerKm;
                  this.vehiclePagData.data.at(idx).model =
                    res.vehicleItem.model;
                }
              }),
            );
          },
          shouldDisplayByCondition: () => true,
        },
      ],
    });
  }

  async setDriverTableConfig(): Promise<void> {
    this.driverTableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez vozača',
        description: 'Ovde će biti prikazana tabela vozača',
        btnLabel: 'Dodaj vozača',
        action: () => this.handleAddDriver(),
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.driversPaginatedData.currentPage = 1;
        } else {
          this.driversPaginatedData.currentPage = event.first! / event.rows + 1;
        }
        this.driversPaginatedData.pageSize = event.rows;
        this.loadDriverTableData();
      },
      columns: [
        {
          header: 'Ime',
          field: 'name',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
        {
          header: 'Telefon(i)',
          field: 'phones',
          type: 'text',
        },
        {
          header: 'Email',
          field: 'email',
          type: 'text',
        },
        {
          header: 'Vozilo',
          field: 'vehicle',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          icon: 'pi pi-pencil',
          title: 'Izmeni podatke vozača',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.handleEditDriver(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
        {
          mode: ActionMode.SINGLE,
          icon: 'pi pi-trash',
          title: 'Obriši vozača',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.handleDeleteDriver(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
      ],
    });
  }

  editPartner(id: string) {
    this.shouldResetId = false;
    this._locationService.routeToEditPartnerForm(
      id,
      this._userService.getRoleRoutePrefix,
    );
  }

  handleAddVehicle() {
    this.dialogConfig.header = 'Dodavanje vozila';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj vozilo',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    this.dialogConfig.data = {
      fromInfoPage: true,
      partnerId: this.currentPartnerId,
    };

    this.dialogConfig.setDialogSize = DialogSize.LARGE;

    this.openDialog(PartnerVehicleFormComponent);
  }

  handleAddDriver() {
    this.dialogConfig.data = null;
    this.dialogConfig.header = 'Dodela vozača';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodeli vozača',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = true;
    this.dialogConfig.closable = true;
    this.dialogConfig.data = {
      partnerId: this.currentPartnerId,
    };
    this.dialogConfig.setDialogSize = DialogSize.SMALL;

    this.openDialog(PartnerDriverFormComponent);
  }

  handleEditDriver(driverId: string) {
    (this.dialogConfig.header = 'Izmena vozača'),
      (this.dialogConfig.customSubmitButton = {
        label: 'Izmeni vozača',
        icon: '',
      }),
      (this.dialogConfig.customCancelButton = {
        label: 'Otkaži',
        icon: '',
      });
    this.dialogConfig.maximisable = true;
    this.dialogConfig.closable = true;
    this.dialogConfig.setDialogSize = DialogSize.SMALL;
    this.dialogConfig.data = {
      driverId: driverId,
      partnerId: this.currentPartnerId,
      isEditMode: true,
    };

    this.openDialog(PartnerDriverFormComponent);
  }

  handleDeleteDriver(driverId: string) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da obrišete vozača?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._driverClient
            .delete(
              new RemovePartnerDriverCommand({
                driverId: driverId,
              }),
            )
            .subscribe((res) => {
              this._alertService.addSuccessMsg(res.result);
              this.loadDriverTableData().then();
            }),
        );
      },
    });
  }

  openDialog(form: Type<any>): void {
    const dialogRef: DialogRef = this._dialogService.open(
      form,
      this.dialogConfig,
    );

    dialogRef.onClose.subscribe(() => {
      this.loadVehicleTableData().then();
      this.loadDriverTableData().then();
    });
  }

  private async loadVehicleTableData() {
    this._subs.add(
      this._partnerClient
        .getPartnerVehiclesTable(this.currentPartnerId)
        .subscribe((res) => {
          this.vehiclePagData = res.result;
        }),
    );
  }

  private async loadDriverTableData() {
    this._subs.add(
      this._partnerClient
        .getPartnerDriversTable(this.currentPartnerId)
        .subscribe((res) => {
          this.driversPaginatedData = res.result;
          this.driversCount = res.result.data.length;
        }),
    );
  }

  private async loadPartnerInfo() {
    this._subs.add(
      this._partnerClient
        .getPartnerInfo(this.currentPartnerId)
        .subscribe((res) => {
          this.partnerData = res.result.value;
        }),
    );
  }

  private _getPaginatedItem(index: number) {
    return this.driversPaginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getVehicleItem(index: number) {
    return this.vehiclePagData.data[this._getVehicleIndex(index)];
  }

  private _getVehicleIndex(index: number): number {
    if (this.vehiclePagData.currentPage > 1) {
      const idx =
        (index - this.vehiclePagData.pageSize) % this.vehiclePagData.pageSize;
      return (
        (index - this.vehiclePagData.pageSize) % this.vehiclePagData.pageSize
      );
    }
    return index;
  }

  private _getPaginatedIndex(index: number): number {
    if (this.driversPaginatedData.currentPage > 1) {
      const idx =
        (index - this.driversPaginatedData.pageSize) %
        this.driversPaginatedData.pageSize;
      return (
        (index - this.driversPaginatedData.pageSize) %
        this.driversPaginatedData.pageSize
      );
    }
    return index;
  }

  ngOnDestroy(): void {
    if (this.shouldResetId) {
      this.currentPartnerId = undefined;
    }
    this._formService.reset();
    this._subs.unsubscribe();
  }
}
