import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, Subscription } from 'rxjs';
import { ControlsOf } from '@shared/forms/forms-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DriversClient,
  IRegisterClientPersonCommand,
  PhoneItem,
  TokensClient,
  VerifyDriverEmailCommand,
} from '@shared/model/atlas.api';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { RegistrationFormService } from './register-form.service';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { HttpClient } from '@angular/common/http';
import { InputPasswordComponent } from '@ui/input-password/input-password.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '@shared/services/location.service';
import { WhiteLogoComponent } from '@ui/white-logo/white-logo.component';
import { LoaderService } from '@shared/components/loader/loader.service';
import { NgFor, NgIf } from '@angular/common';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { Guid } from 'guid-typescript';
import { ContactPhoneListComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/contact-phone-list/contact-phone-list.component';
import { ContactFormService } from '@app/features/partners/services/contact-form.service';

@Component({
  selector: 'atlas-register',
  standalone: true,
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  providers: [TokensClient, HttpClient],
  imports: [
    InputTextComponent,
    ButtonComponent,
    FormControlPipe,
    InputPasswordComponent,
    InputPhoneComponent,
    WhiteLogoComponent,
    NgIf,
    NgFor,
    ButtonComponent,
    ContactPhoneListComponent,
  ],
})
export class RegisterComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  roleIds: string[] = [];
  isDriver: boolean;
  driverName: string;
  driverLastName: string;
  form!: FormGroup<ControlsOf<IRegisterClientPersonCommand>>;
  phonesForm: any;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private _route: ActivatedRoute,
    public _fs: RegistrationFormService,
    private _router: Router,
    private _locationService: LocationService,
    private _loaderService: LoaderService,
    private _driverClient: DriversClient,
    private _fb: FormBuilder,
    private _phonesService: ContactFormService,
  ) {
    this.form = _fs.getForm();
    this.phonesForm = _fs.initDriverContacts();
  }

  ngOnInit(): void {
    this.phonesForm.controls.phones.push(this._fs.getPhoneForm());
    this._route.queryParams.subscribe((x) => {
      if (x) {
        this.isDriver = x['isDriver'];
        if (this.isDriver) {
          const driverEmail = x['email'];

          this.loadDriverInfo(driverEmail);
        }
        this.form.controls.email.patchValue(x['email']);
        this.roleIds = x['roleIds'].split(',');
      }
    });

    this.form.patchValue({
      verificationCode: 'placeholder',
    });
  }

  updatePhoneItems(event: any) {
    this.phonesForm.controls.phones.patchValue(event);
  }

  createPhoneItem(phone: PhoneItem): FormGroup {
    return this._fb.group({
      id: [phone.id, [Validators.required]],
      label: [phone.label, [Validators.required, Validators.maxLength(32)]],
    });
  }

  handleAddContact() {
    this.phonesForm.controls.phones.push(
      this.createPhoneItem(new PhoneItem({ id: Guid.EMPTY, label: '' })),
    );
  }

  private loadDriverInfo(email: string) {
    this._subs.add(
      this._driverClient.getRegisterInfo(email).subscribe((res) => {
        this.form.controls.firstName.patchValue(res.result.value.name);
        this.form.controls.lastName.patchValue(res.result.value.lastName);
        this.form.controls.email.patchValue(res.result.value.email);
        this._patchPhones(res);
      }),
    );
  }

  private _patchPhones(res: any) {
    const phones = res.result.value.phones || [];

    const phonesFormArray = this._phonesService.getPhonesArray(phones);
    this._phonesService.setPhones(phonesFormArray);
  }

  handleRegister() {
    const phones = this._phonesService.getPhones();

    this._fs.getFormValidationErrors();
    this._phonesService.setSubmitted(true);

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this._loaderService.setLoadingText = 'Registrovanje korisnika...';
    this._loaderService.setShowLoader = true;

    this._subs.add(
      this._driverClient
        .verifyDriverEmail(
          new VerifyDriverEmailCommand({
            email: this.form.value.email!,
          }),
        )
        .pipe(
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe({
          next: () => {
            this._router
              .navigateByUrl('/authentication/register/validation-code', {
                state: {
                  firstName: this.form.value.firstName,
                  lastName: this.form.value.lastName,
                  password: this.form.value.password,
                  email: this.form.value.email,
                  roleIds: this.roleIds,
                  isDriver: this.isDriver,
                  driverContacts: phones.value,
                },
              })
              .then();
          },
        }),
    );
  }

  handleBackToLogin() {
    this._locationService.routeToLogin();
  }

  ngOnDestroy(): void {
    this._phonesService.setSubmitted(false);
    this._subs.unsubscribe();
  }
}
