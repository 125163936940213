<div class="center-div p-4">
  <atlas-white-logo></atlas-white-logo>
  <div class="main-div">
    <h1 class="text-center mt-8">Registracija</h1>
    <div class="flex justify-content-center align-items-center">
      <div>
        <div class="mt-4 flex gap-3">
          <div>
            <atlas-input-text
              [formCtrl]="form.controls.firstName | formControl"
              ctrlLabel="Ime"
              formCtrlId="firstName"
              ctrlPlaceholder="Ime"
              [isDisabled]="isDriver"
            ></atlas-input-text>
          </div>
          <div>
            <atlas-input-text
              [formCtrl]="form.controls.lastName | formControl"
              ctrlLabel="Prezime"
              formCtrlId="lastName"
              ctrlPlaceholder="Prezime"
              [isDisabled]="isDriver"
            ></atlas-input-text>
          </div>
        </div>
        <div class="flex flex-column">
          <atlas-input-text
            [formCtrl]="form.controls.email | formControl"
            ctrlLabel="E-mail"
            formCtrlId="email"
            ctrlPlaceholder="E-mail"
            [isDisabled]="isDriver"
          ></atlas-input-text>
          <div>
            <atlas-contact-phone-list [showLabel]="true"></atlas-contact-phone-list>
          </div>
          <atlas-input-password
            [formCtrl]="form.controls.password | formControl"
            ctrlLabel="Lozinka"
            formCtrlId="password"
            ctrlPlaceholder="Lozinka"
          ></atlas-input-password>
          <atlas-input-password
            [formCtrl]="form.controls.confirmedPassword | formControl"
            ctrlLabel="Ponovite lozinku"
            formCtrlId="confirmedPassword"
            ctrlPlaceholder="Ponovite lozinku"
          ></atlas-input-password>
        </div>
        <div class="flex flex-column mt-4">
          <atlas-button
            (btnClicked)="handleRegister()"
            [btnStyle]="ButtonStyle.BASIC"
            btnClass="w-full"
            btnLabel="Napravi nalog"
          ></atlas-button>
        </div>
        <div class="mt-4 flex justify-content-center align-items-center">
          <i class="pi pi-arrow-left" (click)="handleBackToLogin()"></i>
          <atlas-button
            (btnClicked)="handleBackToLogin()"
            btnClass="p-0"
            btnLabel="Nazad na logovanje"
          ></atlas-button>
        </div>
      </div>
    </div>
  </div>
</div>
