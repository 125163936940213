import { JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import {
  CampaignLeadStatusDropdownItem,
  CampaignsClient,
} from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-leads-status-dropdown',
  standalone: true,
  imports: [DropdownComponent, JsonPipe, NgClass, NgTemplateOutlet],
  templateUrl: './leads-status-dropdown.component.html',
  styleUrl: './leads-status-dropdown.component.scss',
})
export class LeadsStatusDropdownComponent implements OnInit, OnChanges {
  statusOptions: CampaignLeadStatusDropdownItem[] = [];
  @Input() currentStatus: number;
  statusCtrl = new FormControl<string | null>(null);
  private areStatusOptionsLoaded: boolean = false;
  placeholder: string = 'Izaberi status';

  constructor(
    private _client: CampaignsClient,
    private service: CampaignFormService,
  ) {}

  ngOnInit(): void {
    this._load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentStatus'] && this.currentStatus !== undefined) {
      if (this.areStatusOptionsLoaded) {
        this.updateSelectedStatus(this.currentStatus);
      }
    }
  }

  getStatusColor(status: number): string {
    return this.service.getColor(status);
  }

  private updateSelectedStatus(status: number) {
    const selectedOption = this.statusOptions.find(
      (option) => option.status === status,
    );
    if (selectedOption) {
      this.statusCtrl.setValue(selectedOption.label);
      this.placeholder = selectedOption.label;
    }
  }

  private _load() {
    this._getStatuses().then((res) => {
      this.statusOptions = res.result.items;
      this.areStatusOptionsLoaded = true;

      if (this.currentStatus !== undefined) {
        this.updateSelectedStatus(this.currentStatus);
      }
    });
  }

  private async _getStatuses() {
    return await firstValueFrom(this._client.getCampaignLeadStatusDropdown());
  }
}
