import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IPaginationResponseOfClientWithPackagesTableResponse } from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import { ButtonStyle, ButtonSeverity } from '@ui/button/button.component';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { TableConfig } from '@ui/table/table-common';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ClientWithPackagesTableComponent } from '@app/features/orders/client-with-packages-table/client-with-packages-table.component';
import { PageComponent } from '@shared/components/page/page.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'atlas-client-with-packages-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    ClientWithPackagesTableComponent,
    ClientWithPackagesTableComponent,
  ],
  templateUrl: './client-with-packages-page.component.html',
  styleUrl: './client-with-packages-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientWithPackagesPageComponent implements OnInit, OnDestroy {
  paginatedData: IPaginationResponseOfClientWithPackagesTableResponse;
  private _subs: Subscription = new Subscription();
  protected tableConfig: TableConfig;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  @Input() showSearchBar: boolean = true;
  @Input() showHeader: boolean = false;
  @Input() pageTitle: string = 'Klijenti';
  @Input() pageDescription: string = '';
  @Input() actionLabel: string = '';

  @Output() clientSelected = new EventEmitter<any>();

  constructor(
    private _locationService: LocationService,
    private acivatedRoute: ActivatedRoute,
  ) {
    this.acivatedRoute.data.subscribe((data) => {
      console.log(data);
    });
  }

  ngOnInit(): void {}

  handleCreateClient() {
    this._locationService.routeToCreateClientForm().then();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
