import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import {
  ActionPriceForm,
  PackageForm,
  PackageService,
} from '@app/administrator/services/package-service';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-package-action-form',
  standalone: true,
  imports: [
    InputTextComponent,
    InputAddonComponent,
    FormsModule,
    FormControlPipe,
  ],
  templateUrl: './package-action-form.component.html',
  styleUrl: './package-action-form.component.scss',
})
export class PackageActionFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  packageForm: PackageForm;
  packageActionForm: ActionPriceForm;

  constructor(
    private _packageService: PackageService,
    private _dialogRef: DialogRef,
  ) {
    this.packageActionForm = this._packageService.initActionPriceForm();
    this.packageForm = this._packageService.packageForm;
  }

  ngOnInit(): void {
    this.packageActionForm.reset();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe((res) => {
        this.handleAddAction();
      }),
    );
  }

  handleAddAction() {
    //this.packageActionForm.controls.isPublic.patchValue(false); // Izmeniti nakon sto definisemo
    // this.packageForm.controls.packagePrices.push(
    //   this.packageActionForm as unknown as FormGroup<
    //     ControlsOf<PackagePriceItem>
    //   >,
    // );
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
