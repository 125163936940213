import { CommonModule, JsonPipe, NgClass, NgFor, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OrderForm, OrderFormService } from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { PackageCardListComponent } from '@app/features/packages/package-card-list/package-card-list.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import { ControlsOf } from '@shared/forms/forms-common';
import { ClientClient, DeliveryType, IPackageCardItem, PaymentType } from '@shared/model/atlas.api';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { LocationService } from '@shared/services/location.service';
import { ButtonComponent } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { SelectItem } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonChangeEvent, SelectButtonModule } from 'primeng/selectbutton';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-buy-package-vehicle',
  standalone: true,
  imports: [ScrollablePageComponent,
    DividerModule,
    NgIf,
    NgOptimizedImage,
    ButtonComponent,
    JsonPipe,
    NgForOf,
    NgFor,
    NgTemplateOutlet,
    NgClass,
    RadioButtonModule,
    CommonModule,
    ReactiveFormsModule,
    CheckboxModule,
     DropdownComponent,
    FormsModule,
    FormControlPipe,
    SelectButtonModule,
    PackageCardListComponent,
  ],
  templateUrl: './buy-package-vehicle.component.html',
  styleUrl: './buy-package-vehicle.component.scss'
})
export class BuyPackageVehicleComponent implements OnInit, OnDestroy  {

  packageData: any;
  clientPackageId: string;
  orderForm: OrderForm;
  packagesSum: number = 0;
  packages: FormArray<FormGroup<ControlsOf<IPackageCardItem>>>;

  stateOptions: SelectItem[] = [
      {
        label: 'Završi kupovinu preko mobilne aplikacije',
        value: 'draft',
        title:
          'Klijent treba da dovriši plaćanje online, kroz mobilnu aplikaciju.',
      },
      {
        label: 'Završi kupovinu na standardan način',
        value: 'new',
        title: 'Klijent treba da plati kuriru ili putem uplatnice',
      },
    ];
    stateValue: 'new' | 'draft' = 'draft';

    paymentTypeCtrl: FormControl<SelectItem | null>;
      draftPaymentTypeOptions: SelectItem<PaymentType>[] = [
        { label: 'Corvus Pay', value: PaymentType.CORVUS_PAY },
        { label: 'Gotovina', value: PaymentType.CASH },
        { label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP },
      ];
      newPaymentTypeOptions: SelectItem<PaymentType>[] = [
        { label: 'Gotovina', value: PaymentType.CASH },
        { label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP },
      ];
    
      deliveryTypeCtrl: FormControl<SelectItem | null>;
      deliveryTypeOptions: SelectItem<DeliveryType>[] = [
        { label: 'eUgovor', value: DeliveryType.E_CONTRACT },
        { label: 'Lično preuzimanje', value: DeliveryType.PICK_UP },
        { label: 'Bex', value: DeliveryType.BEX },
        { label: 'Bex besplatna', value: DeliveryType.BEX_FREE },
        { label: 'Bex bez otkupa', value: DeliveryType.BEX_WITHOUT_DEPOSIT },
        { label: 'Kurir NS', value: DeliveryType.COURIER_NS },
        { label: 'Promo-sezonac', value: DeliveryType.PROMO_SEASONAC },
      ];
    
      activationOptions: any = [
        {
          name: 'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)',
          value: true,
        },
        {
          name: 'Standardna aktivacija (7 dana od evidentiranja uplate)',
          value: false,
        },
      ];
    


  constructor(private location: LocationService,
    private clientPackageClient: ClientClient,
    private route: ActivatedRoute,
     private _orderFormService: OrderFormService,
     private _packageCardService: PackageCardListService,
  ) {
    this.clientPackageId = this.route.snapshot.paramMap.get('id')!;
    this.orderForm = this._orderFormService.getOrderForm;
    this.deliveryTypeCtrl = new FormControl<SelectItem>(
      this.deliveryTypeOptions.find(
        (opt) => DeliveryType.E_CONTRACT === opt.value,
      ),
    );
    this.paymentTypeCtrl = new FormControl<SelectItem>(
      this.draftPaymentTypeOptions.find(
        (opt) => PaymentType.CORVUS_PAY === opt.value,
      ),
    );
    
  }
  ngOnInit(): void {
    this._load().then();
    this._packageCardService.packages$.subscribe((x) => {
      this.packages = x;
      this.packagesSum = this.packages.value.reduce((sum, pkg) => sum + (pkg.price || 0), 0);
    })
  }

  get validPackages() {
    return this.packages.value.filter(pkg => pkg.id !== null);
  }

  private async _loadPackageData() {
      return await firstValueFrom(
        this.clientPackageClient.getPackageSidebarInfo(this.clientPackageId),
      );
    }

    async _load() {
      this._loadPackageData().then((res) => {
        this.packageData = res.result.value;
        console.log(this.packageData)
      });
    }

    handleStateChange($event: SelectButtonChangeEvent) {
        if ($event.value === 'draft') {
          this._orderFormService.setToDraft();
          this.deliveryTypeCtrl.disable();
          this.paymentTypeCtrl.disable();
        } else {
          this.deliveryTypeCtrl.enable();
          this.paymentTypeCtrl.enable();
          this._orderFormService.setToNew();
        }
      }

  handleCancelForm() {
    this.location.routeToClientsTable()
  }

  ngOnDestroy(): void {
    this._packageCardService.clearAllPackages()
  }

}
