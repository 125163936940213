import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GetBrandWithModelsTableQuery,
  IPaginationResponseOfBrandWithModelsResponse,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { firstValueFrom, Subscription } from 'rxjs';
import { TableConfig } from '@ui/table/table-common';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table/table.interface';
import { FormControl } from '@angular/forms';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import {
  TableModule,
  TableRowCollapseEvent,
  TableRowExpandEvent,
} from 'primeng/table';
import { ButtonComponent } from '@ui/button/button.component';
import { CheckboxActionDialogComponent } from '@app/features/orders/checkbox-action-dialog/checkbox-action-dialog.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { NgClass, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { PackageDetailSidebarComponent } from '@app/features/packages/package-detail-sidebar/package-detail-sidebar.component';
import { PageComponent } from '@shared/components/page/page.component';
import { licencePlatePipe } from '@shared/pipes/licence-plate.pipe';
import { DividerModule } from 'primeng/divider';
import { AlertService } from '@shared/services/alert.service';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';

@Component({
  selector: 'atlas-brand-with-model-table',
  standalone: true,
  imports: [
    // Button,
    ButtonComponent,
    CheckboxActionDialogComponent,
    CheckboxModule,
    DropdownComponent,
    InlineWrapperComponent,
    InputTextComponent,
    NgForOf,
    NgIf,
    NgOptimizedImage,
    PackageDetailSidebarComponent,
    PageComponent,
    TableModule,
    licencePlatePipe,
    NgClass,
    DividerModule,
    DropdownInputButtonComponent,
  ],
  templateUrl: './brand-with-model-table.component.html',
  styleUrl: './brand-with-model-table.component.scss',
})
export class BrandWithModelTableComponent implements OnInit, OnDestroy {
  paginatedData: IPaginationResponseOfBrandWithModelsResponse;
  private _subs: Subscription = new Subscription();
  protected tableConfig: TableConfig;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);
  expandedRows: { [key: string]: boolean } = {};

  constructor(
    private client: VehiclesClient,
    private _confirmationService: ConfirmationService,
    private _alertService: AlertService,
  ) {
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
      pageSize: 10,
    };
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.BRAND_NAME,
    );
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.BRAND_NAME,
        value: SearchOptions.BRAND_NAME,
      },
    ];
  }

  ngOnInit(): void {
    this.load().then();
  }

  onRowExpand(event: TableRowExpandEvent) {
    this.expandedRows[event.data.id] = true;
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    this.expandedRows[event.data.id] = false;
  }

  toggleRow(client: any): void {
    if (this.expandedRows[client.id]) {
      delete this.expandedRows[client.id];
    } else {
      this.expandedRows[client.id] = true;
    }
  }

  loadLazy(event: TableLazyLoadEvent) {
    if (event.first === 0) {
      this.paginatedData.currentPage = 1;
    } else {
      this.paginatedData.currentPage = event.first! / event.rows! + 1;
    }
    this.paginatedData.pageSize = event.rows!;
    this.load().then();
  }

  async load() {
    this._loadTable().then((res) => {
      this.paginatedData = res.result;
    });
  }

  private async _loadTable() {
    return await firstValueFrom(
      this.client.getBrandWithModelsTable(
        new GetBrandWithModelsTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize ?? 10,
          brandName:
            this.searchOptionCtrl.value === SearchOptions.BRAND_NAME
              ? this.searchCtrl.value
              : undefined,
        }),
      ),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  deleteBrand(id: string) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da obrišete marku?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this.client.deleteBrand(id).subscribe((res) => {
            this._alertService.addSuccessMsg(res.result);
            this.loadLazy({
              first: 0,
              rows: this.paginatedData.pageSize ?? 10,
            });
          }),
        );
      },
    });
  }

  deleteModel(id: string) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da obrišete model?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this.client.deleteModel(id).subscribe((res) => {
            this._alertService.addSuccessMsg(res.result);
            this.loadLazy({
              first: 0,
              rows: this.paginatedData.pageSize ?? 10,
            });
          }),
        );
      },
    });
  }
}

enum SearchOptions {
  BRAND_NAME = 'Marka',
}
