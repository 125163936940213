<div class="flex flex-column gap-3 table-filter mt-3">
    <div class="flex align-items-center gap-3">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel header="Leadovi"></p-tabPanel>
        <p-tabPanel header="Agenti"></p-tabPanel>
      </p-tabView>

      <div class="search-bar">
        <atlas-input-button
          [searchCtrl]="searchInputValue"
          (onAction)="activeIndex === 0 ? load() : loadAgents()"
        ></atlas-input-button>
      </div>
    </div>
  </div>

  <atlas-table
    [paginatedData]="activeIndex === 1 ? paginatedDataAgents : paginatedData"
    [tableConfig]="activeIndex === 1 ? tableConfigAgents : tableConfig"
></atlas-table>